import { useEffect } from 'react'

export function useKeyDown(handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      handler(event)
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [handler])
}
