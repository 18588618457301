import { PortableText } from "components/elements";
import React from "react";
import { Grid } from "styles";
import { SectionSize, SectionPaddingSize, SectionTheme } from "types/misc";
import OrderedListSection from "./OrderedListSection";
import { Section } from "./Section";

type Props = {
  steps: {
    id: string,
    header: string,
    description: string
  }[]
  theme?: SectionTheme
  paddingSize?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
  borderBottom?: boolean
  header?: string
  description?: any[]
  layout?: 'stacked' | 'grid'
}
const SanityStepSection = ({ steps, paddingSize, paddingTop, paddingBottom, borderBottom, header, description, theme, layout = 'grid' }: Props) => {
  const formattedSteps = steps.map(step => ({
    title: step.header,
    description: step.description
  }));

  return (
    <div className={borderBottom ? 'border-bottom' : ''}>
      <Section
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        size={paddingSize}
        theme={theme}
      >
        {
          layout === 'grid'
          ? (
            <Grid cols={[1,1,2]}>
              <div>
                {header && <h2 className="no-top-margin">{header}</h2>}
                {description && <PortableText _rawContent={description}  />}
              </div>
              <OrderedListSection listItems={formattedSteps} layout='stacked' />
            </Grid>
          )
          : (
            <>
              <div>
                {header && <h2 className="no-top-margin">{header}</h2>}
                {description && <PortableText _rawContent={description} />}
              </div>
              <OrderedListSection listItems={formattedSteps} layout='grid' />
            </>
          )
        }
      </Section>
    </div>
  )
}

export default SanityStepSection
