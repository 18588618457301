import React from 'react'
import { Link } from 'gatsby'
import { RowDropdownImage, RowReference } from 'types/misc'
import { below } from 'styles'
import styled from 'styled-components'
import { Accordion, CustomGatsbyImage, PortableText } from 'components/elements'

const RowTitle = ({ row, idx }: { row: RowReference, idx?: number }) => {
  if (row.isDropdown) return null;

  if (row.accentTitle) {
    return (
      <h3 className={`accent-text ${row.isTextCenter ? 'center-text' : ''}`}>
        {row.title}
      </h3>
    )
  }

  if (idx == 0) {
    return (
      <div className={row.isTextCenter ? 'center-text' : ''}>
        {row.subtitle && <h6>{row.subtitle}</h6>}
        <h2 className={`row-title ${row.subtitle ? 'no-top-margin' : ''}`}>{row.title}</h2>
      </div>
      
    )
  }

  return (
    <h4 className={`no-margins ${row.isTextCenter && 'center-text'}`}>
      {row.title}
    </h4>
  )
}

type Props = {
  row: RowReference
  idx?: number,
  onDropdownClicked?: (
    idx: number, 
    imageAsset?: RowDropdownImage
  ) => any
  isDropdownOpen?: boolean
}
export const Row = ({ row, idx = 0, onDropdownClicked, isDropdownOpen }: Props) => {
  const {
    _rawParagraph,
    buttonLink,
    buttonText,
    quickPoints,
    images,
    isTextCenter,
    isDropdown,
    dropdownImage,
    dropdownMobileImage,
    title
  } = row
  const Content = (
    <RowContainer>
      <RowTitle 
        row={row} 
        idx={idx} 
      />

      <div className={`row-text ${isTextCenter ? 'center-text' : ''}`}>
        <PortableText _rawContent={_rawParagraph} />
      </div>
      {images && (
        <div
          className="images-row"
          style={{ alignItems: 'center' }}
        >
          {images.map((image) => {
            return image.link ? (
                <div key={image.asset.id} className="image-container-row">
                  <a
                    href={image.link}
                    target="_blank"
                    rel="noreferrer noopener"
                    key={image.asset.id}
                  >
                    <CustomGatsbyImage
                      image={image.asset.gatsbyImageData}
                      alt="row image"
                    />
                  </a>
                </div>
            ) : (
              <div key={image.asset.id} className="image-container-row">
                <CustomGatsbyImage
                  image={image.asset.gatsbyImageData}
                  alt="row image"
                />
              </div>
            )
          })}
        </div>
      )}
      {quickPoints?.[0] && (
        <ul className="disc list-spacing">
          {quickPoints.map((point) => {
            return <li key={point}>{point}</li>
          })}
        </ul>
      )}
      {buttonText && (
        <p>
          <Link to={buttonLink}>{buttonText}</Link>
        </p>
      )}
    </RowContainer>
  )

  return isDropdown ? (
    <Accordion 
      title={title} 
      hasBorder={false}
      isOpen={isDropdownOpen}
      onClick={() => {
        if (typeof onDropdownClicked === "function") {
          onDropdownClicked(
            idx, 
            dropdownImage && { 
              image: dropdownImage.asset,
              mobileImage: dropdownMobileImage?.asset
            }
          )
        }
      }}>
      {Content}
    </Accordion>
  ) : Content;
}

const RowContainer = styled.div`
  .images-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .image-container-row {
    width: 50%;
    margin: 5px auto 15px auto;
    text-align: center;
    .gatsby-image-wrapper {
      width: 200px;
      border-radius: 0;
    }
  }

  .row-text {
    p:first-child:has(strong) {
      margin: 0;
    }
  }

  .row-title {
    margin-bottom: 1rem;
    font-size: 3rem;
  }

  ${below.medium`
    .image-container-row {
      width: 45%;
      margin: 5px 15px 5px 0;
      .gatsby-image-wrapper {
        width: 100%;
        margin: auto;
      }
    }
  `}
  ${below.xSmall`
    .row-title {
      font-size: 2.5rem;
    }
  `}
`
