import React, { useEffect, useRef, useState } from "react";
import { Icon } from "./Icon";
import styled, { css } from "styled-components";
import { copy } from "clipboard";
import { useClickOutside, useKeyDown, useUserAgentDetection, useWindowWidth } from "hooks";
import { sizes } from "styles";
import { AnimatePresence, motion } from "framer-motion";
import { shareFacebook, sharePinterest } from "utilities";

type ShareDataFn = (shareData: { text: string, url: string, title?: string, type: string }) => any

type Props = {
  title?: string,
  text: string,
  url: string,
  iconColor?: string
  iconBackgroundColor?: string,
  onSuccessfulShare?: ShareDataFn
}
export const ShareButton = ({
  title,
  text,
  url,
  iconColor,
  iconBackgroundColor,
  onSuccessfulShare
}: Props) => {
  const userAgent = useUserAgentDetection();
  const windowWidth = useWindowWidth();
  const [isDesktopToolActive, setIsDesktopToolActive] = useState(false);
  const handleShare = async (type: string) => {
    if (windowWidth && windowWidth >= sizes.large) {
      setIsDesktopToolActive(true);
      return;
    }
    
    if (navigator.share) {
      try {
        await navigator.share({
          title,
          text,
          url,
        })

        if (typeof onSuccessfulShare === "function") {
          onSuccessfulShare({
            title,
            text,
            url,
            type
          })
        }
      } catch (error) {
        console.error(`Could not share link`, error);
      } 
    } else {
      copy(url)
    }
  }

  return (
    <Container className="btn-share">
      <IconButton 
        backgroundColor={iconBackgroundColor} 
        iconColor={iconColor}
        onClick={handleShare} 
      >
        <Icon 
          name={userAgent.isApple ? "ios-share" : "android-share"} 
          className={userAgent.isApple ? "ios-share" : "android-share"}
        />
      </IconButton>
      <DesktopShareTool 
        isActive={isDesktopToolActive} 
        onClose={() => setIsDesktopToolActive(false)} 
        url={url}
        onSuccessfulShare={onSuccessfulShare}
      />
    </Container>
  );
}

type DesktopToolProps = { 
  isActive: boolean, 
  onClose: () => any,
  onSuccessfulShare?: ShareDataFn
  url: string
}
const DesktopShareTool = ({ isActive, onClose, onSuccessfulShare, url }: DesktopToolProps) => {
  const desktopToolRef = useRef(null);
  useClickOutside(desktopToolRef, onClose)
  const firstLinkRef = useRef<HTMLAnchorElement>(null)
  const [linkCopied, setLinkCopied] = useState(false);
  

  const handleLinkCopied = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    copy(url);
    setLinkCopied(true);
    if (typeof onSuccessfulShare === "function") {
      onSuccessfulShare({
        text: 'Link copied to clipboard',
        url,
        type: 'link-copied'
      });
    }
  }

  useKeyDown((e) => {
    if (e.key === 'Escape') onClose()
  })

  useEffect(() => {
    if (linkCopied) {
      const timeout = setTimeout(() => setLinkCopied(false), 3000)
      return () => clearTimeout(timeout);
    }
  }, [linkCopied])

  useEffect(() => {
    if (firstLinkRef.current) firstLinkRef.current.focus();
  }, [])

  return (
    <AnimatePresence>
      {isActive ? (
        <DesktopToolContainer
          ref={desktopToolRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }} 
          transition={{ duration: 0.17, ease: "easeInOut" }}
        >
          <button onClick={onClose} className="btn-close">
            <Icon name="close" className="icon-close" />
          </button>
          <div className="share-link">
            <a 
              href="" 
              onClick={(e) => {
                e.preventDefault();
                shareFacebook(url)
                if (typeof onSuccessfulShare === "function") {
                  onSuccessfulShare({
                    text: 'Shared on Facebook',
                    url,
                    type: 'facebook'
                  });
                }
              }}
              ref={firstLinkRef}
              target="_blank"
            >
             <span>
              <Icon name="facebook" style={{ width: '25px' }} />
              {" "}Facebook
             </span>
            </a>
          </div>
          <div className="share-link">
            <a 
              href="" 
              onClick={(e) => {
                e.preventDefault();
                sharePinterest(url)
                if (typeof onSuccessfulShare === "function") {
                  onSuccessfulShare({
                    text: 'Shared on Pinterest',
                    url,
                    type: 'pinterest'
                  });
                }
              }}
              ref={firstLinkRef}
              target="_blank"
            >
              <span>
                <Icon name="pinterest" style={{ width: '20px' }} />
                {" "}Pinterest
              </span>
            </a>
          </div>
          <div className="share-link">
            <a 
              href="" 
              onClick={handleLinkCopied}
            >
              <span>
                <Icon name="link" style={{ width: '20px' }} />
                {" "}{linkCopied ? 'Link copied!' : 'Copy link'}
              </span>
            </a>
          </div>
          <div className="share-link">
            <a 
              href=""
              onClick={(e) => {
                e.preventDefault();
                if (typeof onSuccessfulShare === "function") {
                  onSuccessfulShare({
                    text: 'Shared via Email',
                    url,
                    type: 'email'
                  });
                }
                window.open(`mailto:?subject=Check out this out on https://rachio.com&body=I recommend purchasing this product from Rachio.com: ${url}`)
              }}
            >
              <span>
                <Icon name="envelope" style={{ width: '20px' }} />
                {" "}Email
              </span>
            </a>
          </div>
        </DesktopToolContainer>
      ) : null}
    </AnimatePresence>
  )
}

const Container = styled.div`
  position: relative;
`

const DesktopToolContainer = styled(motion.div)`
  position: absolute;
  top: 60px;
  right: 0px;
  z-index: 1;
  padding: 10px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  width: 175px;
  height: auto;
  .btn-close {
    float: right;
  }
  .icon-close {
    width: 16px;
    height: 16px;
  }
  .share-link {
    span {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
  }
  > div {
    border-bottom: 1px solid var(--lineColor);
    &:last-child {
      border-bottom: none;
    }

    a {
      color: grey;
    }
  }
`

const IconButton = styled.button<{ backgroundColor?: string, iconColor?: string }>`
  ${({ backgroundColor, iconColor }) => {
    return css`
      transition: var(--transition);
      background-color: ${backgroundColor || 'var(--darkNavy)'};
      svg {
        width: 35px;
        height: 35px;
        path {
          fill: ${iconColor || 'white'};
          stroke: ${iconColor || 'white'};
        }
        &.android-share {
          width: 30px;
          height: 30px;
          position: relative;
          top: 2px;
          path:last-child {
            fill: none;
          }
        }
      }

      &:hover {
        background-color: ${iconColor || 'var(--accentColor)'};
      }
    `
  }}
  position: relative;
  border-radius: 50px;
  padding: 4px;
  border: none;
  line-height: 0;
`

