import { Button } from "components/elements";
import { IpGeolocationContext } from "global";
import React from "react";

export const ShopInternationalButton = () => {
  const { isInternational, internationalLink } = React.useContext(IpGeolocationContext);
  
  return isInternational ? (
    <div className='center-text' style={{ marginTop: '1rem' }}>
      <Button 
        as="a" 
        href={internationalLink.href} 
        target="_blank" 
        color="white" 
        size="full"
        className="full-width"
        title="Rachio International Store (opens in a new tab)"
      >
        {internationalLink.copy}
      </Button>
    </div>
  ) : null
}