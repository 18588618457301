import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "./Icon";

type Props = {
  openText?: string
  closeText?: string
  openByDefault?: boolean,
  testId?: string
  children: JSX.Element | JSX.Element[]
}
export const Dropdown = ({ 
  openText = "Show Details", 
  closeText = "Hide Details", 
  openByDefault, 
  testId = 'dropdown',
  children 
}: Props) => {
  const [open, setOpen] = useState<boolean>(openByDefault || false);
  return (
    <Container>
      <Trigger 
        className={open ? 'open' : ''}
      >
        <a 
          data-testid={testId}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setOpen(!open)
          }}
        >
          <span>{open ? closeText : openText}</span>
          <Icon name="arrow-left" />
        </a>
      </Trigger>
      {open && <div className="content-wrapper">{children}</div>}
    </Container>
  )
}

const Container = styled.div`
  .content-wrapper {
    ::-webkit-scrollbar {
      display: none;
    }
    max-height: 50vh;
    overflow-y: scroll;
    border-bottom: 1px solid var(--lineColor);
  }
`

const Trigger = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--lineColor);
  &:hover {
    cursor: pointer;
    span {
      color: var(--accentColor);
    }
  }
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    height: 30px;
    color: inherit;
  }
  svg {
    width: 10px;
    transform: rotate(270deg);
    transition: .2s;
  }
  &.open {
    svg {
      transform: rotate(90deg)
    }
  }
`