import { trackEvent, TrackFunction } from "analytics"
import { ShoppingEventInputs, SHOPPING_EVENT_NAMES } from "."
import { getProductsInCart } from "./transformers/product"
import { ShopifyCheckout } from "lib/shopify/storefront-api-client/types/custom.types"

const track: TrackFunction<
  SHOPPING_EVENT_NAMES.CART_VIEWED,
  ShoppingEventInputs[SHOPPING_EVENT_NAMES.CART_VIEWED]
> = async (
  eventName,
  properties
) => {
  await trackEvent(eventName, properties);
}

export const trackCartViewed = async (checkout: ShopifyCheckout | undefined) => {
  if (!checkout?.lineItems?.length) return;
  
  const cart_id = checkout?.id;

  if (!cart_id) return

  await track(
    SHOPPING_EVENT_NAMES.CART_VIEWED, 
    {
      cart_id,
      products: getProductsInCart(checkout),
      value: Number(checkout.totalPrice.amount),
      currency: checkout.currencyCode
    }
  )
}