import { trackEvent, TrackFunction } from "analytics"
import { ShoppingEventInputs, SHOPPING_EVENT_NAMES } from "."
import { getTrackedPropertiesFromVariant, getProductsInCart } from "./transformers/product"
import { TrackingProductUnformatted } from "./types"
import { Checkout } from "lib/shopify/storefront-api-client/types/storefront.types"
import { ShopifyCheckout } from "lib/shopify/storefront-api-client/types/custom.types"

const track: TrackFunction<SHOPPING_EVENT_NAMES.PRODUCT_ADDED, ShoppingEventInputs[SHOPPING_EVENT_NAMES.PRODUCT_ADDED]> = async (
  eventName,
  properties
) => {
  await trackEvent(eventName, properties)
}

export const trackProductAdded = async (variant: TrackingProductUnformatted, checkout: ShopifyCheckout) => {
  if (!variant || !checkout) return;

  await track(
    SHOPPING_EVENT_NAMES.PRODUCT_ADDED, 
    {
      cart_id: checkout.id,
      ...getTrackedPropertiesFromVariant(variant),
      products: getProductsInCart(checkout),
      pathname: window?.location?.pathname
    }
  )
}

export const trackProductsAdded = async (variants: TrackingProductUnformatted[], checkout: ShopifyCheckout) => {
  for (const variant of variants) {
    await trackProductAdded(variant, checkout);
  }
}