import { Icon } from 'components/elements';
import React from 'react';
import styled from 'styled-components';
import { below, Grid, sizes } from 'styles';
import { SectionSize, SectionPaddingSize, SectionTheme } from 'types/misc';
import { SanitySection } from './SanitySection';

type ProductFeatureListItem = {
  icon: string,
  title: string
}

type Props = {
  theme?: SectionTheme
  size?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
  productFeatureListItems: ProductFeatureListItem[]
}

const SanityProductFeatureList = ({ theme, size, paddingTop, paddingBottom, productFeatureListItems }: Props) => {
  return (
    <Container>
      <SanitySection
        size={size}
        paddingTop={paddingTop || 'small'}
        paddingBottom={paddingBottom || 'small'}
        theme={theme || ''}
        width={`${sizes.large}px`}
      >
        <FeatureListGrid cols={[4]}>
          {productFeatureListItems && productFeatureListItems.map(listItem => (
            <ProductFeatureListItem className="icon-list-item center-text" key={listItem.title}>
              <div>
                <Icon name={listItem.icon} accent="var(--white)" style={{ minWidth: '85px', minHeight: '60px' }} />
              </div>

              <p>{listItem.title}</p>
            </ProductFeatureListItem>
          ))}
        </FeatureListGrid>
      </SanitySection>
    </Container>
  )
}

const ProductFeatureListItem = styled.div`
  max-width: 100%;
  padding: 15px;
  color: var(--white);
  div {
    min-height: 90px;
  }
  p {
    line-height: 25px;
    font-weight: var(--fontWeightNormal);
    font-size: 18px;
    ${below.mediumLarge`
      font-size: 16px;
    `}
  }
`

const FeatureListGrid = styled(Grid)`
  ${below.medium`
    grid-template-columns: repeat(2,1fr)
  `}
  ${below.xSmall`
    grid-template-columns: repeat(1,1fr)
  `}
`

const Container = styled.div`
  background: var(--darkGreen);
`

export default SanityProductFeatureList
