// @ts-check

import { fetchRachioEmailForTracking, redactUrlPII } from "../../utilities";

/**
 * @typedef {Object} PageViewedProps
 * @property {string} path
 * @property {string} referrer
 * @property {string} search
 * @property {string} title
 * @property {string} url
 * @property {Array<string>} keywords
 */

/**
 * @param {Location} location
 * @returns {Promise<void>}
 */
export const trackPageViewed = async (location) => {
  if (!window.dataLayer) return;

  const redactedURL = redactUrlPII(location);

  /**
   * @type {PageViewedProps}
   */
  const pageViewedEvent = {
    path: redactedURL.pathname,
    referrer: document?.referrer || "",
    search: redactedURL.search,
    title: document?.title || "",
    url: redactedURL.href,
    keywords: []
  }

  const params = new URLSearchParams(location.search)

  // Get klaviyo id from passed from emails to attach to orders for post purchase logic
  const klaviyoId = params.get('k_id');
  if (klaviyoId) window.sessionStorage.setItem('k_id', klaviyoId);

  // If customer is coming from mobile app, identify as rachio account user
  const rachioAccountId = params.get('ajs_uid');

  if (rachioAccountId) {
    window.sessionStorage.setItem('ajs_uid', rachioAccountId);
    const rachioAccountEmail = await fetchRachioEmailForTracking(rachioAccountId);

    if (rachioAccountEmail) {
      window.sessionStorage.setItem('ajs_email', rachioAccountEmail);

      window.dataLayer.push({
        event: 'identify',
        email: rachioAccountEmail
      })
    }
  }

  // DELETE AFTER RS TESTING
  window.dataLayer.push({
    event: 'Loaded a Page',
    ...pageViewedEvent
  });
}

/**
 *
 * @param {Location} location
 * @param {Location|null} prevLocation
 */
export const sendPageView = (location, prevLocation, trackingFn = trackPageViewed) => {
  const isNavigateNewPage = (
    prevLocation 
    && (
      location.pathname !== prevLocation.pathname
      || location.search !== prevLocation.search
    )
  );

  const isTrackable = !prevLocation || isNavigateNewPage;

  if (isTrackable) {
    trackingFn(location)
  }
}