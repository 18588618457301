import React from "react";
import styled from "styled-components";
import { Price } from "./Price";

type Props = {
  basePrice: number
  discountAmount?: number
}
export const SubTotalPrice = ({ basePrice, discountAmount }: Props) => {
  return (
    <Container className="subtotal-price">
      <div className="subtotal-price-title">Subtotal</div>
      <Price 
        forceShow
        basePrice={basePrice}
        discountAmount={discountAmount || 0}
      />
    </Container>
  ) 
}

const Container = styled.div`
  .price, .discount {
    font-family: var(--headlineFont);
    text-align: center;
    font-size: var(--largestFontSize);
    line-height: 30px;
    font-weight:  var(--fontWeightNormal);
  }
`