// Button Clicked (Homepage Hero)
// * Tracks button clicks in the homepage hero

import { trackEvent, TrackFunction } from "analytics"

type ButtonClickProps = {
  label: string
  location: string
  category?: string
}

enum BUTTON_EVENT_NAMES {
  CLICKED = 'Button Clicked'
}

type ButtonEventInputs = {
  [BUTTON_EVENT_NAMES.CLICKED]: ButtonClickProps
}

const track: TrackFunction<BUTTON_EVENT_NAMES.CLICKED|string, ButtonEventInputs[BUTTON_EVENT_NAMES.CLICKED]> = async (
  eventName,
  properties
) => {
  await trackEvent(eventName, properties);
}

export const trackButtonClicked = async (properties: ButtonClickProps, category?: string | null, customName?: string, ) => {
  const payload = {
    ...properties
  }

  if (category) payload.category = category

  await track(customName || BUTTON_EVENT_NAMES.CLICKED, payload)
}