import { FragmentHelpers } from "../../types";

export const USER_ERROR_FRAGMENT: FragmentHelpers = {
  fragment: `
    fragment UserErrorFragment on UserError {
      field
      message
    }
  `,
  ref: '...UserErrorFragment'
}