export const CHECKOUT_ATTRIBUTES_UPDATE_MUTATION = `
  #graphql

  mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }

  fragment UserErrorFragment on UserError {
    field
    message
  }

  fragment CheckoutUserErrorFragment on CheckoutUserError {
    field
    message
    code
  }

  fragment DiscountApplicationFragment on DiscountApplication {
    targetSelection
    allocationMethod
    targetType
    value {
      ... on MoneyV2 {
        amount
        currencyCode
      }
      ... on PricingPercentageValue {
        percentage
      }
    }
    ... on ManualDiscountApplication {
      title
      description
    }
    ... on DiscountCodeApplication {
      code
      applicable
    }
    ... on ScriptDiscountApplication {
      title
    }
    ... on AutomaticDiscountApplication {
      title
    }
  }

  fragment CheckoutFragment on Checkout {
    id
    ready
    requiresShipping
    note
    paymentDue {
      amount
      currencyCode
    }
    paymentDueV2: paymentDue {
      amount
      currencyCode
    }
    webUrl
    orderStatusUrl
    taxExempt
    taxesIncluded
    currencyCode
    totalTax {
      amount
      currencyCode
    }
    totalTaxV2: totalTax {
      amount
      currencyCode
    }
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    subtotalPrice {
      amount
      currencyCode
    }
    subtotalPriceV2: subtotalPrice {
      amount
      currencyCode
    }
    totalPrice {
      amount
      currencyCode
    }
    totalPriceV2: totalPrice {
      amount
      currencyCode
    }
    completedAt
    createdAt
    updatedAt
    email
    discountApplications(first: 10) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...DiscountApplicationFragment
        }
      }
    }
    shippingAddress {
      ...MailingAddressFragment
    }
    shippingLine {
      handle
      price {
        amount
        currencyCode
      }
      priceV2: price {
        amount
        currencyCode
      }
      title
    }
    customAttributes {
      key
      value
    }
    lineItems (first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          title
          variant {
            ...VariantWithProductFragment
          }
          quantity
          customAttributes {
            key
            value
          }
          discountAllocations {
            allocatedAmount {
              amount
              currencyCode
            }
            discountApplication {
              ...DiscountApplicationFragment
            }
          }
        }
      }
    }
  }

  fragment MailingAddressFragment on MailingAddress {
    id
    address1
    address2
    city
    company
    country
    firstName
    formatted
    lastName
    latitude
    longitude
    phone
    province
    zip
    name
    countryCode: countryCodeV2
    provinceCode
  }
  
  fragment VariantWithProductFragment on ProductVariant {
    ...VariantFragment
    product {
      id
      handle
      tags
    }
  }

  fragment VariantFragment on ProductVariant {
    id
    title
    price {
      amount
      currencyCode
    }
    priceV2: price {
      amount
      currencyCode
    }
    weight
    availableForSale
    sku
    compareAtPrice {
      amount
      currencyCode
    }
    compareAtPriceV2: compareAtPrice {
      amount
      currencyCode
    }
    image {
      id
      src: url
      altText
      width
      height
    }
    selectedOptions {
      name
      value
    }
    unitPrice {
      amount
      currencyCode
    }
    unitPriceMeasurement {
      measuredType
      quantityUnit
      quantityValue
      referenceUnit
      referenceValue
    }
  }
`