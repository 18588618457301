import React from "react"; 
import styled from "styled-components";

type Props = {
  text: string
  maxLength?: number
  buttonText?: string
}
export const CollapsibleText = ({ text, maxLength = 100, buttonText = "read more" }: Props) => {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
  const originalText = text;
  return (
    <Container className="collapsible-text">
      <p>
        {
          isCollapsed && text.length > 100 
            ? (
              <>
                {`${text.slice(0, maxLength).trim()}...`}
                <ButtonTrigger onClick={() => setIsCollapsed(false)}>{buttonText}</ButtonTrigger>
              </>
            ) 
            : originalText
        }
      </p>
    </Container>
  )
}

const ButtonTrigger = styled.button`
  border: none;
  padding: 0;
  margin: 0 0 0 2px;
  background: transparent;
  text-transform: capitalize;
  text-decoration: underline;
`

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
`