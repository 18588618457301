import { FragmentHelpers } from "../../types";

export const CHECKOUT_USER_ERROR_FRAGMENT: FragmentHelpers = {
  fragment: `
    fragment CheckoutUserErrorFragment on CheckoutUserError {
      field
      message
      code
    }
  `,
  ref: '...CheckoutUserErrorFragment'
}