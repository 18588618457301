import React from 'react'
import { SanitySection } from 'components/sections'
import { SanityVideo, SectionPaddingSize, SectionSize, SectionTheme } from 'types/misc'
import styled from 'styled-components'
import { below } from 'styles'
import { Video, VideoIframe, VideoMimeType } from 'components/elements'

type Props = {
  title?: string
  description?: string
  youtubeVideoId: string
  enableLoop?: boolean
  size?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
  theme: SectionTheme
  video: SanityVideo
}

const SectionVideo = ({ video }: { video: SanityVideo }) => {
  if (video.videoId) {
    return (
      <VideoIframe
        videoId={video.videoId}
        enableLoop={video.enableLoop}
        title={video.title}
      />
    )
  }

  return (
    <Video 
      title={video.title}
      sources={[
        { 
          mimeType: VideoMimeType.MP4, 
          src: video.videoMp4Src 
        },
        { 
          mimeType: VideoMimeType.WEBM, 
          src: video.videoWebmSrc 
        }
      ]}
      enableControls={video.enableControls}
      posterImage={video.posterImage}
    />
  )
}

const SanityVideoSection = ({
  title,
  description,
  size = '',
  paddingTop = '',
  paddingBottom = '',
  theme,
  video
}: Props) => {
  return (
    <Container>
      <SanitySection
        width="tight"
        size={size}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        theme={theme}
      >
        <div className="center-text">
          {title && (<h2 className="no-margins bottom-margin">{title}</h2>)}
          {description && (
            <div className="bottom-margin">
              <div className='max-width-small bottom-margin'>{description}</div>
            </div>
          )}
          {video && <SectionVideo video={video} />}
        </div>
      </SanitySection>
    </Container>
  )
}

const Container = styled.div`
  .video-iframe {
    width: 1120px;
    height: 530px;
    margin: 0 auto;
    max-width: 100%;
    ${below.mediumLarge`
      width: 80%;
      height: 430px;
    `}

    ${below.mediumLarge`
      height: 375px;
    `}

    @media (max-width: 675px) {
      width: 100%;
      height: 320px;
    }

    @media (max-width: 500px) {
      height: 275px;
    }

    ${below.xSmall`
      height: 220px;
    `}
  }
`

export default SanityVideoSection
