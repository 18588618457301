import React from 'react'
import styled from 'styled-components'
import { below } from 'styles'

type Spec = {
  title: string
  description?: string
  descriptionList?: string[]
}

type Props = {
  specs: Spec[]
}

export function SpecsChart({ specs }: Props) {
  return (
    <Table>
      <tbody>
        {specs.map((spec: Spec) => (
          <tr key={spec.title}>
            <th>
              <p className="large">{spec.title}</p>
            </th>
            <td>
              {spec.description && <p className="large">{spec.description}</p>}
              {spec.descriptionList && (
                <ul>
                  {spec.descriptionList.map((item) => (
                    <li key={item}>
                      <p className="large">{item}</p>
                    </li>
                  ))}
                </ul>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const Table = styled.table`
  margin: var(--margins);
  text-align: left;
  border-collapse: collapse;
  td p {
    margin-left: var(--margin);
  }
  tr {
    &:last-child {
      border-bottom: none;
    }
    border-top: 1px solid var(--lightestGray);
    border-bottom: 1px solid var(--lightestGray);
    th {
      vertical-align: baseline;
      width: 50%;
    }
    td {
      ul {
        margin-left: 35px;
      }
      li {
        list-style: initial;
        p {
          margin: 0;
        }
        &:first-child p {
          margin-top: 4rem;
        }
        &:last-child p {
          margin-bottom: 4rem;
        }
      }
    }
  }
  ${below.medium`
  th,
  td {
    display: block;
    width: 100%;
  }
  th p {
    margin-bottom: 0;
  }
  tr td p, tr td li p {
    margin-left: 0;
  }
  `};
`
