import { PortableText } from "components/elements";
import { Section } from "components/sections";
import { Link } from "gatsby";
import React from "react";

type Props = {
  title: string,
  description?: any[]
}
export const BundleUnavailable = ({ title, description }: Props) => (
  <Section
    id='product-bundle-unavailable-section'
    paddingTop='small'
    theme='white'
    paddingBottom='small'
    size='small'
  >
    <div className="center-text">
      <h2 className="no-top-margin"> 
        {title}{" "}is no longer available!
      </h2>
      {description ? (
        <PortableText _rawContent={description} /> 
      ) : (
        <p className="no-bottom-margin max-width-small">
          Whoops! It looks like the bundle you are trying to purchase is not available.
          Please try again later, or check out our selection of other <Link to="/products/">products</Link>!
        </p>
      )}
    </div>
  </Section>
)