import React from "react";
import { getStoryType } from "./helpers";
import { CustomerStory } from "types/misc";
import { Modal, PortableText, Video, VideoMimeType } from "components/elements";
import { GatsbyImage } from "gatsby-plugin-image";
import { CustomerStoryProductList } from "./CustomerStoryProductList";
import styled from "styled-components";
import { CustomerStoryAuthor } from "./CustomerStoryAuthor";
import { useWindowWidth } from "hooks";

type Props = {
  customerStory: CustomerStory,
  isModalActive: boolean
  onClose: () => any
}
export const CustomerStoryModal = ({ customerStory, isModalActive, onClose }: Props) => {
  const type = getStoryType(customerStory);
  const windowWidth = useWindowWidth();
  const customerStoryCopy = {
    ...customerStory,
    name: `${customerStory.name}'s Story`
  }

  return (
    <Modal
      maxWidth={windowWidth && windowWidth > 1199 ? "60%" : "80%"}
      isActive={isModalActive}
      closeAction={onClose}
      modalContentStyles={{
        padding: 0,
        overflow: 'hidden',
        border: '1px solid black'
      }}
      innerModalContentStyles={{
        padding: 0
      }}
      closeButtonColor="white"
    >
      <Container className={type}>
        <div className={`customer-card-media-container ${type}`}>
          {type === "video" && customerStory.video && (
            <Video
              enableControls={customerStory.video.enableControls}
              title={customerStory.video.title}
              sources={[
                {
                  mimeType: VideoMimeType.MP4,
                  src: customerStory.video.videoMp4Src
                },
                {
                  mimeType: VideoMimeType.WEBM,
                  src: customerStory.video.videoWebmSrc
                }
              ]}
              posterImage={{
                asset: {
                  url: String(customerStory.video.posterImage.asset.url)
                }
              }}
            />
          )}
          {type === "image" && customerStory.image?.asset.gatsbyImageData && (
            <GatsbyImage
              image={customerStory.image?.asset.gatsbyImageData}
              alt={`${customerStory.name} customer story video`}
            />
          )}
          {type === 'text' && customerStory.summary && (
            <div className="summary">
              <blockquote>{customerStory.summary}</blockquote>
            </div>
          )}
        </div>
        <div className="details">
          <CustomerStoryAuthor customerStory={customerStoryCopy} />
          
          {customerStory.tagline && (
            <div className="tagline center-text">
              <blockquote>{customerStory.tagline}</blockquote>
            </div>
          )}
          <PortableText _rawContent={customerStory._rawStory} />
          <hr />
          <h4 className="products-title">Related Products</h4>
          <CustomerStoryProductList 
            customerStory={customerStory} 
            layout="cards" 
          />
        </div>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .customer-card-media-container {
    min-height: 60vh;
    display: flex;
    align-items: center;
    height: 100%;
    background: black;
    min-width: 60%;
    .gatsby-image-wrapper {
      width: 100%;
      @media (max-width: 991px) {
        max-height: 50vh;
      }
    }
    video {
      width: 100%;
    }
    @media (max-width: 1439px) {
      min-height: 50vh;
    }
  } 
  blockquote {
    &:before, &:after {
      content: '"';
    }
  }
  .tagline blockquote {
    margin: 1rem auto;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
  &.text {
    .customer-card-media-container {
      background: var(--darkNavy);
    }
    
    .summary {
      width: 100%;
      text-align: center;
      blockquote {
        font-size: 2.5rem;
        color: white;
        padding: 0 3rem;
        line-height: 3.8rem;
        text-align: center;
      }
    }
  }

  .products-title {
    margin: 2rem 0 0 0;
    font-size: 1.6rem;
  }

  .details {
    padding: 2rem;
  }
  
  p {
    line-height: 2rem;
    font-size: 1.4rem;
    margin: 1rem auto;
  }

  .customer-details {
    padding: 1rem 0;
  }

  @media (max-width: 991px) {
    display: block;
    .customer-card-media-container:not(.text) {
      min-height: auto;
    }

    .customer-card-media-container.text {
      min-height: 35rem;
    }
  }
`