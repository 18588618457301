import React from 'react'
import { SectionTheme, SectionSize } from 'types/misc'
import { SanitySection } from './SanitySection'
import { below, sizes } from 'styles'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Button } from 'components/elements'

type Props = {
  title: string
  description: string
  buttonText: string
  buttonLink: string
  id?: string
  theme?: SectionTheme | ''
  size?: SectionSize | ''
}

type ImageProps = {
  controllerImg: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

function SanityControllerRebatesFormSection({
  id = '',
  title,
  description,
  size = '',
  buttonText = '',
  buttonLink = ''
}: Props) {
  const data: ImageProps = useStaticQuery(graphql`
    query {
      controllerImg: file(
        relativePath: { eq: "components/email-capture-section/floating-controller.png" }
      ) {
        ...fixedImage400
      }
    }
  `)

  return (
    <Container>
      <SanitySection
        size={size}
        width={`${sizes.medium}px`}
        id={id}
      >
        <Header>
          <h2>{title}</h2>
          <p>{description}</p>
        </Header>
        <div className="center-text">
          <Button as={Link} to={buttonLink}>
            {buttonText}
          </Button>
        </div>
      </SanitySection>
      <div className="controller-img">
        <GatsbyImage
          image={data.controllerImg.childImageSharp.gatsbyImageData}
          alt="Rachio 3 Controller cut off"
          objectFit="contain"
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  background: var(--white);
  overflow: hidden;
  .controller-img {
    position: absolute;
    right: 0;
    bottom: -25%;
    z-index: 2;
    width: 350px;
  }

  form {
    margin: 0 auto;
    max-width: 768px;
  }

  @media (max-width: 1699px) {
    padding-bottom: 100px;
    .controller-img {
      width: 300px;
    }
  }

  ${below.mediumLarge`
    overflow: initial;
  `}

  @media (max-width: 950px) {
    padding-bottom: 225px;
    .controller-img {
      width: 280px;
      bottom: -15%;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 130px;
    .controller-img {
      width: 200px;
      bottom: -12%;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 150px;
    .controller-img {
      width: 200px;
      bottom: -15%;
    }
  }

  @media (max-width: 380px) {
    padding-bottom: 100px;
    .controller-img {
      width: 150px;
      bottom: -12%;
    }
  }
`

const Header = styled.div`
  h2 {
    margin-top: 0;
  }
  text-align: center;
  p {
    max-width: 600px;
    margin: 0 auto 3rem;
  }
`

export default SanityControllerRebatesFormSection
