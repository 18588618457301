import {
  createStorefrontApiClient,
} from '@shopify/storefront-api-client';

import { SHOPIFY_API_VERSION }  from "../../../../common/config/shopify";

export const storefrontAPIClient = createStorefrontApiClient({
  storeDomain: String(process.env.GATSBY_SHOPIFY_CUSTOM_DOMAIN),
  apiVersion: SHOPIFY_API_VERSION,
  publicAccessToken: String(process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN),
  // @ts-ignore ignoring since this will always return the correct type when ran in the browser
  customFetchApi: (url, init) => {
    if(typeof window !== undefined) {
      return fetch(url, init)
    } 
  }
});