import { storefrontAPIClient } from "../client";
import { resolveProduct } from "lib/shopify/transformations";
import { QUERY_PRODUCT_BY_ID } from "../graphql";
import { ShopifyProduct } from "../types/custom.types";
import { Product } from "../types/storefront.types";

export const getProductById = async (id: string): Promise<ShopifyProduct | null> => {
  const { data, errors } = await storefrontAPIClient.request<{ node: Product }>(
    QUERY_PRODUCT_BY_ID, 
    { 
      variables: {
        id 
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.node) return null;

  return resolveProduct(data);
}