import React from 'react'
import { PortableText } from 'components/elements'
import styled from 'styled-components'
import { below } from 'styles'
import { Link } from 'gatsby'
import { Icon } from 'components/elements'
import { ARTICLE_TAXONOMY, ARTICLE_TYPE } from 'types/misc'

type Props = {
  type: ARTICLE_TYPE
  publishedAt: string
  _rawArticleContent: any[]
  currentSlug: string
  sanityArticleLikeItems: {
    nodes: {
      slug: string
    }[]
  }
}

export const SanityArticleContent = ({
  type,
  _rawArticleContent,
  sanityArticleLikeItems,
  currentSlug,
}: Props) => {
  const currIdx = sanityArticleLikeItems.nodes.findIndex(
    (node) => node.slug === currentSlug
  )
  const totalPosts = sanityArticleLikeItems.nodes.length

  return (
    <ArticleContentContainer data-testid="article-content">
      <PortableText _rawContent={_rawArticleContent} />
      <div className="nav-container">
        {currIdx !== totalPosts - 1 && (
          <Link
            className="previous"
            to={`${ARTICLE_TAXONOMY[type]}${sanityArticleLikeItems.nodes[currIdx + 1].slug}/`}
          >
            <Icon name="caret" className="icon-left" accent="var(--primaryBlue)" />
            Previous
          </Link>
        )}
        {currIdx !== 0 && (
          <Link
            className="next"
            to={`${ARTICLE_TAXONOMY[type]}${sanityArticleLikeItems.nodes[currIdx - 1].slug}/`}
          >
            Next
            <Icon name="caret" className="icon-right" accent="var(--primaryBlue)" />
          </Link>
        )}
      </div>
    </ArticleContentContainer>
  )
}

const ArticleContentContainer = styled.div`
  padding: 0 calc(var(--baseSectionPadding) / 2) calc(var(--baseSectionPadding) / 2);
  .nav-container {
    position: relative;
    height: 30px;
    margin: var(--margin) 0;
  }
  .nav-container .previous {
    left: 0;
  }
  .nav-container .next {
    right: 0;
  }
  .nav-container .icon-left,
  .nav-container .icon-right {
    height: 10px
  }
  .nav-container .icon-left {
    transform: rotateZ(90deg);
  }
  .nav-container .icon-right {
    transform: rotateZ(-90deg);
  }
  .previous,
  .next {
    font-weight: bold;
    position: absolute;
    color: var(--primaryColor);
    display: flex;
    align-items: center;
    transition: var(--transition);
    &:hover {
      color: var(--primaryBlue);
    }
  }
  ${below.medium`
    padding: 0 0 calc(var(--baseSectionPadding) / 2);
  `}
`
