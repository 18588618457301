import { resolveCheckout } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { CHECKOUT_DISCOUNT_CODE_REMOVE_MUTATION } from "../graphql";
import { Checkout } from "../types/storefront.types";
import { ShopifyCheckout } from "../types/custom.types";

type CheckoutResponse = { 
  checkoutDiscountCodeRemove: {
    checkout:Checkout 
  }
}

export const removeDiscount = async (checkoutId: string): Promise<ShopifyCheckout> => {
  const { data, errors } = await storefrontAPIClient.request<CheckoutResponse>(
    CHECKOUT_DISCOUNT_CODE_REMOVE_MUTATION, 
    { 
      variables: {
        checkoutId
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.checkoutDiscountCodeRemove?.checkout) throw new Error('Could not remove discount!');

  return resolveCheckout(data.checkoutDiscountCodeRemove.checkout);
}