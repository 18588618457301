import React, { useState } from 'react'
import styled from 'styled-components'
import { Grid, Cols, below } from 'styles'
import { SanitySection } from '../SanitySection'
import loadable from '@loadable/component'
const ComplexList = loadable(() => import('./ComplexList'))
const SimpleList = loadable(() => import('./SimpleList'))
const CustomGatsbyImage = loadable(() => import('components/elements'), {
  resolveComponent: (components) => components.CustomGatsbyImage,
})
import { IGatsbyImageData } from 'gatsby-plugin-image'

import {
  SectionTheme,
  SectionPaddingSize,
  SectionSize,
  RowReference,
  SANITY_MOBILE_RESPONSIVE_IMAGE_BREAKPOINT,
  TextAlign,
  ProductCopy,
  RowDropdownImage,
} from 'types/misc'

import { sizes } from 'styles'
import { useWindowWidth } from 'hooks'
import { Product } from './Product'
import { ProductBadgeSimple } from 'components/product/ProductBadge'

// ? Used for sections that have a split layout (1/2 or 2/3)
// * Layout becomes 1 column on mobile
// * Use isSplitLayout for a 2/3 (Cols) View

/**
 * @prop theme takes an string with the value of the background
 * @prop isMobileReverse a boolean that changes the order that image is showed
 * @prop isSplitLayout a boolean used for for a 2/3 (Cols) View
 * @prop fluid the gatsby image in fluid format
 * @prop size a string with the padding size
 * @prop paddingTop a string with the padding top size
 * @prop paddingBottom a string with the padding bottom size
 * @prop isImageFirst a boolean to know if the image will be first
 * @prop list a string array with main points
 * @prop listHasMargin we need to know for extra margin in list items
 * @prop rowReference a object array for complex lists.
 * @prop alt is the string to display in case an image cannot be displayed
 * @prop title is the section title string
 * @prop paragraph is the section paragraph
 * @prop complexList a boolean to know if the list is complex
 * @prop imageHeight there are images that need height 100%
 * @prop buttonLink cta link
 * @prop buttonText cta text
 * @prop subtitle small text above the main title
 * @prop listHasAccentText blue style in list items
 * @prop buttonTheme custom theme for cta button
 * @prop imageHasGrayBackground optional gray background for images
 **/

type Props = {
  theme?: SectionTheme | ''
  isMobileReverse?: boolean
  isSplitLayout?: boolean
  image: {
    gatsbyImageData: IGatsbyImageData
  }
  mobileImage?: {
    gatsbyImageData: IGatsbyImageData
  }
  imageHasGrayBackground?: boolean
  size?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
  isImageFirst?: boolean
  list: string[]
  listBulletsType?: string
  listHasMargin?: boolean
  listHasAccentText?: boolean
  rowReference: RowReference[]
  alt: string
  id?: string
  title: string
  subtitle?: string
  _rawParagraph?: any[]
  hasComplexList?: boolean
  buttonLink?: string
  buttonText?: string
  buttonTheme?: string
  textAlign?: TextAlign
  addToCartEnabled?: boolean
  productPage?: ProductCopy,
  noRedirectToCart?: boolean
  goToCheckout?: boolean
  quantitySelectionEnabled?: boolean
}

type ImageContainerProps = {
  image: {
    gatsbyImageData: IGatsbyImageData
  }
  mobileImage?: {
    gatsbyImageData: IGatsbyImageData
  }
  alt: string
  imageHasGrayBackground: boolean,
  productPage?: ProductCopy
}
const ImageContainer = ({
  image,
  mobileImage,
  alt,
  imageHasGrayBackground,
  productPage
}: ImageContainerProps) => {
  const width = useWindowWidth();
  const primaryImage = mobileImage && Number(width) <= SANITY_MOBILE_RESPONSIVE_IMAGE_BREAKPOINT
    ? mobileImage
    : image;

  const ratio = primaryImage.gatsbyImageData.width / primaryImage.gatsbyImageData.height;

  return (
    <div
      className={`image-container ${imageHasGrayBackground ? 'gray-bg' : ''}`}
    >
      {productPage?.badgeEnabled && (
        <ProductBagdgeWrapper>
          <ProductBadgeSimple 
            productPage={productPage} 
          />
        </ProductBagdgeWrapper>
      )}
      <CustomGatsbyImage
        style={{ height: '100%' }}
        image={primaryImage.gatsbyImageData}
        alt={alt}
        objectFit={ratio > .80 ? 'fill' : 'contain'}
      />
    </div>
  );
}

export const SanitySplit = ({
  theme = '',
  title = '',
  subtitle = '',
  isMobileReverse = false,
  isSplitLayout = true,
  hasComplexList = false,
  isImageFirst = true,
  image,
  mobileImage,
  imageHasGrayBackground = false,
  list,
  listBulletsType,
  listHasMargin = false,
  listHasAccentText = false,
  rowReference,
  _rawParagraph,
  alt,
  size = '',
  paddingTop = '',
  paddingBottom = '',
  id = '',
  buttonLink = '',
  buttonText = '',
  buttonTheme = '',
  textAlign = 'left',
  addToCartEnabled,
  productPage,
  noRedirectToCart,
  goToCheckout,
  quantitySelectionEnabled
}: Props) => {
  const [currentImage, setCurrentImage] = useState<RowDropdownImage>({
    image, 
    mobileImage
  })
  const nodeImg = (
    <ImageContainer
      image={currentImage.image}
      mobileImage={currentImage.mobileImage}
      imageHasGrayBackground={imageHasGrayBackground}
      alt={alt}
      productPage={productPage}
    />
  )
  let nodeInfo = (
    <>
      {hasComplexList ? (
        <ComplexList
          rowReference={rowReference}
          buttonText={buttonText}
          buttonLink={buttonLink}
          buttonTheme={buttonTheme}
          onDropdownClicked={(idx, imageAsset) => {
            if (imageAsset) setCurrentImage(imageAsset);
          }}
        />
      ) : (
        <SimpleList
          subtitle={subtitle}
          title={title}
          _rawParagraph={_rawParagraph}
          list={list}
          listBulletsType={listBulletsType}
          listHasAccentText={listHasAccentText}
          listHasMargin={listHasMargin}
          buttonText={buttonText}
          buttonLink={buttonLink}
          buttonTheme={buttonTheme}
          textAlign={textAlign}
        />
      )}
      {(productPage && addToCartEnabled) && (
        <Product
          productPage={productPage}
          customButtonText={buttonText}
          customButtonTheme={buttonTheme}
          noRedirectToCart={noRedirectToCart}
          goToCheckout={goToCheckout}
          quantitySelectionEnabled={quantitySelectionEnabled}
        />
      )}
    </>
  )

  const Content = (
    <>
      <SectionLeft
        className={`
          Section--left
          ${isMobileReverse ? 'Section--left__mobile-reverse' : ''}
          ${isImageFirst ? '' : 'Section--text'}
        `}
      >
        {isImageFirst ? nodeImg : nodeInfo}
      </SectionLeft>
      <SectionRight className={`
        Section--right
        ${isImageFirst ? 'Section--text' : ''}
      `}>
        {isImageFirst ? nodeInfo : nodeImg}
      </SectionRight>
    </>
  )

  const Header = hasComplexList && title && (
    <div className='center-text'>
      {subtitle && <h6>{subtitle}</h6>}
      <h2 className={`title-split ${subtitle ? 'no-top-margin' : ''}`}>{title}</h2>
    </div>
  )

  return (
    <SplitContainer paddingBottom={paddingBottom}>
      <SanitySection
        theme={theme}
        id={id}
        size={size}
        paddingTop={paddingTop || 'small'}
        paddingBottom={paddingBottom || 'small'}
      >
        {isSplitLayout ? (
          <>
            {Header}
            <Cols isReverse={isImageFirst}>{Content}</Cols>
          </>
        ) : (
          <>
            {Header}
            <Grid cols={[1, 1, 2]}>{Content}</Grid>
          </>
        )}
      </SanitySection>
    </SplitContainer>
  )
}

export const SplitContainer = styled.div<{ paddingBottom: string }>`
  ${({ paddingBottom }) => paddingBottom === 'none' 
    ? 'border-bottom: none;'
    : 'border-bottom: 1px solid var(--lineColor);' 
  }
  
  .list-spacing,
  .list-has-margin {
    margin-bottom: var(--margin);
  }
  .image-container {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 600px;
    @media screen and (max-width: ${sizes.medium}px) {
      display: flex;
      justify-content: center;
    }
  }
  .gatsby-image-wrapper {
    border-radius: var(--cardRadius);
    isolation: isolate;
    background-color: var(--productImgBackground);
    width: 100%;
    @media screen and (max-width: ${sizes.medium}px) {
      width: 75%;
    }
    @media screen and (max-width: ${sizes.small}px) {
      width: 100%;
    }
  }
  .Section--text {
    max-width: 500px;
    margin: 0 auto;
  }
  .accordion-wrapper {
    padding: .75rem 0;
    .h5 {
      font-size: 1.8rem;
    }
  }
`

export const SectionLeft = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  h3:first-child,
  h2:first-child {
    margin-top: 0;
  }
  ${below.medium`
    &.Section--left__mobile-reverse {
      grid-row: 2;
    }
  `};
`

export const SectionRight = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  h3:first-child,
  h2:first-child {
    margin-top: 0;
  }
`

const ProductBagdgeWrapper = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 2;
  .product-badge {
    font-size: 1.5rem;
    padding: 8px 13px;
  }
`