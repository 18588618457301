import { Section } from "components/sections";
import React from "react";
import { useYotpoReviews } from "../../lib/yotpo";
import { fetchIdFromGlobalId } from "utilities";

export const YotpoReviews = ({ productId }: { productId: string }) => {
  const productIdStripped = fetchIdFromGlobalId(productId);
  useYotpoReviews();

  return (
    <Section size="small">
      <div
        className="yotpo yotpo-main-widget"
        data-product-id={productIdStripped}
      />
    </Section>
  )
}