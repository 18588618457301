// ? Client-side error messages

// API
export const API_ERROR =
  "We're sorry, we cannot process your request at this moment. Please try again later."

// FORMS
export const SUBMIT_ERROR = 'Form could not be submitted'
export const EMAIL_ERROR = 'Please enter valid email address.'
export const ADDRESS_ERROR = 'Sorry, that address is not recognized.'
export const ZIPCODE_ERROR = 'Zipcode not found'

// Other
export const EXISTING_CODE_ERROR =
  'Code could not be found, please contact customer support. https://support.rachio.com'
