import { Button } from "@rachio-npm/rachio-retail-common"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Price } from "../Price"
import { MAX_LINE_ITEM_QUANTITY, getDiscountedAmount, determineBuyButtonText, ProductAvailabilityStatus, getTotalQuantityOfProductInCart } from "utilities"
import { QuantityInput } from "components/inputs"
import { LineItemInput, useCheckout } from "global"
import { below } from "styles"
import { ShopifyProductVariant } from "lib/shopify/storefront-api-client/types/custom.types"
import { useShopifyDiscount } from "hooks"

type VariantSelectionMap = { 
  [variantId: string]: ShopifyProductVariant 
}

type VariantSelectionProps = {
  variant: ShopifyProductVariant
  onSelection: (variant:ShopifyProductVariant, quantity: number, idx: number) => any
  idx: number
}
const VariantQuantitySelection = ({ variant, onSelection, idx }: VariantSelectionProps) => {
  const [currentQty, setCurrentQty] = useState(0);

  const onIncrement = () => {
    const qty = currentQty < MAX_LINE_ITEM_QUANTITY ? currentQty + 1 : currentQty;
    setCurrentQty(qty);
    onSelection(variant, qty, idx);
  }

  const onDecrement = () => {
    const qty = currentQty >= 1 ? currentQty - 1 : 0;
    setCurrentQty(qty);
    onSelection(variant, qty, idx);
  }

  return (
    <VariantContainer data-testid="tiered-variant-selector">
      <div className="title">
        {variant.title}
      </div>
      <QuantityInput 
        increment={onIncrement} 
        decrement={onDecrement}
        selectedAmount={currentQty}
      />
    </VariantContainer>
  )
}

type Props = {
  productPageLike: {
    tieredDiscountCode?: string
    variantSelectorTitle?: string
  }
  shopifyProductLike: {
    id: string
    variants: ShopifyProductVariant[]
    availableForSale?: boolean
  }
  renderTieredVariantSelectionText: (variantSelection: VariantSelectionMap) => JSX.Element
  testId: string
  onVariantSelection?: (variant: ShopifyProductVariant) => any
}
export const ProductFormTiered = ({ 
  shopifyProductLike, 
  productPageLike,
  renderTieredVariantSelectionText,
  testId,
  onVariantSelection
}: Props) => {  
  const { addVariantsToCart, openCart, checkout } = useCheckout();
  const shopifyDiscount = useShopifyDiscount(productPageLike.tieredDiscountCode);
  const [discountUnlocked, setDiscountUnlocked] = useState<boolean>(false);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [variantSelections, setVariantSelections] = useState<{ [variantId: string] : ShopifyProductVariant & { quantity?: number } } | {}>({})
  const hasSelections = Object.keys(variantSelections).length;

  const totalQty = (variantLike: { quantity: number, id: string } []) => {
    const totalQtyInCart = getTotalQuantityOfProductInCart(shopifyProductLike.variants, checkout)

    return variantLike.reduce((prev, cur) => prev + cur.quantity, 0) + totalQtyInCart;
  }

  useEffect(() => {
    const variants = Object.keys(variantSelections).map(key => variantSelections[key]);

    setSubTotal(
      variants.reduce((prev, cur) => (
        prev + (cur.quantity * Number(cur.price.amount))
      ), 0)
    )

    if (shopifyDiscount) {
      const minQtyRequired = Number(shopifyDiscount.minimumRequirement?.greaterThanOrEqualToQuantity);

      if (totalQty(variants) >= minQtyRequired) {
        return setDiscountUnlocked(true)
      }
    }

    return setDiscountUnlocked(false);
  }, [variantSelections])

  const onSelection = (variant: ShopifyProductVariant, quantity: number) => {
    const copySelections = { ...variantSelections };

    if (quantity === 0) {
      delete copySelections[variant.id]
    } else {
      copySelections[variant.id] = {
        ...variant,
        quantity
      }
    }

    if (typeof onVariantSelection === 'function') onVariantSelection(variant)

    setVariantSelections(copySelections);
  }

  const addToCart = async () => {
    if (!variantSelections) return;
    const lineItems: LineItemInput[] = Object.keys(variantSelections).map(key => ({
      variantId: variantSelections[key].id,
      name: variantSelections[key].title,
      price: variantSelections[key].price.amount,
      quantity: variantSelections[key].quantity,
      sku: variantSelections[key].sku
    }))

    await addVariantsToCart(
      lineItems, 
      true,
      (discountUnlocked && shopifyDiscount) 
        ? shopifyDiscount.code 
        : undefined
    )

    openCart();
  }

  const discountAmount = () => {
    if (!discountUnlocked) return 0;
    return getDiscountedAmount(
      subTotal,  
      'percentage', 
      Number(shopifyDiscount?.percentage) * 100
    )
  }

  return (
    <Container className="add-to-cart" data-testid="product-page-variant-selector">
      {(shopifyProductLike?.availableForSale) && (
        <>
          <div>
            {shopifyDiscount && (
              <div className="discount-header">
                <span className="discount-disclaimer">
                  Get {" "}
                  {Number(shopifyDiscount.percentage) * 100}% OFF
                  when you buy a minimum of {shopifyDiscount.minimumRequirement?.greaterThanOrEqualToQuantity}{" "}
                  of any variant.
                </span>
              </div>
            )}
            <div className="variants">
              {productPageLike.variantSelectorTitle && (
                <div className="tiered-variants-header">
                  <div className="variant-selector-title">
                    {productPageLike.variantSelectorTitle}
                  </div>
                  <div>
                    Quantity:
                  </div>
                </div>
              )}
              {shopifyProductLike.variants.filter(v => v.availableForSale).map((variant, idx) => (
                <VariantQuantitySelection 
                  variant={variant}
                  key={variant.id}
                  onSelection={onSelection}
                  idx={idx}
                />
              ))}
            </div>
          </div> 

          <div className="add-to-cart-details">
          {typeof renderTieredVariantSelectionText === 'function' && (
            <div>{renderTieredVariantSelectionText(variantSelections)}</div>
          )}
          <div className="subtotal">
            <div className="label">SUBTOTAL:{" "}</div>
            <Price 
              basePrice={subTotal} 
              discountAmount={discountAmount()}
              forceShow={Boolean(setDiscountUnlocked)}
            />
          </div>
          </div>
        </>
      )}
      
      <Button
        disabled={!hasSelections} 
        onClick={addToCart} 
        className="add-to-cart-button" 
        data-testid={
          !hasSelections
          ? 'product-overview-out-of-stock-button' 
          : 'product-overview-buy-button'
        }>
          {determineBuyButtonText(
            shopifyProductLike.availableForSale 
            ? ProductAvailabilityStatus.Available
            : ProductAvailabilityStatus.Unavailable
          )}
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  button { 
    padding: 12px 30px;
  }
  .discount-header,
  .add-to-cart-details,
  .subtotal,
  .tiered-variants-header {
    text-transform: uppercase;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
  }

  .subtotal {
    flex: .25;
    margin-bottom: 0;
    .label {
      margin-right: 1rem;
    }
    .price-text {
      display: flex;
      font-size: 2rem;
      line-height: 3.1rem;
    }
  }

  .tiered-variants-header {
    margin-bottom: 1rem;
    div:nth-child(2) {
      text-align: right;
    }
  }

  .discount-disclaimer {
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    background: var(--white);
    border: 1px solid var(--lineColor);
    border-radius: var(--cardRadius);
    padding: 1rem 2.5rem;
    text-transform: initial;
  }

  .variants {
    margin-bottom: 2rem;
  }

  .price-text {
    div {
      &:first-child {
        margin-right: .5rem;
      }
    }
  }


  .single-variant {
    .single-variant-title {
      font-size: 1.6rem
    }

    .price-text {
      display: flex;
      justify-content: center;
      padding-bottom: .5rem;
      font-size: 1.8rem;
      div {
        flex: unset;
      }
    }
  }

  ${below.small`
    .discount-disclaimer {
      padding: 1rem 2rem;
    }

    .add-to-cart-details {
      display: block;
      > div {
        display: flex;
        justify-content: space-between;
      }
    }
  `}
`

const VariantContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  .title {
    padding: 1rem;
    border: 1px solid var(--lineColor);
    border-radius: var(--cardRadius);
    width: 75%;
  }
  .quantity-input {
    width: 25%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 2rem;
    button {
      padding: 0;
    }
  }

  ${below.small`
    font-size: 1.4rem;
  `}

  @media(max-width: 399px) {
    .title {
      border: none;
      padding: 0 2rem 0 0;
      text-align: left;
    }
    .quantity-input {
      margin-left: 1rem;
      .quantity {
        font-size: 16px;
        padding: 1px 8px;
      }
    }
  }
`