import { SUBMIT_ERROR } from 'data'

export const isValidEmail = (email: string) => {
  const pattern = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  )
  return pattern.test(email)
}

// removes server code from front of some error messages
export const formatError = (message: string) => {
  const newMessage = message.split('): ')
  return newMessage.length > 1 ? newMessage[1] : message || SUBMIT_ERROR
}
