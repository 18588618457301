import { Icon } from "components/elements";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { getStoryType } from "./helpers";
import { CustomerStory, CustomerStoryLayout } from "types/misc";
import { CustomerStoryProductList } from "./CustomerStoryProductList";
import { CustomerStoryAuthor } from "./CustomerStoryAuthor";

type Props = {
  customerStory: CustomerStory
  setSelectedStory: (customerStory: CustomerStory) => any
  layout?: CustomerStoryLayout
}
export const CustomerStoryCard = ({ customerStory, setSelectedStory, layout }: Props) => {
  const type = getStoryType(customerStory);
  const image = customerStory.video?.posterImageThumbnail.asset.gatsbyImageData 
    || customerStory.imageThumbnail?.asset.gatsbyImageData

  return (
    <CustomerStoryCardContainer className={`customer-story-card ${layout}`}>
      <div className="customer-card-icons">
        <CustomerStoryProductList 
          customerStory={customerStory} 
          layout="icons" 
        />
      </div>
      <button 
        onClick={() => setSelectedStory(customerStory)}
        className="trigger"
      >
        <div className={`customer-card-image-container ${type}`}>
          {type === "video" && (<div className="video-icon-wrapper"><Icon name="play" className="icon-video" color="white" /></div>)}
          {image && (
            <>
              <GatsbyImage
                image={image}
                alt={`${customerStory.name} customer story media thumbnail`}
              />

              {customerStory.tagline && customerStory.image && (
                <div className="tagline center-text">
                  <blockquote>{customerStory.tagline}</blockquote>
                </div>
              )}
            </>
          )}
          {type === 'text' && customerStory.summary && (
            <div className="summary">
              <blockquote className="quote">{customerStory.summary}</blockquote>
            </div>
          )}
        </div>
      </button>
      <div className="details">
        <CustomerStoryAuthor customerStory={customerStory} />
      </div>
    </CustomerStoryCardContainer>
  )
}

const CustomerStoryCardContainer = styled.div`
  background: var(--white);
  border-radius: var(--cardRadius);
  overflow: hidden;
  position: relative;

  &.grid {
    transition: var(--transition);
    &:hover {
      transform: translateY(-10px);
    }
  }
  
  blockquote {
    &:before, &:after { 
      content: '"';
    }
  }
  .summary {
    height: 29.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--darkNavy);
    color: white;
    padding: 2rem;
    
    blockquote {
      font-size: 2.2rem;
      line-height: 3rem;
    }
  }
  .trigger {
    width: 100%;
  }

  .icons {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: flex-end;
    .product-icons {
      border-bottom-left-radius: 2.5rem;
      padding: .25rem 2rem .25rem 1rem;
      background: var(--darkNavy);
      display: inline-block;
      z-index: 1;
      .product-icon {
        float: left;
      }
    }
  }

  .customer-details {
    min-height: 7rem;
  }

  .customer-card-image-container {
    position: relative;
    overflow: hidden;

    .video-icon-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-video {
        width: 5rem;
        fill: white;
      }
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 29.5rem;
      @media (max-width: 600px) {
        height: auto;
      }
    }
    
    .customer-card-icons {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tagline {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(0,0,0,.5);
      font-size: 1.4rem;
      color: white;
      padding: .75rem 0;
    }
  }
`