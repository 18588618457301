import React from 'react'
import styled from 'styled-components'
import { below } from 'styles'

type Props = {
  text: string
  price: string
  disabled?: boolean
  onClick: () => void
}

// ? Used when a button with different content on 2 sides is needed
// * Mobile styles will need changed if used outside of StickyBar component
export const DualButton = ({
  text,
  price,
  onClick,
  disabled = false,
}: Props) => (
  <DualButtonWrapper
    data-testid="dual-button"
    onClick={onClick}
    disabled={disabled}
    className="dual-button"
  >
    <span className="left">{text}</span>
    <span className="right">${price}</span>
  </DualButtonWrapper>
)

const DualButtonWrapper = styled.button`
  &:disabled {
    cursor: auto;
    background: var(--darkGray);
    border-color: var(--darkGray);
    &:hover {
      background: var(--darkGray);
      border-color: var(--darkGray);
    }
  }
  cursor: pointer;
  border: none;
  transition: var(--transition);
  background: transparent;
  overflow: hidden;
  text-transform: uppercase;
  box-shadow: var(--elevation-1);
  padding: 0;
  border-radius: 40px;
  color: var(--navyBlue);
  background: var(--orange);
  border-color: var(--orange);
  display: flex;
  align-items: center;
  &:disabled {
    background: var(--lighterGray);
    border-color: var(--lighterGray);
    color: var(--white);
    box-shadow: var(--elevation-1);
    cursor: auto;
    &:hover {
      background: var(--lighterGray);
      border-color: var(--lighterGray);
      box-shadow: var(--elevation-1);
      color: var(--white);
    }
  }
  &:hover {
    background: var(--orange);
    border-color: var(--orange);
    color: var(--navyBlue);
  }
  span {
    padding: 1.4rem 3.3rem;
    &.left {
      border-radius: 40px 0 0 40px;
      font-size: var(--baseFontSize);
    }
    &.right {
      position: relative;
      font-size: var(--h4);
      font-weight: bold;
      border-radius: 0 40px 40px 0;
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        height: 100%;
        width: 1px;
        background: var(--white);
      }
    }
  }
  ${below.small`
    width: 100%;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
    justify-content: space-between;
  `};
`
