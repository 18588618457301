import { resolveProduct } from "lib/shopify/transformations"
import { storefrontAPIClient } from "../client"
import { QUERY_PRODUCT_BY_HANDLE } from "../graphql";
import { ShopifyProduct } from "../types/custom.types";
import { Product } from "../types/storefront.types";

export const getProductByHandle = async (handle: string) => {
  const { data, errors } = await storefrontAPIClient.request<{ productByHandle: Product }>(
    QUERY_PRODUCT_BY_HANDLE, 
    { 
      variables: {
        handle 
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.productByHandle) return null;

  return resolveProduct({ node: data.productByHandle });
}

export const getProductsByHandles = async (handles: string[]) => {
  const products = [] as ShopifyProduct[]

  for (const handle of handles) {
    const product = await getProductByHandle(handle);
    if (product) products.push(product);
  }

  return products;
}
