import { resolveCheckout } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { CHECKOUT_LINE_ITEMS_REMOVE_MUTATION } from "../graphql";
import { Checkout } from "../types/storefront.types";
import { ShopifyCheckout } from "../types/custom.types";

type CheckoutResponse = { 
  checkoutLineItemsRemove: {
    checkout:Checkout 
  }
}

export const removeLineItems = async (checkoutId: string, lineItemIds: string[]): Promise<ShopifyCheckout> => {
  const { data, errors } = await storefrontAPIClient.request<CheckoutResponse>(
    CHECKOUT_LINE_ITEMS_REMOVE_MUTATION, 
    { 
      variables: {
        checkoutId, 
        lineItemIds
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.checkoutLineItemsRemove?.checkout) throw new Error('Could not remove line items from checkout!');

  return resolveCheckout(data.checkoutLineItemsRemove.checkout);
}