import React, { useEffect, useRef, useState } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { Grid, below } from 'styles'
import { ShopifyCopyProduct } from 'utilities'
import ProductBadge from './ProductBadge'
import { ProductShareButton } from './ProductShareButton'
import { Icon, ShareButton, Video, VideoIframe } from 'components/elements'
import { trackProductShared } from '../../analytics/events/ecommerce/product-shared'

const MediaItem = ({ mediaItem, height }: { mediaItem: MediaItemProps, height?: number | null }) => {
  return (
    <MediaItemContainer height={height}>
      {mediaItem.mediaContentType === 'IMAGE' ? (
        <GatsbyImage
          image={mediaItem.image.gatsbyImageData}
          alt={mediaItem.image.altText || 'carousel image'}
          objectFit="contain"
        />
      ) : 
      mediaItem.mediaContentType === 'EXTERNAL_VIDEO' ? (
        <VideoIframe 
          videoEmbedUrl={mediaItem.embedUrl ? mediaItem.embedUrl : ''}
          videoId={mediaItem.videoId ? mediaItem.videoId : ''}
          title={`${mediaItem.host} video`}
          disableControls={true}
        />
      ) : 
      mediaItem.mediaContentType === 'VIDEO' ? (
        <Video
          title={mediaItem.alt} 
          enableControls={true}
          sources={mediaItem.sources}
          posterImage={{
            asset: {
              url: mediaItem.previewImage.image.originalSrc
            }
          }}
        />
      ) : null}
    </MediaItemContainer>
  )
}

type MediaItemProps = {
  mediaContentType: 'IMAGE' | 'EXTERNAL_VIDEO'
  id: string,
  image: {
    gatsbyImageData: IGatsbyImageData
    altText?: string
    originalSrc: string
  }
  previewImage: {
    image: {
      gatsbyImageData: IGatsbyImageData
      altText?: string
      originalSrc: string
    }
  }
  sources: { mimeType: string, src: string }[]
  embedUrl: string
  host: 'YOUTUBE'
  thumbnailUrl?: string
  videoId?: string
  alt: string
}

type Props = {
  mediaItems: MediaItemProps[]
  activeImageIndex?: number
  copyProduct?: ShopifyCopyProduct
  enableShare?: boolean
}

type ThumbnailProps = { 
  mediaItem: MediaItemProps, 
  index: number, 
  isActive: boolean, 
  changeImage: (index: number) => void 
}

const Thumbnail = ({ mediaItem, index, changeImage, isActive }: ThumbnailProps) => {
  const activeImage = (
    mediaItem.previewImage?.image 
      ? mediaItem.previewImage?.image 
      : mediaItem.image
  );

  const altText = `${activeImage.altText ? activeImage.altText : ''} thumbnail`;

  return (
    <ThumbnailContainer
      isActive={isActive}
      key={mediaItem.id}
      onClick={() => changeImage(index)}
    >
      {
        ['VIDEO', 'EXTERNAL_VIDEO'].includes(mediaItem.mediaContentType) ? (
          <VideoThumbnailWrapper>
            <div className='icon-wrapper'>
              <Icon 
                name='play' 
                color='white' 
                className='icon-play' 
              />
            </div>
            <img 
              className='video-thumbnail-image'
              src={mediaItem.thumbnailUrl || mediaItem.previewImage.image.originalSrc} 
              alt={altText} 
            />
          </VideoThumbnailWrapper>
        ) : (
          <GatsbyImage
            image={activeImage.gatsbyImageData}
            alt={altText}
            objectFit="contain"
          />
        )
      }
    </ThumbnailContainer>
  )
}

export function ProductCarousel({ mediaItems, copyProduct, activeImageIndex, enableShare = true }: Props) {
  if (!mediaItems) return null

  const [[mediaItem, mediaItemIndex], setImage] = useState([mediaItems[0], 0])
  const activeSlideRef = useRef<HTMLDivElement>(null);
  const initialActiveSlideHeightRef = useRef<number>(null);
  const isVideoMediaItem = ['EXTERNAL_VIDEO', 'VIDEO'].includes(mediaItem.mediaContentType);

  const changeImage = (index: number) => {
    setImage([mediaItems[index], index])
  }

  useEffect(() => {
    if (typeof activeImageIndex === 'number') {
      setImage([mediaItems[activeImageIndex], activeImageIndex])
    }
  }, [activeImageIndex])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activeSlideRef.current && !initialActiveSlideHeightRef.current) {
        initialActiveSlideHeightRef.current = activeSlideRef.current.offsetHeight;
      }
    }, 1000)

    return () => clearTimeout(timeout);
  }, []);

  const handleSuccessfulShare = (shareData: { text: string, url: string, type: string }) => {
    trackProductShared({
      title: shareData.text,
      url: shareData.url,
      type: shareData.type
    })
  }

  return (
    <div style={{ marginBottom: mediaItems.length > 1 ? '0px' : '20px' }}>
      <ImageContainer ref={activeSlideRef}>
        {copyProduct && copyProduct.copy.badgeEnabled && !isVideoMediaItem && (
          <ProductBadge
            product={copyProduct}
          />
        )}
        {enableShare && !isVideoMediaItem ? (
          copyProduct ? (
            <ProductShareButton
              product={copyProduct}
              onSuccessfulShare={handleSuccessfulShare}
            />
          ) : (
            <ShareButton 
              text='Check out this product on rachio.com!'
              url={location.href}
              onSuccessfulShare={handleSuccessfulShare}
            />
          )
        ) : null}
        <MediaItem 
          mediaItem={mediaItem} 
          height={initialActiveSlideHeightRef.current}
        />
      </ImageContainer>

      {mediaItems.length > 1 && (
        <ThumbnailGrid cols={mediaItems.length}>
          {mediaItems.map((mediaItem, index) => (
            <Thumbnail 
              key={`${mediaItem.id}-${index}`} 
              mediaItem={mediaItem} 
              index={index} 
              isActive={index === mediaItemIndex} 
              changeImage={changeImage} 
            />
          ))}
        </ThumbnailGrid>
      )}
    </div>
  )
}

const ImageContainer = styled.div`
  position: relative;
  .product-badge {
    position: absolute;
    top: 8px;
    left: 8px;
    font-size: 1.2rem;
  }
  .btn-share {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
  }
  margin: calc(var(--margin) * 2) 0px calc(var(--margin) / 2) 0px;
  .gatsby-image-wrapper {
    border-radius: var(--cardRadius);
    isolation: isolate;
    border: 1px solid var(--lineColor);
  }

  overflow: hidden;
  display: flex;
  justify-content: center;
  ${below.medium`
    margin:  0px;  
  `}
`

const ThumbnailGrid = styled(Grid)`
  margin: var(--margin) 0;
  grid-gap: 10px;
`

const VideoThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .icon-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .icon-play {
    width: 3rem;
    height: 3rem;
    z-index: 1;
  }
`

const ThumbnailContainer = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  isolation: isolate;
  overflow: hidden;

  .video-thumbnail-image {
    height: auto;
    width: 125%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: -12.5%;
    max-width: initial;
  }
  ${({ isActive }: { isActive: boolean }) =>
    isActive &&
    css`
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #00a7e1;
        opacity: 0.2;
      }
    `}
`

const MediaItemContainer = styled.div<{ height?: number | null }>`
  width: 100%;
  video, iframe {
    width: 100%;
    overflow: hidden;
    height: ${({ height }) => height ? `${height}px` : 'auto'};
    border-radius: var(--cardRadius);
  }
  video {
    object-fit: cover;
  }
`