import React from 'react'
import loadable from '@loadable/component'
import { SectionLeft, SectionRight } from 'components/sections'
import { below, Cols, Grid } from 'styles'
const Button = loadable(() => import('components/elements'), {
  resolveComponent: (components) => components.Button,
})
import { SanitySection } from './SanitySection'
import { Link } from 'gatsby'
import { SectionPaddingSize, SectionSize } from 'types/misc'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import styled from 'styled-components'
const GatsbyImage = loadable(() => import('gatsby-plugin-image'), {
  resolveComponent: (components) => components.GatsbyImage,
})

type Props = {
  heroImage: IGatsbyImageData
  alt?: string
  subtitle?: string
  title?: string
  isTitleH1?: boolean
  description?: string
  buttonLink: string,
  buttonText: string,
  isSplitLayout?: boolean
  imageNode?: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  gradientOpacity?: number
  paddingSize?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
}

const SanityHeroProduct = ({
  heroImage,
  alt = '',
  subtitle = '',
  title,
  isTitleH1 = false,
  description = '',
  buttonLink,
  buttonText,
  imageNode,
  isSplitLayout = true,
  gradientOpacity = 0,
  paddingSize = '',
  paddingTop = '',
  paddingBottom = '',
}: Props) => {
  const darkness = gradientOpacity / 100

  const Content = (
    <>
      <SectionLeft className="Section--left">
        <>
          {subtitle && <h5 className="white-text">{subtitle}</h5>}
          {isTitleH1 ? (
            <h1 className={`${subtitle ? 'no-margins' : ''} white-text`}>
              {title}
            </h1>
          ) : (
            <h2 className={`${subtitle ? 'no-margins' : ''} white-text`}>
              {title}
            </h2>
          )}
          {description && <p className="white-text large Section--left-description">{description}</p>}

          <Button as={Link} to={buttonLink} color="white">
            {buttonText}
          </Button>
        </>
      </SectionLeft>
      <SectionRight className="Section--right">
        {imageNode && (
          <div className="image-container">
            <GatsbyImage
              image={imageNode.asset.gatsbyImageData}
              loading="eager"
              alt={alt}
            />
          </div>
        )}
      </SectionRight>
    </>
  )
  return (
    <Container>
      <GatsbyImage
        alt={alt}
        image={heroImage}
        className="hero-image"
        loading="eager"
      />
      <div
        style={{
          position: 'inherit',
          background: `linear-gradient(90.12deg, rgba(2,2,2,${darkness}) 40%, rgba(0, 0, 0, 0) 100%)`,
        }}
      >
        <SanitySection
          paddingTop={paddingTop}
          paddingBottom={paddingBottom || 'none'}
          size={paddingSize}
        >
          {isSplitLayout ? (
            <Cols>{Content}</Cols>
          ) : (
            <Grid
              cols={[1, 1, 2]}
              className="banner-grid"
            >
              {Content}
            </Grid>
          )}
        </SanitySection>
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  .hero-image {
    max-height: 50vh;
    position: absolute;
    height: 150%;
    width: 100%;
  }

  .Section--left, .Section--right {
    display: block;
  }

  .Section--right {
    max-width: 500px;
  }

  @media (max-width: 1600px) {
    .hero-image {
      max-height: 65vh;
    }
  }

  ${below.large`
    h1 {
      font-size: 3.8rem;
    }
    .hero-image {
      max-height: 70vh;
      height: 120%;
    }
    .banner-grid {
      display: block;
    }
    .Section--left {
      text-align: center;
      margin-bottom: 80px;
    }
    .Section--left-description {
      max-width: 480px;
      margin: 20px auto;
    }
    .Section--right {
      max-width: 500px;
      margin: 0 auto;
    }
  `}

  @media (max-width: 430px) {
    .hero-image {
      max-height: 50vh;
      height: 120%;
    }

    .h1 {
      font-size: 4rem;
    }

    .Section--left {
      text-align: center;
      margin-bottom: 50px;
    }
  }
`

export default SanityHeroProduct