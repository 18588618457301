import React, { FC } from 'react'
import { LoadingProvider } from './loading'
import { DiscountProvider } from './discount'
import { NotificationProvider } from './notification';

// Provider Composer imports all Providers so that we can isolate related state
// All state is managed through state hooks in the individual providers
export function ProviderComposer({ contexts, children }) {
  return contexts.reduceRight(
    (kids, parent) =>
      React.cloneElement(parent, {
        children: kids,
      }),
    children
  )
}

interface Props {
  children: React.ReactNode;
}

export const ContextProvider: FC<Props> = ({ children }) => {
  return (
    <ProviderComposer
      contexts={[
        <LoadingProvider />,
        <NotificationProvider />,
        <DiscountProvider />
      ]}
    >
      {children}
    </ProviderComposer>
  )
}
