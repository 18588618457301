import { FragmentHelpers } from "../../types";
import { VARIANT_FRAGMENT } from "./variant-fragment";

export const PRODUCT_FRAGMENT: FragmentHelpers = {
  fragment: `
    ${VARIANT_FRAGMENT.fragment}
    
    fragment ProductFragment on Product {
      id
      availableForSale
      createdAt
      updatedAt
      descriptionHtml
      description
      handle
      productType
      title
      vendor
      publishedAt
      onlineStoreUrl
      tags
      options {
        name
        values
      }
      media(first: 250) {
        edges {
          node {
            ... on MediaImage {
              mediaContentType
              alt
              image {
                src: url
                altText
              }
              previewImage {
                src: url
                altText
              }
            }

            ... on Video {
              id
              alt
              mediaContentType
              previewImage {
                src: url
                altText
              }
              sources {
                src: url
                mimeType
                width
              }
            }

            ... on ExternalVideo {
              embedUrl
              id
              mediaContentType
              previewImage {
                src: url
                altText
              }
            }
          }
        }
      }
      images(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            src: url
            altText
            width
            height
          }
        }
      }
      variants(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ...VariantFragment
          }
        }
      }
    }
  `,
  ref: '...ProductFragment'
}