import { useState, useEffect } from "react";
import { filterVariantsBySku } from "../../../lib/shopify";
import { GatsbyShopifyStorefrontProduct, ShopifyProductVariant } from "lib/shopify/storefront-api-client/types/custom.types";

export const useBundleVariants = (product: GatsbyShopifyStorefrontProduct, copy: { filterSku?: string }) => {
  const variantIdx = filterVariantsBySku(product.variants, copy.filterSku);
  const [selectedVariant, setSelectedVariant] = useState<ShopifyProductVariant>(
    product.variants[variantIdx]
  )
  const variants = copy.filterSku ? [product.variants[variantIdx]] : product.variants

  useEffect(() => {
    if (variants.length > 1) {
      setSelectedVariant(
        product.variants[filterVariantsBySku(product.variants, copy.filterSku)]
      )
    } else {
      setSelectedVariant(product.variants[0])
    }
  }, [product])

  const availableVariants = variants.filter(v => v.availableForSale);

  return {
    availableVariants,
    selectedVariant,
    setSelectedVariant
  }
}