export const shareTwitter = (url: string, text?: string) => {
  let shareUrl = url
  if (typeof window !== 'undefined') {
    // Opens a pop-up with twitter sharing dialog
    shareUrl = 'http://twitter.com/share?' // url base
    // params
    const params = {
      url,
      via: 'rachioco',
      text
    }

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        shareUrl += `&${key}=${encodeURIComponent(params[key])}`
      }
    })

    window.open(
      shareUrl,
      '',
      'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
    )
  }
}

export const shareFacebook = (url: string) => {
  let shareUrl = url
  if (typeof window !== 'undefined') {
    shareUrl = `https://facebook.com/sharer.php?u=${encodeURIComponent(url)}`
    window.open(
      shareUrl,
      '',
      'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
    )
  }
}

export const sharePinterest = (url: string) => {
  let shareUrl = url
  if (typeof window !== 'undefined') {
    shareUrl = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}`
    window.open(
      shareUrl,
    )
  }
}