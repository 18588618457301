import { trackEvent, TrackFunction } from "analytics"
import { ShoppingEventInputs, SHOPPING_EVENT_NAMES, TrackingProductUnformatted } from "."
import { getTrackedPropertiesFromVariant } from "./transformers/product"

const track: TrackFunction<SHOPPING_EVENT_NAMES.PRODUCT_VIEWED, ShoppingEventInputs[SHOPPING_EVENT_NAMES.PRODUCT_VIEWED]> = async (
  eventName,
  properties
) => {
  await trackEvent(eventName, properties);
}

/**
 * Expected Event Payload:
 * {
    product_id: "gid://shopify/ProductVariant/41924475158706",
    sku: "8ZULW-C",
    name: "Rachio 3 8-Zone",
    price: 199.99,
    quantity: 1,
    category: "CONTROLLER",
    event: "Product Viewed",
    gtm.uniqueEventId: 1645
  }
 */
export const trackProductViewed = async (variant: TrackingProductUnformatted) => {
  if (variant) {
    await track(
      SHOPPING_EVENT_NAMES.PRODUCT_VIEWED, 
      getTrackedPropertiesFromVariant(variant)
    )
  }
}