import { Button } from "@rachio-npm/rachio-retail-common"
import React from "react"
import { VariantSelector } from "../VariantSelector"
import styled from "styled-components"
import { useDiscount } from "global"
import { Price } from "../Price"
import { getBasePrice } from "utilities"
import { QuantitySelector } from "../QuantitySelector"
import { ShopifyProductVariant } from "lib/shopify/storefront-api-client/types/custom.types"

type Props = {
  shopifyProductLike: {
    variants: ShopifyProductVariant[]
    availableForSale?: boolean
  }
  selectedVariant: ShopifyProductVariant
  setSelectedVariant: (variant: ShopifyProductVariant) => any
  addToCart: () => any
  testId: string
  buttonText: string
  buttonTheme?: string
  hidePrice?:boolean
  singleVariantLayout?: boolean
  quantitySelectionEnabled?: boolean
  currentQuantity: number,
  setCurrentQuantity: (qty: number) => any
}
export const ProductFormMini = ({ 
  shopifyProductLike, 
  selectedVariant, 
  setSelectedVariant,
  addToCart,
  testId,
  buttonText,
  buttonTheme,
  hidePrice,
  singleVariantLayout,
  quantitySelectionEnabled,
  currentQuantity,
  setCurrentQuantity
}: Props) => {
  const { getDiscountAmount } = useDiscount();
  
  return (
    <Container className="add-to-cart">
      {shopifyProductLike.availableForSale && (
         <>
            {quantitySelectionEnabled && (
              <div className="qty-selector-wrapper">
                <QuantitySelector 
                  selectedVariant={selectedVariant}
                  currentQuantity={currentQuantity}
                  setCurrentQuantity={setCurrentQuantity}
                  hidePrice={true}
                />
              </div>
            )}
            {
              shopifyProductLike 
              && shopifyProductLike.variants.length > 1
                ? (
                  <VariantSelector 
                    variants={shopifyProductLike.variants}
                    setSelectedVariant={setSelectedVariant}
                    selectedVariant={selectedVariant}
                    hidePrice={Boolean(hidePrice)}
                    type='input'
                  />
                ) : (
                  shopifyProductLike.variants.length === 1 
                  && singleVariantLayout
                )
                ? (
                  <div className="single-variant">
                    <div className="single-variant-title">
                      {selectedVariant.title}
                    </div>
                    <Price 
                      basePrice={getBasePrice(selectedVariant)} 
                      discountAmount={getDiscountAmount(selectedVariant)}
                    />
                  </div>
                ) : null
            }
         </>
      )}
      
      <Button
        color={buttonTheme}
        disabled={!selectedVariant.availableForSale} 
        onClick={addToCart} 
        className="add-to-cart-button" 
        data-testid={selectedVariant.availableForSale && testId}>
          {buttonText}
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  text-align: center;
  > div:not(.qty-selector-wrapper) {
    flex: 1;
  }
  
  button { 
    padding: 12px 30px;
  }

  .qty-selector-wrapper {
    margin-top: 1rem;
    button {
      padding: .5rem;
      margin: 0;
    }
    .quantity-input-wrapper {
      margin-bottom: 0;
    }
  }

  .single-variant {
    .single-variant-title {
      font-size: 1.6rem
    }

    .price-text {
      display: flex;
      justify-content: center;
      padding-bottom: .5rem;
      font-size: 1.8rem;
      div {
        flex: unset;
        &:first-child {
          margin-right: .5rem;
        }
      }
    }
  }
`