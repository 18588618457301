import { useEffect, useState } from 'react'

export const useCustomDate = (date: string): string => {
  const [publishedAtParsed, setPublishedAt] = useState('')

  useEffect(() => {
    const parseDate = async () => {
      if (typeof date != 'string') return
      const { default: dayjs } = await import('dayjs')
      const parsed = dayjs(date).format('MM/DD/YYYY')
      setPublishedAt(parsed)
    }
    parseDate()
  }, [])

  return publishedAtParsed
}
