import React, {
  createContext, FC,
} from 'react'
import { useIpGeolocation, GeoIpLocationResponse } from '../lib/ip-geolocation';

interface AuthContextValues {
  isInternational: boolean;
  geolocationData: GeoIpLocationResponse,
  internationalLink: {
    copy: string,
    href: string
  }
}

export const initialAuthValues: AuthContextValues = {
  isInternational: false,
  geolocationData: {
    country_code: "",
    postal_code: "",
  },
  internationalLink: {
    copy: "",
    href: ""
  }
}

export const IpGeolocationContext = createContext(initialAuthValues)

export const IpGeolocationProvider = ({ children }) => {
  const { isInternational, internationalLink, geolocationData } = useIpGeolocation();

  return (
    <IpGeolocationContext.Provider
      value={{
        isInternational,
        geolocationData,
        internationalLink
      }}
    >
      {children}
    </IpGeolocationContext.Provider>
  )
}