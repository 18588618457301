import { Icon } from "components/elements";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { CustomerStory } from "types/misc";

type Props = {
  customerStory: CustomerStory
}
export const CustomerStoryAuthor = ({ customerStory }: Props) => {
  return (
    <Container className="customer-details">
      {customerStory.headshot && (
        <div className="headshot-image-container">
          <GatsbyImage
            image={customerStory.headshot?.asset.gatsbyImageData}
            alt={customerStory.headshotImageAltText || `customer ${customerStory.name} headshot`}
          />
        </div>
      )}
        
      <div className="name">
        {customerStory.name}
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 1.5rem;
  .headshot-image-container {
    max-width: 5rem;
    border-radius: 50%;
    overflow: hidden;
    background: white;
    margin-right: 1rem;
  }

  .icon-container {
    overflow:hidden; 
    border-radius: 50%;
    padding: .5rem;
    border: 1px solid var(--darkNavy);
    width: 5rem;
    height: 5rem;
    svg {
      position: relative;
      top: 3px;
      width: 4rem;
      height: 4rem;
    }
  }
`