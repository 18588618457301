import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useWindowWidth } from "hooks";
import styled, { css } from "styled-components";
import { sizes } from "styles";
import { Image, SectionPaddingSize, SectionTheme } from "types/misc";
import { Section } from "./Section";

export type ThumbnailNavigationItem = {
  id: string
  url: string,
  image: Image,
  alt: string
  title: string
  active?: boolean,
}

type ThumbnailNavigationCardProps = { 
  navItem: ThumbnailNavigationItem,
}
const ThumbnailNavigationCard = ({ 
  navItem,
}: ThumbnailNavigationCardProps) => {
  return (
    <div 
      className={
        `
          thumbnail-nav-item
          ${navItem.active ? 'active-nav-item' : ''}
        `
      }
    >
      <Link to={navItem.url}>
        <div 
          className={`
            nav-item-title 
          `}
        >
          {navItem.title}
        </div>
      </Link>
    </div>
  )
}

type Props = {
  id: string
  header?: string
  description?: string
  theme?: SectionTheme,
  thumbnailNavigationItems: ThumbnailNavigationItem[]
  activeThumbnailIndex?: number
  paddingTop?: SectionPaddingSize
  paddingBottom?: SectionPaddingSize
  headlineH1?: boolean
}
const ThumbnailNavigationSection = ({ 
  header, 
  description,
  theme,
  id,
  thumbnailNavigationItems,
  activeThumbnailIndex,
  headlineH1
}: Props) => {
  if (!thumbnailNavigationItems) return null;
  const navigationItems = [...thumbnailNavigationItems];

  if (typeof activeThumbnailIndex === 'number' && activeThumbnailIndex !== -1) {
    navigationItems[activeThumbnailIndex || 0].active = true;
  }

  return (
    <Container theme={theme}>
      <Section
        id={id}
        theme={theme || 'gray'}
        paddingTop='none'
        paddingBottom='none'
      >
        {thumbnailNavigationItems.length > 0 && (
          <>
            <div>
              {header && 
                (
                  <div className="header">
                    {headlineH1 ? <h1>{header}</h1> : <h2>{header}</h2>}
                    {description && <p>{description}</p>}
                  </div>
                )
              }

              <div className="thumbnail-navigation-items">
                {navigationItems.map((navItem) => (
                  <ThumbnailNavigationCard 
                    key={navItem.id}
                    navItem={navItem}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </Section>
    </Container>
  )
}

const Container = styled.div`
  .Section > div {
    padding: 0;
  }

  .header {
    padding: 40px var(--gutterWidth) 0;
    h1, p, h2 {
      margin: 0;
    }
  }

  .thumbnail-navigation-items {
    font-family: var(--headlineFont);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    padding: 2rem var(--gutterWidth) 4rem;
  }

  .thumbnail-nav-item {
    isolation: isolate;
    border-radius: 25px;
    overflow: hidden;
    display: block;
    margin: 10px 10px 0 0;
    flex-shrink: 0;
    &:focus {
      border: 1px solid;
    }
    .nav-item-title {
      background: white;
      padding: .5rem 2rem;
      text-align: center;
      font-size: 14px;
      color: var(--sectionTextColor);
    }

    &.active-nav-item {
      .nav-item-title {
        ${({ theme }) => {
          if (['primary', 'secondary', 'green'].includes(theme)) {
            return css`
              background: var(--gray);
              color: var(--darkNavy);
            `
          }

          return css`
            background: var(--darkNavy);
            color: white;
          `
        }}
      }
    }
  }
`

export default ThumbnailNavigationSection;