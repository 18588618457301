import { ShareButton } from "components/elements";
import { useDiscount } from "global";
import React, { useEffect, useState } from "react";
import { trackProductShared } from "../../analytics/events/ecommerce/product-shared";
import { Discount } from "types/misc";
import { isProductDiscounted } from "utilities";

type ShareDetails = { 
  text: string, 
  url: string 
}

const buildSharingDetails = (discount: Discount, product: CopyProductLike): ShareDetails => {
  let text = `Check out the ${product.title} on rachio.com!` 
  const trailingSlashPath = product.copy.seoPathname.endsWith('/') 
    ? product.copy.seoPathname 
    : `${product.copy.seoPathname}/`;

  const url = new URL(location.origin + trailingSlashPath);

  if (discount.isActive && isProductDiscounted(discount, product)) {
    const amountText = discount.amount ? `$${Number(discount.amount).toFixed(2)}` : `${discount.percentage}%`
    text = `${text} Get it for ${amountText} OFF only for a limited time.`
    const params = new URLSearchParams(url.search);
    if (params.has('discount')) params.delete('discount')
    params.append('discount', discount.code);
    url.search = params.toString();
  }

  return {
    text,
    url: url.href
  }
}

type CopyProductLike = {
  copy: {
    seoPathname: string
  }
  id: string,
  title: string
  variants: {
    sku: string
    available: boolean
  }[]
};

type Props = {
  product: CopyProductLike
  iconColor?: string
  iconBackgroundColor?: string
  onSuccessfulShare?: (shareData: { text: string, url: string, type: string }) => any
}
export const ProductShareButton = ({ 
  product,
  iconColor, 
  iconBackgroundColor,
  onSuccessfulShare
}: Props) => {
  const { discount } = useDiscount();
  const [shareDetails, setShareDetails] = useState<ShareDetails|null>(null);
  
  useEffect(() => {
    if (discount) {
      setShareDetails(buildSharingDetails(discount, product))
    }
  }, [discount])

  if (!shareDetails) return null;

  return (
    <ShareButton
      iconColor={iconColor}
      iconBackgroundColor={iconBackgroundColor}
      onSuccessfulShare={onSuccessfulShare}
      {...shareDetails}
    />
  )
}