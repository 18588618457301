import React from "react";
import styled from "styled-components";

export const AlertBanner = ({ text }: { text: string }) => {
  return <Container className="product-alert-banner">{text}</Container>
}

const Container = styled.h4`
  text-align: center;
  padding: 5px;
  color: var(--white);
  background: var(--darkNavy);
  margin-top: var(--largeMargin);
`