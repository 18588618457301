export type TrackFunction<TEventName, TProps> = (eventName: TEventName, props: TProps) => Promise<void>;

/**
 * This function acts as the generic tracking service call
 * It is placed here so that in the scenario we want to
 * change analytics services in the future or add aditional functionality
 * to this trackEvent call, we only have to do it in one place
 */
import { isValid } from "./helpers";

import { trackEvent as trackEventAdapter, identify as identifyAdapter } from "./adapters/google-tag-manager";

export const trackEvent = async (eventName: string, properties: any) => {
  if (!isValid(eventName)) {
    return console.error('eventName is undefined')
  } else {
    await trackEventAdapter(eventName, properties || {})
  }
}

export const identify = identifyAdapter