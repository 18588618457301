import React, { ReactNode } from 'react'
import { Wrapper } from 'styles'
import { SectionTheme, SectionPaddingSize, SectionSize } from 'types/misc'
import { SectionOuter } from './sectionStyle'

// ? Used for sections with standardized section padding

type Props = {
  theme?: SectionTheme | ''
  children: ReactNode
  id?: string
  width?: 'tight' | string // Max content width in px
  size?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
}

export function Section({
  children,
  theme = '',
  id = '',
  width = '',
  size = '',
  paddingTop = '',
  paddingBottom = '',
}: Props) {
  return (
    <SectionOuter
      id={id}
      className={`Section ${theme ? `Section--theme__${theme}` : ''}
    `}
    >
      <Wrapper width={width}>
        <div
          className={`Section--padding
          ${size ? `Section--padding__${size}` : ''}
          ${paddingTop ? `Section--padding-top__${paddingTop}` : ''}
          ${paddingBottom ? `Section--padding-bottom__${paddingBottom}` : ''}
          `}
        >
          {children}
        </div>
      </Wrapper>
    </SectionOuter>
  )
}
