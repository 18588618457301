import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { Icon } from './Icon'

type Props = {
  title?: string
  hasBorder?: boolean
  children: JSX.Element
  theme?: string
  arrowColor ?: string,
  onClick?: () => any,
  isOpen?: boolean
}

export function Accordion({ 
  title = '', 
  children, 
  hasBorder = true, 
  theme = 'default', 
  arrowColor = 'var(--primaryColor)',
  onClick,
  isOpen
}: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    if (typeof isOpen === "boolean") setIsMenuOpen(isOpen)
  }, [isOpen])

  const variants = {
    expanded: () => ({
      height: 'auto',
      opacity: 1,
    }),
    collapsed: () => ({
      height: 0,
      opacity: 0,
      transition: { duration: 0.3 },
    }),
  }

  return (
    <AccordionWrapper
      isMenuOpen={isMenuOpen}
      hasTitle={!!title}
      hasBorder={hasBorder}
      className={`accordion Accordion--theme-${theme}`}
      onClick={() => {
        setIsMenuOpen(!isMenuOpen)
        if (typeof onClick === "function") onClick()
      }}
    >
      <button
        className={`accordion-wrapper`}
      >
        {title && <div className="h5">{title}</div>}
        <Icon
          name="caret"
          accent={arrowColor}
          style={{
            transform: isMenuOpen && 'rotate(-180deg)',
            transition: 'var(--transitionSpeed) ease-in-out transform',
            minWidth: '24px',
            marginLeft: 'var(--smallMargin)',
          }}
        />
      </button>
      <motion.div
        initial={isMenuOpen ? 'expanded' : 'collapsed'}
        animate={isMenuOpen ? 'expanded' : 'collapsed'}
        variants={variants}
      >
        {children}
      </motion.div>
    </AccordionWrapper>
  )
}

const AccordionWrapper = styled.div<{
  hasBorder?: boolean
  hasTitle?: boolean
  isMenuOpen?: boolean
}>`
  width: 100%;
  border-bottom: ${({ hasBorder }) =>
    hasBorder ? '1px solid var(--lightestGray)' : 'none'};
  .accordion-wrapper {
    width: 100%;
    display: flex;
    justify-content: ${({ hasTitle }) =>
      hasTitle ? 'space-between' : 'center'};
    align-items: center;
    overflow: hidden;
    padding: 2rem 0;
    cursor: pointer;
    margin: 0;
    .h5 {
      margin: 0;
      text-align: left;
    }
  }
  &.Accordion--theme-white {
    background: white;
    padding: .5rem 2rem;
    border-radius: var(--cardRadius);
    border-bottom: none;
    margin-bottom: 1.5rem;
  }
  div {
    overflow: hidden;
  }
  ul.disc, 
  ol.disc, 
  ul, 
  ol {
    margin-left: 0;
  }
`
