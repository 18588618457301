export const getUrlParam = (param) => {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    return urlParams?.get(param)
  }
}

export const hasUrlParam = (param) => {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    return urlParams?.has(param)
  }
}

export const transformParams = (url: string) => {
  const idx = url.indexOf('?') + 1
  const preQuery = url.slice(0, idx)
  const postQuery = url.slice(idx)
  const replaced = postQuery.replace(/\?/g, '&')
  return preQuery + replaced
}

export const checkParams = (param: string, setParam: (_: string) => void) => {
  if (hasUrlParam(param)) {
    const urlParams = getUrlParam(param)
    setParam(urlParams || '')
    return urlParams
  }
}

export const isBrowser = typeof window !== 'undefined'