import React, { useEffect, useRef } from "react";
import { useOnScreen, useUserAgentDetection } from 'hooks'

export enum VideoMimeType {
  MP4 = 'video/mp4',
  WEBM = 'video/webm'
}

export type VideoSource = {
  mimeType: string,
  src?: string
}

type Props = {
  title: string
  sources: VideoSource[]
  enableControls?: boolean
  posterImage: {
    asset: {
      url: string
    }
  }
}
export const Video = ({ title, sources, enableControls, posterImage }: Props) => {
  const userAgent = useUserAgentDetection();
  const ref = useRef<HTMLVideoElement>(null)
  const isVisible = useOnScreen(ref)

  const getVideoOptions = () : React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> => {
    const defaultSettings = {
      playsInline: true,
      autoPlay: false,
      poster: posterImage.asset.url
    }
    if (Boolean(enableControls)) {
      return {
        controls: true,
        ...defaultSettings
      }
    }
    return {
      ...defaultSettings,
      controls: false,
      muted: true,
      loop: true,
      autoPlay: userAgent.isiOS,
    }
  }

  useEffect(() => {
    if (isVisible && ref.current && !Boolean(enableControls)) {
      ref.current.play();
    }
  }, [isVisible]);

  return (
    <video
      {...getVideoOptions()}
      ref={ref}
      title={title}
    >
      {sources.map((source, index) => (
        <source 
          key={`video-source-${index}`} 
          src={source.src} 
          type={source.mimeType} 
        />
      ))}
    </video>
  )
}