import { resolveCheckout } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { CHECKOUT_NODE_QUERY } from "../graphql";
import { Checkout } from "../types/storefront.types";
import { ShopifyCheckout } from "../types/custom.types";

export const fetchCheckout = async (id: string): Promise<ShopifyCheckout | null> => {
  const { data, errors } = await storefrontAPIClient.request<{ node: Checkout }>(
    CHECKOUT_NODE_QUERY, 
    { 
      variables: {
        id
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.node) {
    console.error('Could not fetch checkout!', id)
    return null;
  }

  return resolveCheckout(data.node);
}