import React from 'react'
import styled from 'styled-components'
import { Icon } from 'components/elements'
import { Link } from 'gatsby'
import { below } from 'styles'
import { SocialShareButtons } from 'components/elements'
import loadable from '@loadable/component'
const GatsbyImage = loadable(() => import('gatsby-plugin-image'), {
  resolveComponent: (components) => components.GatsbyImage,
})
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { useCustomDate } from 'hooks'
import { ARTICLE_TAXONOMY, ARTICLE_TYPE, ArticleTopic } from 'types/misc'
import { SanityArticleTopic } from 'components/elements/SanityArticleTopic'
import { sortTopicsAlpha } from 'utilities'
import { navigate } from "gatsby";
type Props = {
  type: ARTICLE_TYPE,
  author?: {
    name: string
    positions: string[]
  }
  title: string
  mainImage?: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  mainImageAltText?: string
  publishedAt: string
  topics?: ArticleTopic[]
}

export const SanityArticleHero = ({
  type,
  author,
  title,
  mainImage,
  mainImageAltText,
  publishedAt,
  topics
}: Props) => {
  const publishedAtParsed = useCustomDate(publishedAt)

  const goBack = (e: React.MouseEvent<HTMLAnchorElement> | undefined) => {
    e && e.preventDefault()
    if (!window.history.state) return navigate(ARTICLE_TAXONOMY[type])
    navigate(-1)
  };

  return (
    <ArticleHeroContainer data-testid="article-hero">
      <div
        className={`grid one-col`}
      >
        <div className='hero-meta one-col'>
          <h1 className="title">{title}</h1>

          <div className="author">
            <div className="name-positions">
              <span>
                {author && (
                  <>
                    <strong>{author?.name || 'TEAM RACHIO'}</strong>{' '} <span> | </span>
                  </>
                )}
                {publishedAtParsed && (<span>Published: {publishedAtParsed}</span>)}
              </span>

              {topics && topics.length > 0 && (
                <div className="topics center-text">
                  {sortTopicsAlpha(topics).slice(0, 4).map(topic => (
                    <SanityArticleTopic 
                      key={`${title} - ${topic.id}`}
                      topic={topic} 
                      style={{ marginRight: '3px' }}
                    />
                  ))}
                </div>
              )}


              {type === ARTICLE_TYPE.ARTICLE && (
                <>
                  <SocialShareButtons isPc={false} />
                  <a onClick={goBack} className="back">
                    <Icon name="caret" className="icon-left" accent="#00a7e1" />
                    <span className="back-text">Go Back</span>
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
        {mainImage?.asset.gatsbyImageData && (
          <div className="main-image-container">
            <GatsbyImage 
              image={mainImage?.asset.gatsbyImageData} 
              alt={mainImageAltText ? mainImageAltText : `${title} image`} 
            />
            {type === ARTICLE_TYPE.ARTICLE && <SocialShareButtons isPc={true} />}
          </div>
        )}

      </div>
    </ArticleHeroContainer>
  )
}

const ArticleHeroContainer = styled.div`
  .grid {
    display: grid;
  }
  .two-cols {
    grid-template-columns: repeat(2, 1fr);
  }
  .one-col {
    grid-template-columns: repeat(1, 1fr);
  }
  .main-image-container {
    position: relative;
    overflow: hidden;
    isolation: isolate;
    border-radius: var(--cardRadius);
    margin-bottom: var(--margin);
  }

  .hero-bg {
    height: 100%;
  }
  .title {
    margin: var(--margin) 0;
  }
  .hero-meta {
    text-align: center;
    padding: 60px 100px 0px 80px;
    .icon-left {
      transform: rotateZ(90deg);
    }
  }
  .hero-meta.one-col {
    width: 100%;
  }
  .hero-meta.two-cols {
    max-width: 620px;
  }
  .hero-meta.one-col h1 {
    text-align: center;
  }
  .back {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    margin-top: calc(var(--margin) * 1.5);
    svg {
      max-height: 10px;
    }
    .back-text {
      color: var(--primaryColor);
      transition: var(--transition);
      &:hover {
        color: var(--primaryBlue);
      }
    }
  }
  .author {
    display: flex;
  }
  .topics {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    span {
      margin-bottom: 3px;
    }
  }
  .publishedAt {
    padding: var(--basePadding) 0;
    text-align: end;
    float: left;
    width: 100%;
    span {
      color: #767676;
      font-weight: bold;
    }
  }
  .name-positions {
    padding: 0 0 var(--basePadding) 0;

    width: 100%;
  }

  ${below.medium`
    grid-template-columns: repeat(1, 1fr);

    .two-cols {
      grid-template-columns: repeat(1, 1fr);
    }

    .title{
      font-size: 30px;
    }
    .hero-meta {
      padding: 30px 0;
      width: 100%;
    }
  `}
`
