import styled, { css } from 'styled-components'
import { media } from './breakpoints'
// Grids & Utilities
// 🔒 Not configurable
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Used anytime you need 2 columns inside of a layout
// Cards, Images, nearly anything
// * scalign: any justify-content property value = 'space-between'
export const Flex = styled.div`
  display: flex;
  margin: 0 auto;
  > *:last-child {
    margin-left: var(--margin);
  }
  justify-content: ${
    ({ scalign }: { scalign?: string }) => scalign || 'space-between'
  };
`

// Used anytime you need a grid of anything.
// Cards, Images, nearly anything
// * cols: [1,2,3], 1
export const Grid = styled.div<{
  cols?: number | number[]
  twoColsGridGap?: boolean
}>`
  display: grid;
  grid-gap: ${({ twoColsGridGap }) =>
    twoColsGridGap ? 'var(--twoColsGridGap)' : 'var(--gridGap)'};
  margin: 0 auto;
  ${({ cols = [] }) => {
    if (typeof cols === 'object') {
      return cols.map((_, index: number) => {
        // This nifty map goes over each col and adds style to a breakpoint
        const key = Object.keys(media)[index]
        return media[key]`
         grid-template-columns: repeat(${cols[index]}, 1fr);
      `
      })
    }
    return css`
      grid-template-columns: repeat(${cols}, 1fr);
    `
  }};
`

export const Card = styled.div`
  padding: var(--cardPadding);
  border-radius: var(--cardRadius);
  background: var(--white);
  box-shadow: var(--elevation-1);
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
  .number-header {
    font-weight: var(--fontWeightBold);
    font-size: var(--bigFontSize);
    line-height: var(--bigFontSize);
    margin-bottom: var(--smallMargin);
  }
`

// Used anytime we need padding and max width in a layout
// if bg has color, needs to be wrapped in a div with a background color
//  * width: "tight" or width in px
export const Wrapper = styled.div<{
  padding?: string
  maxWidth?: string
  width?: string
}>`
  padding: ${({ padding = 'var(--gutterWidth)' }) => `0 ${padding}`};
  margin: 0 auto;
  max-width: ${({ maxWidth = 'var(--layoutWidthMax)' }) => maxWidth};
  ${media.large`
    max-width: ${({ width }) =>
      width === 'tight' ? 'var(--layoutWidthTight)' : width};
  `};
`

// This is a standard layout only to be used as a main page layout
// Anytime you want the uneven two col layout.
export const Cols = styled.div<{ isReverse?: boolean }>`
  display: grid;
  grid-gap: var(--twoColsGridGap);
  grid-template-columns: 1fr;
  ${media.medium`
    grid-template-columns: ${({ isReverse }: { isReverse?: boolean }) =>
      isReverse ? 'var(--sidebarWidth) 1fr' : '1fr var(--sidebarWidth)'};
  `};
`
