import UAParser from "ua-parser-js";

type UserAgentDetails = {
  browser?: string
  isApple: boolean
  isAndroid: boolean
  isiOS: boolean
  isWindows: boolean 
  isSafari?: boolean
}

export const useUserAgentDetection = (): UserAgentDetails => {
  const parser = UAParser();

  return {
    browser: parser.browser.name,
    isiOS: parser.os.name === 'iOS',
    isApple: parser.os.name === 'iOS' || parser.os.name === 'Mac OS',
    isAndroid: Boolean(parser.os.name?.includes('Android')),
    isWindows: parser.os.name === 'Windows',
    isSafari: parser.browser.name?.includes('Safari')
  }
}