type SkuCategorized = {
  name: string,
  category: string
}

type SkuMap = {
  [name: string]: SkuCategorized
}

export enum PRODUCT_CATEGORY {
  RACHIO = 'Rachio',
  ACCESSORY = 'ACCESSORY',
  INTEGRATION = 'Integration',
  MARKET = 'Market'
}

export const SKU_NAME_MAP: SkuMap = {
  '8ZULW-C': {
    name: 'Rachio 3 8-Zone',
    category: PRODUCT_CATEGORY.RACHIO
  },
  '16ZULW-C': {
    name: 'Rachio 3 16-Zone',
    category: PRODUCT_CATEGORY.RACHIO
  },
  '8ZULWC-L': {
    name: 'Rachio 3 8-Zone',
    category: PRODUCT_CATEGORY.RACHIO
  },
  '4ZULW-C': {
    name: 'Rachio 3 4-Zone',
    category: PRODUCT_CATEGORY.RACHIO
  },
  '16RACHBX': {
    name: 'Weatherproof Enclosure',
    category: PRODUCT_CATEGORY.RACHIO
  },
  'SHVK001': {
    name: 'Smart Hose Timer Starter Kit (Valve + WiFi Hub)',
    category: PRODUCT_CATEGORY.RACHIO
  },
  'SHV01001': {
    name: 'Smart Hose Timer Starter Kit (Valve Only)',
    category: PRODUCT_CATEGORY.RACHIO
  },
  'WF-TWS01-R1': {
    name: 'Tempest Weather System',
    category: PRODUCT_CATEGORY.INTEGRATION
  },
  'WF-TWS01-R2': {
    name: 'Rachio Tempest Bundle',
    category: PRODUCT_CATEGORY.INTEGRATION
  },
  '10001-misc-elec-base-replace-1y': {
    name: 'Extend Protection Plan',
    category: PRODUCT_CATEGORY.ACCESSORY,
  },
  '10001-misc-elec-base-replace-2y': {
    name: 'Extend Protection Plan',
    category: PRODUCT_CATEGORY.ACCESSORY,
  },
  '10001-misc-elec-base-replace-3y': {
    name: 'Extend Protection Plan',
    category: PRODUCT_CATEGORY.ACCESSORY,
  },
}