import React from 'react'
import loadable from '@loadable/component'
const Button = loadable(() => import('components/elements'), {
  resolveComponent: (components) => components.Button,
})
import { SanitySection } from './SanitySection'
import { SectionInner } from './sectionStyle'
import { Link } from 'gatsby'
import { CTA, SectionPaddingSize, SectionSize } from 'types/misc'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import styled from 'styled-components'
const GatsbyImage = loadable(() => import('gatsby-plugin-image'), {
  resolveComponent: (components) => components.GatsbyImage,
})


/**
 * @prop fluid can take an array of a gradient and image path for image overlays
 * @prop alt is the string to display in case an image cannot be displayed
 * @prop subtitle is the string that is displayed above the h2 title
 * @prop title is the section title string
 * @prop isTitleH1 boolean value to conditionally render an h2 tag in place of an h1 tag
 * @prop description is the section paragraph
 * @prop cta reference which contains the CTA information like button text and button link
 * @prop paddingSize custom padding size of hero
 */

type Props = {
  heroImage?: IGatsbyImageData
  alt?: string
  subtitle?: string
  title?: string
  isTitleH1?: boolean
  description?: string
  cta?: CTA[]
  gradientOpacity?: number
  paddingSize?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
  textColor?: string
  backgroundColor ?: string
}

const SanityHero = ({
  heroImage,
  alt = '',
  subtitle = '',
  title,
  isTitleH1 = false,
  description = '',
  cta,
  gradientOpacity = 0,
  paddingSize = '',
  paddingTop = '',
  paddingBottom = '',
  backgroundColor = '',
  textColor,
}: Props) => {
  const darkness = gradientOpacity / 100

  return (
    <>
      <Container style={{ position: 'relative', backgroundColor: backgroundColor || 'transparent' }}>
        {
          !backgroundColor
            ? (
              <GatsbyImage
                style={{ position: 'absolute', height: '100%', width: '100%' }}
                alt={alt || String(title)}
                image={heroImage as IGatsbyImageData}
              />
            )
            : null
        }
        <div
          style={{
            position: 'inherit',
            background: `linear-gradient(90.12deg, rgba(2,2,2,${darkness}) 40%, rgba(0, 0, 0, 0) 100%)`,
          }}
        >
          <SanitySection
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            size={paddingSize}
          >
            <div style={{ color: textColor  }}>
              <SectionInner>
                {subtitle && <div className='h6'>{subtitle}</div>}
                {isTitleH1 ? (
                  <h1 className={`${subtitle ? 'no-margins' : ''} `}>
                    {title}
                  </h1>
                ) : (
                  <h2 className={`${subtitle ? 'no-margins' : ''} `}>
                    {title}
                  </h2>
                )}
                {description && <p className="large hero-description">{description}</p>}
                {Array.isArray(cta) && cta[0] && (
                  <Button as={Link} to={cta[0].buttonLink}>
                    {cta[0].buttonText}
                  </Button>
                )}
              </SectionInner>
            </div>
          </SanitySection>
        </div>
      </Container>
    </>
  )
}

const Container = styled.div`
  .hero-description {
    max-width: 600px;
    margin: var(--margin) auto;
  }
`

export default SanityHero
