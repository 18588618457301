import { resolveCheckout } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { CHECKOUT_ATTRIBUTES_UPDATE_MUTATION } from "../graphql";
import { Checkout, CheckoutAttributesUpdateV2Input } from "../types/storefront.types";
import { ShopifyCheckout } from "../types/custom.types";

type CheckoutResponse = { 
  checkoutAttributesUpdateV2: {
    checkout:Checkout 
  }
}

export const attributesUpdate = async (checkoutId: string, input: CheckoutAttributesUpdateV2Input): Promise<ShopifyCheckout> => {
  const { data, errors } = await storefrontAPIClient.request<CheckoutResponse>(
    CHECKOUT_ATTRIBUTES_UPDATE_MUTATION, 
    { 
      variables: {
        checkoutId, 
        input
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.checkoutAttributesUpdateV2?.checkout) throw new Error('Could not update attributes in checkout!');

  return resolveCheckout(data.checkoutAttributesUpdateV2.checkout);
}