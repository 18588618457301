import { CHECKOUT_STATES, useCheckout } from 'global';
import { useEffect, useLayoutEffect, useState } from 'react'
import { isBrowser } from 'utilities';
import { PickyStoryClient, PickyStoryConfig, PickyStoryDiscountDetails, PickyStoryDiscountEvent, PickyStorySetStorefrontOptions } from './types';
declare var window: any

const setInitialConfig = () => {
  window.Shopify = window.Shopify || {};
  window.Shopify.shop = process.env.GATSBY_SHOPIFY_STOREURL
  window.pickystory = window.pickystory || {};
  window.pickystory = {
    api: {
      storefront: {
        useCheckoutAsCart: true,
        enable: true,
        token: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN
      }
    }
  } as PickyStoryConfig

  if (process.env.GATSBY_ENVIRONMENT !== 'prod') {
    window.pickystory.discountDebugToken = '8dd5bfac-0765-48bb-8a52-1289046d3cf1';
  }
}

const appendScript = () => {
  const script = document.createElement('script')
  script.id = 'pickystory-script'
  script.setAttribute(
    'src',
    `https://cdn.pickystory.com/widget/dist/latest/pickystory-widget.min.js`
  )
  document['body'].appendChild(script)
}

export enum PICKYSTORY_CLIENT_STATES {
  NOT_INITIALIZED = 'NOT INITIALIZED',
  READY = 'READY',
}

type PickyStoryReturnTypes = {
  pickyStoryClient?: PickyStoryClient,
  setStorefrontOptions: PickyStorySetStorefrontOptions
  regenerateBundleDiscount: () => Promise<void>
  pickyStoryStatus: PICKYSTORY_CLIENT_STATES,
  pickyStoryDiscountDetails?: PickyStoryDiscountDetails
}
export const usePickyStory = (): PickyStoryReturnTypes  => {
  const [clientStatus, setClientStatus] = useState<PICKYSTORY_CLIENT_STATES>(
    (isBrowser && window.pickystory?.client)
      ? PICKYSTORY_CLIENT_STATES.READY
      : PICKYSTORY_CLIENT_STATES.NOT_INITIALIZED
  );
  const [pickyStoryDiscountDetails, setPickyStoryDiscountDetails] = useState<PickyStoryDiscountDetails|undefined>();
  const { checkoutState, checkout, getCheckout } = useCheckout();

  const regenerateBundleDiscount = async () => {
    await window.pickystory?.client.refreshCart();
  }

  useLayoutEffect(() => {
    const pickyStoryScript = document.getElementById('pickystory-script')
    if (!pickyStoryScript) {
      setInitialConfig();
      appendScript();

      window.addEventListener('pickystory', (
        event: CustomEvent<{ name: string, payload?: PickyStoryDiscountEvent }>
      ) => {
        if (event.detail.name === 'API_READY') {
          setClientStatus(PICKYSTORY_CLIENT_STATES.READY);
        }

        if (event.detail.name === 'DEAL_DISCOUNTS') {
          getCheckout();
          if (event.detail.payload?.discount?.applications.length) {
            setPickyStoryDiscountDetails(event.detail.payload.discount);
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    if (
      checkoutState === CHECKOUT_STATES.READY
      && clientStatus === PICKYSTORY_CLIENT_STATES.READY
    ) {
      window.pickystory?.client
        ?.setStorefrontOptions({ checkoutId: checkout.id })
        .catch(console.error);

      window.pickystory?.client
        ?.refreshCart()
        .catch(console.error);
    }
  }, [checkoutState, clientStatus]);

  useEffect(() => {
    const conditions = [
      CHECKOUT_STATES.DISCOUNT_REMOVED,
      CHECKOUT_STATES.QUANTITY_UPDATED,
      CHECKOUT_STATES.PRODUCT_REMOVED,
    ];
    if (conditions.includes(checkoutState)) {
      regenerateBundleDiscount();
    }
  }, [checkoutState]);

  return {
    pickyStoryClient: isBrowser && window?.pickystory?.client,
    setStorefrontOptions: isBrowser && window?.pickystory?.client?.setStorefrontOptions,
    regenerateBundleDiscount,
    pickyStoryStatus: clientStatus,
    pickyStoryDiscountDetails
  }
}
