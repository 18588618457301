import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ShopifyCopyStorefrontProduct, fetchIdFromGlobalId, getDefaultShopifyProductImage } from "utilities";
import { Icon } from "components/elements";
import { GatsbyImage } from "gatsby-plugin-image";
import { QuantityInput } from "components/inputs";
import { PickyStorySelectionMap } from "lib/pickystory";
import { ShopifyProductVariant } from "lib/shopify/storefront-api-client/types/custom.types";

const MAX_LINE_ITEM_BUNDLE_QUANTITY = 5;

type BundleBuilderSelectionCardProps = {
  product: ShopifyCopyStorefrontProduct & { selectedVariant: ShopifyProductVariant } | null, 
  selectionMap: PickyStorySelectionMap,
  onRemoveSelection: (strippedProductId: string) => void
  onUpdateQuantitySelection: (strippedProductId: string, quantity: number) => void
}
export const BundleBuilderSelectionCard = ({
  product,
  selectionMap,
  onRemoveSelection,
  onUpdateQuantitySelection
}: BundleBuilderSelectionCardProps) => {
  if (!product) return null;

  const strippedProductId = fetchIdFromGlobalId(product.id);
  const selectedVariant = product.selectedVariant;
  const strippedVariantId = fetchIdFromGlobalId(selectedVariant.id);
  const selectionKey = `${strippedProductId}-${strippedVariantId}`;
  const [quantity, setQuantity] = useState(selectionMap[selectionKey].quantity);

  useEffect(() => setQuantity(selectionMap[selectionKey].quantity), [selectionMap[selectionKey].quantity])

  const onIncrement = () => {
    if (quantity === MAX_LINE_ITEM_BUNDLE_QUANTITY) return;
    const updatedQuantity = quantity + 1;
    setQuantity(updatedQuantity);
    onUpdateQuantitySelection(selectionKey, updatedQuantity);
  }

  const onDecrement = () => {
    if (quantity === 1) return;
    const updatedQuantity = quantity - 1;
    setQuantity(updatedQuantity);
    onUpdateQuantitySelection(selectionKey, updatedQuantity);
  }

  const productImage = getDefaultShopifyProductImage(product);

  return (
    <SelectionCardContainer key={product.id} data-testid="bundle-selection-card">
      <div className="product-image-wrapper">
        <span 
          data-testid="remove-selection"
          onClick={() => onRemoveSelection(selectionKey)}
        >
          <Icon 
            name="remove" 
            color="white" 
            className="icon-remove-selection"   
          />
        </span>
        
        <GatsbyImage
          image={productImage.image.gatsbyImageData}
          alt={`${product.title}`}
          objectFit="contain"
          loading="eager"
        />
      </div>
      <div className="selection-card-details">
        <p><strong>{product.title}</strong></p>
        <p className="selected-variant">
          {selectedVariant.title}
        </p>
        <div style={{ position: 'relative' }}>
          <QuantityInput 
            increment={onIncrement}
            decrement={onDecrement}
            selectedAmount={quantity}
          />
        </div>
      </div>
    </SelectionCardContainer>
  )
}

const SelectionCardContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1rem;
  .product-image-wrapper {
    width: 85px;
    position: relative;
    .gatsby-image-wrapper {
      width: 85px;
    } 
  }

  .selection-card-details {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    p {
      margin: 0 0 0 10px;
      text-align: left;
      font-size: 1.5rem;
      width: 100%;
      line-height: 2rem;
    }
    p.selected-variant {
      line-height: 21px;
      font-size: 1.3rem;
      font-size: 1.3rem;
    }
  }

  img {
    overflow: hidden;
    border-radius: 10px;
  }
  svg.icon-remove-selection {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    &:hover {
      cursor: pointer;
    }
    fill: var(--darkNavy);
  }

  .quantity-input {
    margin-top: 2px;
    margin-left: 10px;
    .quantity {
      font-size: 1.3rem;
      line-height: 18px;
      padding: 4px 9px;
    }
  }
`