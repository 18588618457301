import React from 'react'
import styled, { css } from 'styled-components'

type Props = {
  height?: string
  style?: object
  light?: boolean
  type: 'standard' | 'connect'
}

const LogoWrapper = styled.span`
  ${({ light } : { light: boolean }) => (
    light ? css`
      .cls-2 {
        fill: white;
      }
    ` : null
  )}
`

type LogoProps = {
  height: string,
  style: {}
}
const StandardLogo = ({ height, style }: LogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="rachio-logo"
      data-name="Layer 1"
      data-testid="rachio-logo"
      viewBox="0 0 300 76.63"
      style={{
        height,
        ...style,
      }}
    >
      <defs />
      <title>Rachio Logo</title>
      <circle cx="37.9" cy="38.73" r="37.9" fill="#fff" />
      <path
        d="M206.76 19.59l1.69-.57a33.29 33.29 0 0112.2-1.66 18.29 18.29 0 018.26 2.24 15.87 15.87 0 017.44 9.68 26.56 26.56 0 011 7.67v27.5c0 .13-.1.36-.35.35h-7.62c-.7 0-.7 0-.7-.73V36.65a24.4 24.4 0 00-.39-4.15 7.7 7.7 0 00-6.02-6.5 14.36 14.36 0 00-3.54-.49 31.06 31.06 0 00-11.33 2c-.74.27-.63.18-.63.94v35.41c0 1.07.13.95-.92.95h-7c-.75 0-.75 0-.75-.77V32.22v-31c0-1-.18-1 .92-1h7.4c.21 0 .31.1.31.31v18.11z"
        className="cls-2"
      />
      <path
        d="M206.76 19.59l1.69-.57a33.29 33.29 0 0112.2-1.66 18.29 18.29 0 018.26 2.24 15.87 15.87 0 017.44 9.68 26.56 26.56 0 011 7.67v27.5c0 .13-.1.36-.35.35h-7.62c-.7 0-.7 0-.7-.73V36.65a24.4 24.4 0 00-.39-4.15 7.7 7.7 0 00-6.02-6.5 14.36 14.36 0 00-3.54-.49 31.06 31.06 0 00-11.33 2c-.74.27-.63.18-.63.94v35.41c0 1.07.13.95-.92.95h-7c-.75 0-.75 0-.75-.77V32.22v-31c0-1-.18-1 .92-1h7.4c.21 0 .31.1.31.31v18.11z"
        className="cls-2"
      />
      <path
        d="M122.26 26.24l.38-.09A35.26 35.26 0 01134.77 25a16.3 16.3 0 014.7 1 7.82 7.82 0 014.4 4.27 15.75 15.75 0 011.13 5.12c0 .43 0 .46-.46.4a56.28 56.28 0 00-6.5-.47 41.22 41.22 0 00-10.69 1 20.55 20.55 0 00-8 3.66 11.68 11.68 0 00-4.24 6.02 14.88 14.88 0 002.29 13 11.55 11.55 0 004.31 3.78 23.38 23.38 0 005.76 2.1 33 33 0 008.88.63 26.27 26.27 0 008.14-1.66 13.05 13.05 0 004.51-2.61 14.58 14.58 0 004.71-10.62c.07-4.74 0-9.48 0-14.22a23.92 23.92 0 00-.75-6 16.07 16.07 0 00-10.62-11.68 24.25 24.25 0 00-7.39-1.35 40.82 40.82 0 00-10.27.86q-1.17.25-2.31.57s-.76.18-.76.55V26s-.08.46.65.24zM145 47.09v3.3a7.77 7.77 0 01-.54 2.86 6.18 6.18 0 01-3.11 3.26 11.77 11.77 0 01-4 1.12 24.34 24.34 0 01-7.72-.26 11.39 11.39 0 01-3.15-1.14 7 7 0 01-3.1-8.23 6.4 6.4 0 012.44-3.12 9.65 9.65 0 013-1.44 28.9 28.9 0 0110.47-1.25c1.75.13 3.51.21 5.25.46.37.05.48.18.48.55-.02 1.3-.02 2.59-.02 3.89zM299.87 38.36a29.65 29.65 0 00-1.49-8 17.07 17.07 0 00-5.17-8 21.12 21.12 0 00-7.74-4 29.53 29.53 0 00-11-.77 23.06 23.06 0 00-8.75 2.71A16.65 16.65 0 00258 29a30.32 30.32 0 00-2 9.18 44.32 44.32 0 00.13 8 27.75 27.75 0 001.87 7.56 16.29 16.29 0 004.81 6.89 21.28 21.28 0 006.65 3.63 28.72 28.72 0 0012 1.08 22.33 22.33 0 0011-4.15 14.13 14.13 0 003.08-3 20.34 20.34 0 002.93-5.71 35.15 35.15 0 001.59-11c-.06-1.03-.12-2.07-.19-3.12zm-9.52 11.42l-.17.56a9.86 9.86 0 01-8.18 7.13 21 21 0 01-8.59-.11 9.69 9.69 0 01-7.41-6.29 21.2 21.2 0 01-1.15-5.13 43.42 43.42 0 01-.14-7.39 24.62 24.62 0 011.12-6.25 9.64 9.64 0 017.33-6.67 20 20 0 019.94.08 9.52 9.52 0 016.83 6.07 21.28 21.28 0 011.07 4.91 45 45 0 01.27 4.82 31.73 31.73 0 01-.92 8.27z"
        className="cls-2"
      />
      <path
        d="M193.35 28a.92.92 0 01-.64-.17 28.86 28.86 0 00-8.6-2.53 21.55 21.55 0 00-9.78.67 13.34 13.34 0 00-2.13 1 9.3 9.3 0 00-4.41 5.36 22.46 22.46 0 00-1 4.79 42.71 42.71 0 00-.14 7 23.3 23.3 0 001.26 6.74 10 10 0 007.73 6.57 23.9 23.9 0 008.24.23 30.57 30.57 0 009-2.57c.11 0 .21-.13.35-.09s.07.37.07.55v6.82c0 .66 0 .67-.6.89a38.11 38.11 0 01-9.1 2.13 33.89 33.89 0 01-9-.16q-12.14-2-15.78-13.73a31.44 31.44 0 01-1.23-7.28 41.37 41.37 0 01.33-8.62 24.29 24.29 0 012.68-8.39 18 18 0 0112.12-9.05 31.34 31.34 0 019.61-.64 36.63 36.63 0 0110.26 2.24c.74.28.69.11.69 1v6.67z"
        className="cls-2"
        data-name="SPLINE"
      />
      <path
        d="M193.35 28a.92.92 0 01-.64-.17 28.86 28.86 0 00-8.6-2.53 21.55 21.55 0 00-9.78.67 13.34 13.34 0 00-2.13 1 9.3 9.3 0 00-4.41 5.36 22.46 22.46 0 00-1 4.79 42.71 42.71 0 00-.14 7 23.3 23.3 0 001.26 6.74 10 10 0 007.73 6.57 23.9 23.9 0 008.24.23 30.57 30.57 0 009-2.57c.11 0 .21-.13.35-.09s.07.37.07.55v6.82c0 .66 0 .67-.6.89a38.11 38.11 0 01-9.1 2.13 33.89 33.89 0 01-9-.16q-12.14-2-15.78-13.73a31.44 31.44 0 01-1.23-7.28 41.37 41.37 0 01.33-8.62 24.29 24.29 0 012.68-8.39 18 18 0 0112.12-9.05 31.34 31.34 0 019.61-.64 36.63 36.63 0 0110.26 2.24c.74.28.69.11.69 1v6.67z"
        className="cls-2"
        data-name="HATCH"
      />
      <path
        d="M93.75 43.34V22.75c0-.87-.08-.72.71-1A64.85 64.85 0 01108.2 18a49.56 49.56 0 017.73-.63c.65 0 .65 0 .66.62v6.67c0 .58 0 .59-.61.6A58 58 0 00103.09 27c-.71.18-.71.18-.71.9v35.91c0 1.13.14 1-1 1H94.1c-.24 0-.36-.11-.35-.35s0-.39 0-.59V43.34z"
        className="cls-2"
        data-name="SPLINE"
      />
      <path
        d="M93.75 43.34V22.75c0-.87-.08-.72.71-1A64.85 64.85 0 01108.2 18a49.56 49.56 0 017.73-.63c.65 0 .65 0 .66.62v6.67c0 .58 0 .59-.61.6A58 58 0 00103.09 27c-.71.18-.71.18-.71.9v35.91c0 1.13.14 1-1 1H94.1c-.24 0-.36-.11-.35-.35s0-.39 0-.59V43.34z"
        className="cls-2"
        data-name="HATCH"
      />
      <path
        d="M242.91 41.38v-22.5c0-.92-.18-.89.91-.89h7.33c.29 0 .39.13.37.41s0 .34 0 .51v45c0 1 .12.88-.92.88h-6.74c-1.12 0-1 .12-1-1q.05-11.2.05-22.41z"
        className="cls-2"
        data-name="SPLINE"
      />
      <path
        d="M242.91 41.38v-22.5c0-.92-.18-.89.91-.89h7.33c.29 0 .39.13.37.41s0 .34 0 .51v45c0 1 .12.88-.92.88h-6.74c-1.12 0-1 .12-1-1q.05-11.2.05-22.41z"
        className="cls-2"
        data-name="HATCH"
      />
      <circle cx="247.13" cy="5.44" r="5.44" className="cls-2" />
      <path
        fill="#00a8e1"
        d="M37.9.84a37.9 37.9 0 00-5.82 75.35V34.32c0-1.38-1.15-1.71-2.75-2h.06a40.49 40.49 0 00-8.09-1h-.68V25s4.72-.44 13.09 1.82V18c4.72-2.28 13.89-2.64 13.89-2.64v5h-.54a30.49 30.49 0 00-5.56.64 3 3 0 00-2.6 2.69v15.23c10.42-2.82 16.28-2.27 16.28-2.27v7.86h-.84a51.87 51.87 0 00-10.89 1.4c-1.58.33-2.72 1.35-2.72 2.72v27.89A37.89 37.89 0 0037.9.84z"
      />
      <path
        d="M298.24 17.4a1.79 1.79 0 11-1.75 1.79 1.77 1.77 0 011.75-1.79zm0 3.19a1.4 1.4 0 10-1.31-1.4 1.35 1.35 0 001.31 1.4zm-.61-2.3h.74a.54.54 0 01.59.57.47.47 0 01-.32.46.51.51 0 01.06.1l.34.65h-.46l-.3-.66H298v.66h-.42zm.65.84a.24.24 0 00.26-.27.24.24 0 00-.25-.26H298v.53z"
        className="cls-2"
      />
    </svg>
  )
}

const ConnectLogo = ({ height, style }: LogoProps) => {
  return (
    <svg   
      viewBox="0 0 160 67" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      style={{
        height,
        ...style,
      }}
    >
      <title>Rachio Logo</title>
      <path className="cls-2" d="M19.8553 40.1441C30.821 40.1441 39.7105 31.2546 39.7105 20.2889C39.7105 9.3231 30.821 0.433594 19.8553 0.433594C8.8895 0.433594 0 9.3231 0 20.2889C0 31.2546 8.8895 40.1441 19.8553 40.1441Z" fill="white"/>
      <path className="cls-2" d="M111.159 10.2641C111.492 10.152 111.765 10.0548 112.041 9.96509C114.116 9.28107 116.25 8.98578 118.429 9.09792C119.954 9.17641 121.419 9.50908 122.757 10.2716C124.765 11.4154 125.998 13.1609 126.652 15.3438C127.045 16.652 127.198 17.9976 127.202 19.3582C127.202 24.094 127.202 28.8336 127.202 33.5694C127.202 33.6329 127.202 33.6965 127.202 33.76C127.205 33.8871 127.146 33.9469 127.019 33.9432C126.955 33.9432 126.891 33.9432 126.828 33.9432C125.561 33.9432 124.294 33.9432 123.027 33.9432C122.66 33.9432 122.66 33.9432 122.66 33.5582C122.66 28.77 122.66 23.9819 122.66 19.1937C122.66 18.4611 122.578 17.7397 122.455 17.0183C122.137 15.1868 121.031 14.0991 119.281 13.6169C118.676 13.4487 118.055 13.374 117.424 13.359C115.375 13.3142 113.405 13.7066 111.488 14.4019C111.103 14.5439 111.159 14.4953 111.159 14.8915C111.159 21.0739 111.159 27.2599 111.159 33.4423C111.159 34.003 111.226 33.9394 110.677 33.9394C109.462 33.9394 108.243 33.9394 107.029 33.9394C106.636 33.9394 106.636 33.9394 106.636 33.5395C106.636 27.9851 106.636 22.427 106.636 16.8726C106.636 11.4565 106.636 6.04039 106.636 0.628033C106.636 0.101002 106.543 0.127167 107.118 0.127167C108.322 0.127167 109.526 0.127167 110.729 0.127167C110.819 0.127167 110.909 0.127167 110.998 0.127167C111.107 0.127167 111.163 0.175758 111.159 0.287893C111.159 0.388813 111.159 0.493472 111.159 0.594393C111.159 3.65566 111.159 6.71319 111.159 9.77446C111.159 9.91276 111.159 10.0548 111.159 10.2604V10.2641Z" fill="#0C2738"/>
      <path className="cls-2" d="M111.159 10.2641C111.492 10.152 111.765 10.0548 112.041 9.96509C114.116 9.28107 116.25 8.98578 118.429 9.09792C119.954 9.17641 121.419 9.50908 122.757 10.2716C124.765 11.4154 125.998 13.1609 126.652 15.3438C127.045 16.652 127.198 17.9976 127.202 19.3582C127.202 24.094 127.202 28.8336 127.202 33.5694C127.202 33.6329 127.202 33.6965 127.202 33.76C127.205 33.8871 127.146 33.9469 127.019 33.9432C126.955 33.9432 126.891 33.9432 126.828 33.9432C125.561 33.9432 124.294 33.9432 123.027 33.9432C122.66 33.9432 122.66 33.9432 122.66 33.5582C122.66 28.77 122.66 23.9819 122.66 19.1937C122.66 18.4611 122.578 17.7397 122.455 17.0183C122.137 15.1868 121.031 14.0991 119.281 13.6169C118.676 13.4487 118.055 13.374 117.424 13.359C115.375 13.3142 113.405 13.7066 111.488 14.4019C111.103 14.5439 111.159 14.4953 111.159 14.8915C111.159 21.0739 111.159 27.2599 111.159 33.4423C111.159 34.003 111.226 33.9394 110.677 33.9394C109.462 33.9394 108.243 33.9394 107.029 33.9394C106.636 33.9394 106.636 33.9394 106.636 33.5395C106.636 27.9851 106.636 22.427 106.636 16.8726C106.636 11.4565 106.636 6.04039 106.636 0.628033C106.636 0.101002 106.543 0.127167 107.118 0.127167C108.322 0.127167 109.526 0.127167 110.729 0.127167C110.819 0.127167 110.909 0.127167 110.998 0.127167C111.107 0.127167 111.163 0.175758 111.159 0.287893C111.159 0.388813 111.159 0.493472 111.159 0.594393C111.159 3.65566 111.159 6.71319 111.159 9.77446C111.159 9.91276 111.159 10.0548 111.159 10.2604V10.2641Z" fill="#0C2738"/>
      <path className="cls-2" d="M66.8958 13.7441L67.0939 13.6955C69.1684 13.1498 71.2802 12.8732 73.4519 13.0675C74.2966 13.1423 75.1227 13.2955 75.9114 13.6132C76.9841 14.0468 77.7504 14.7907 78.2139 15.8522C78.5876 16.7082 78.7372 17.6127 78.8119 18.5359C78.8306 18.7602 78.8119 18.7752 78.5727 18.7415C77.4439 18.5845 76.3076 18.5173 75.1675 18.4911C73.2837 18.45 71.4073 18.5472 69.5646 18.992C68.047 19.3583 66.6229 19.9376 65.3782 20.9095C64.3129 21.743 63.5168 22.7784 63.1467 24.0941C62.4552 26.5461 62.9262 28.8037 64.3428 30.8819C64.9222 31.7304 65.6847 32.3883 66.6005 32.863C67.5574 33.3564 68.5628 33.7302 69.6169 33.9619C71.1531 34.2983 72.7043 34.3805 74.2705 34.2946C75.7357 34.2161 77.1635 33.9582 78.5353 33.4237C79.3875 33.0947 80.2173 32.7172 80.8827 32.078C82.46 30.5642 83.316 28.7065 83.3496 26.5162C83.387 24.0343 83.3608 21.5486 83.3571 19.0667C83.3571 18.0014 83.2337 16.9474 82.9646 15.9157C82.1834 12.9068 80.3257 10.8622 77.4028 9.79694C76.1543 9.34093 74.8536 9.13909 73.5304 9.08676C71.7213 9.01574 69.9271 9.15404 68.1517 9.5353C67.7442 9.625 67.3406 9.72219 66.9406 9.83432C66.9406 9.83432 66.5444 9.92777 66.5444 10.1259V13.5983C66.5444 13.5983 66.507 13.8487 66.8883 13.7366L66.8958 13.7441ZM78.8231 24.666C78.8231 25.2416 78.8231 25.8172 78.8231 26.3928C78.8194 26.9086 78.7334 27.4095 78.5428 27.8917C78.2288 28.6804 77.6607 29.2224 76.9131 29.5999C76.2552 29.9325 75.5488 30.1045 74.8199 30.1904C73.4668 30.3474 72.1137 30.325 70.7756 30.0521C70.1963 29.9325 69.6468 29.7382 69.1235 29.4578C67.3181 28.4823 67.0752 26.3779 67.4826 25.1519C67.7106 24.4566 68.1816 23.9408 68.7609 23.5147C69.2394 23.1596 69.7776 22.9316 70.3458 22.7634C72.1362 22.2289 73.9565 21.971 75.8329 22.1093C76.7524 22.1766 77.6681 22.2177 78.5802 22.3485C78.7745 22.3747 78.8343 22.442 78.8306 22.6363C78.8194 23.3129 78.8269 23.9932 78.8269 24.6697L78.8231 24.666Z" fill="#0C2738"/>
      <path className="cls-2" d="M159.934 20.0944C159.848 18.6703 159.62 17.2724 159.152 15.9231C158.592 14.3046 157.799 12.8207 156.443 11.7218C155.239 10.7462 153.886 10.0323 152.387 9.61736C150.499 9.09781 148.582 9.01557 146.642 9.21368C145.02 9.38188 143.499 9.84911 142.059 10.6303C140.157 11.6619 138.83 13.1832 138.023 15.183C137.398 16.7304 137.092 18.3414 136.969 19.9935C136.864 21.3877 136.89 22.7782 137.036 24.1687C137.182 25.5367 137.462 26.8711 137.982 28.1457C138.546 29.5399 139.308 30.8033 140.501 31.7564C141.551 32.59 142.706 33.2403 143.984 33.659C146.036 34.328 148.141 34.4439 150.279 34.2234C152.387 34.0066 154.297 33.2889 156.016 32.048C156.633 31.6032 157.175 31.0836 157.631 30.4744C158.311 29.5661 158.801 28.5531 159.164 27.4841C159.799 25.6227 159.971 23.6902 159.997 21.7391C160.012 21.1896 159.967 20.6402 159.934 20.0907V20.0944ZM154.947 26.0787C154.918 26.1759 154.891 26.2731 154.861 26.374C154.2 28.5083 152.705 29.7081 150.559 30.108C149.064 30.3884 147.547 30.3884 146.059 30.0482C144.209 29.6259 142.852 28.5793 142.172 26.7515C141.85 25.8843 141.671 24.9835 141.57 24.064C141.428 22.7782 141.42 21.4849 141.499 20.1954C141.566 19.0815 141.734 17.9863 142.086 16.921C142.71 15.026 144.037 13.8822 145.928 13.4262C147.651 13.0113 149.415 13.0113 151.135 13.4673C152.832 13.9233 154.08 14.955 154.716 16.6482C155.026 17.4817 155.205 18.3414 155.314 19.2161C155.415 20.0533 155.441 20.8981 155.456 21.7428C155.43 23.2043 155.359 24.6583 154.951 26.0712L154.947 26.0787Z" fill="#0C2738"/>
      <path className="cls-2" d="M104.136 14.6672C103.975 14.6933 103.893 14.6223 103.803 14.5812C102.364 13.9196 100.869 13.4486 99.2952 13.2543C97.5683 13.0449 95.8489 13.03 94.1706 13.6056C93.7819 13.7402 93.4119 13.9121 93.053 14.1102C91.913 14.7419 91.1617 15.6876 90.7468 16.9173C90.4702 17.7322 90.3095 18.5732 90.216 19.4254C90.0852 20.6364 90.0777 21.855 90.145 23.0698C90.2086 24.2733 90.3917 25.4582 90.8066 26.602C91.5018 28.5232 92.926 29.5997 94.8547 30.0445C96.2788 30.3734 97.7253 30.3361 99.1718 30.1641C100.824 29.9698 102.398 29.5025 103.911 28.8185C103.967 28.7924 104.02 28.7512 104.095 28.7737C104.166 28.8596 104.132 28.9643 104.132 29.0615C104.132 30.2538 104.132 31.4425 104.132 32.6348C104.132 32.9824 104.132 32.9862 103.814 33.0983C102.27 33.659 100.686 34.044 99.0485 34.2122C97.4749 34.3766 95.9087 34.3841 94.3426 34.1299C90.1002 33.4385 87.3417 31.0388 86.0745 26.9347C85.6933 25.6937 85.5139 24.4191 85.4316 23.1221C85.3345 21.6083 85.3868 20.1019 85.6036 18.6031C85.8279 17.0594 86.2502 15.5754 87.009 14.2074C88.4107 11.6732 90.5637 10.1369 93.3595 9.46413C95.0191 9.06792 96.7011 9.01185 98.3944 9.13146C100.245 9.26228 102.035 9.65849 103.769 10.3051C104.158 10.4509 104.128 10.3612 104.128 10.8135C104.128 11.9797 104.128 13.1421 104.128 14.3083C104.128 14.4242 104.128 14.5363 104.128 14.6634L104.136 14.6672Z" fill="#0C2738"/>
      <path className="cls-2" d="M104.136 14.6672C103.975 14.6933 103.893 14.6223 103.803 14.5812C102.364 13.9196 100.869 13.4486 99.2952 13.2543C97.5683 13.0449 95.8489 13.03 94.1706 13.6056C93.7819 13.7402 93.4119 13.9121 93.053 14.1102C91.913 14.7419 91.1617 15.6876 90.7468 16.9173C90.4702 17.7322 90.3095 18.5732 90.216 19.4254C90.0852 20.6364 90.0777 21.855 90.145 23.0698C90.2086 24.2733 90.3917 25.4582 90.8066 26.602C91.5018 28.5232 92.926 29.5997 94.8547 30.0445C96.2788 30.3734 97.7253 30.3361 99.1718 30.1641C100.824 29.9698 102.398 29.5025 103.911 28.8185C103.967 28.7924 104.02 28.7512 104.095 28.7737C104.166 28.8596 104.132 28.9643 104.132 29.0615C104.132 30.2538 104.132 31.4425 104.132 32.6348C104.132 32.9824 104.132 32.9862 103.814 33.0983C102.27 33.659 100.686 34.044 99.0485 34.2122C97.4749 34.3766 95.9087 34.3841 94.3426 34.1299C90.1002 33.4385 87.3417 31.0388 86.0745 26.9347C85.6933 25.6937 85.5139 24.4191 85.4316 23.1221C85.3345 21.6083 85.3868 20.1019 85.6036 18.6031C85.8279 17.0594 86.2502 15.5754 87.009 14.2074C88.4107 11.6732 90.5637 10.1369 93.3595 9.46413C95.0191 9.06792 96.7011 9.01185 98.3944 9.13146C100.245 9.26228 102.035 9.65849 103.769 10.3051C104.158 10.4509 104.128 10.3612 104.128 10.8135C104.128 11.9797 104.128 13.1421 104.128 14.3083C104.128 14.4242 104.128 14.5363 104.128 14.6634L104.136 14.6672Z" fill="#0C2738"/>
      <path className="cls-2" d="M51.9594 22.7037C51.9594 19.1079 51.9594 15.5121 51.9594 11.9163C51.9594 11.4566 51.9146 11.5388 52.3332 11.3781C54.6618 10.4661 57.0578 9.79701 59.5285 9.41575C60.8703 9.21017 62.2197 9.07935 63.5803 9.08309C63.9204 9.08309 63.9241 9.08309 63.9241 9.40828C63.9241 10.5745 63.9241 11.7369 63.9241 12.9031C63.9241 13.2096 63.9204 13.2134 63.6064 13.2171C61.3226 13.262 59.0762 13.5946 56.8597 14.1441C56.613 14.2064 56.4896 14.3646 56.4896 14.6188C56.4896 20.8908 56.4896 27.1591 56.4896 33.4312C56.4896 34.0255 56.5607 33.947 55.9888 33.9508C54.8001 33.9508 53.6078 33.9508 52.4192 33.9508C52.3295 33.9508 52.2397 33.9508 52.15 33.9508C52.023 33.9508 51.9631 33.8947 51.9669 33.7639C51.9669 33.6629 51.9669 33.5583 51.9669 33.4574C51.9669 29.8728 51.9669 26.292 51.9669 22.7074L51.9594 22.7037Z" fill="#0C2738"/>
      <path className="cls-2" d="M51.9594 22.7037C51.9594 19.1079 51.9594 15.5121 51.9594 11.9163C51.9594 11.4566 51.9146 11.5388 52.3332 11.3781C54.6618 10.4661 57.0578 9.79701 59.5285 9.41575C60.8703 9.21017 62.2197 9.07935 63.5803 9.08309C63.9204 9.08309 63.9241 9.08309 63.9241 9.40828C63.9241 10.5745 63.9241 11.7369 63.9241 12.9031C63.9241 13.2096 63.9204 13.2134 63.6064 13.2171C61.3226 13.262 59.0762 13.5946 56.8597 14.1441C56.613 14.2064 56.4896 14.3646 56.4896 14.6188C56.4896 20.8908 56.4896 27.1591 56.4896 33.4312C56.4896 34.0255 56.5607 33.947 55.9888 33.9508C54.8001 33.9508 53.6078 33.9508 52.4192 33.9508C52.3295 33.9508 52.2397 33.9508 52.15 33.9508C52.023 33.9508 51.9631 33.8947 51.9669 33.7639C51.9669 33.6629 51.9669 33.5583 51.9669 33.4574C51.9669 29.8728 51.9669 26.292 51.9669 22.7074L51.9594 22.7037Z" fill="#0C2738"/>
      <path className="cls-2" d="M130.098 21.6754C130.098 17.747 130.098 13.8148 130.098 9.88634C130.098 9.40416 130.005 9.42285 130.577 9.42285C131.766 9.42285 132.958 9.42285 134.146 9.42285C134.236 9.42285 134.326 9.42659 134.416 9.42285C134.565 9.41538 134.621 9.49387 134.61 9.63591C134.603 9.72562 134.61 9.81532 134.61 9.90503C134.61 17.7657 134.61 25.6225 134.61 33.4832C134.61 33.9952 134.674 33.9466 134.132 33.9466C132.954 33.9466 131.777 33.9466 130.599 33.9466C130.016 33.9466 130.102 34.0102 130.098 33.4271C130.098 29.5099 130.098 25.5926 130.098 21.6754Z" fill="#0C2738"/>
      <path className="cls-2" d="M130.098 21.6754C130.098 17.747 130.098 13.8148 130.098 9.88634C130.098 9.40416 130.005 9.42285 130.577 9.42285C131.766 9.42285 132.958 9.42285 134.146 9.42285C134.236 9.42285 134.326 9.42659 134.416 9.42285C134.565 9.41538 134.621 9.49387 134.61 9.63591C134.603 9.72562 134.61 9.81532 134.61 9.90503C134.61 17.7657 134.61 25.6225 134.61 33.4832C134.61 33.9952 134.674 33.9466 134.132 33.9466C132.954 33.9466 131.777 33.9466 130.599 33.9466C130.016 33.9466 130.102 34.0102 130.098 33.4271C130.098 29.5099 130.098 25.5926 130.098 21.6754Z" fill="#0C2738"/>
      <path className="cls-2" d="M132.307 5.69643C133.88 5.69643 135.156 4.42124 135.156 2.84821C135.156 1.27519 133.88 0 132.307 0C130.734 0 129.459 1.27519 129.459 2.84821C129.459 4.42124 130.734 5.69643 132.307 5.69643Z" fill="#0C2738"/>
      <path d="M19.8553 0.437163C8.88852 0.437163 0 9.32568 0 20.2887C0 30.2163 7.28873 38.4432 16.8052 39.9085V18.3338V17.9787C16.8052 17.2536 16.2034 17.0816 15.3661 16.9097H15.3998C14.2635 16.6705 12.6749 16.4014 11.1611 16.4014H10.806V13.0822C10.806 13.0822 13.2767 12.8504 17.6612 14.0391H17.6761V9.42286C20.1468 8.2305 24.9536 8.03987 24.9536 8.03987V10.6676H24.6733C23.6529 10.6676 22.5913 10.8208 21.7429 10.9853C20.9467 11.1722 20.3823 11.6955 20.3823 12.3944V12.372L20.3786 20.3784C25.8358 18.8982 28.9082 19.186 28.9082 19.186V23.3051H28.4672C26.3852 23.3051 24.1799 23.7125 22.7595 24.0377C21.9297 24.2096 21.3354 24.7442 21.3354 25.4656V25.4431L21.3317 40.0767C31.6069 39.3216 39.7143 30.7508 39.7143 20.2849C39.7143 9.32194 30.8257 0.429688 19.859 0.429688L19.8553 0.437163Z" fill="#00A7E1"/>
      <path className="cls-2" d="M13.9271 63.4796C13.3403 64.2758 12.5815 64.9224 11.6471 65.4158C10.7126 65.9092 9.58755 66.1596 8.27557 66.1596C7.14676 66.1596 6.10017 65.969 5.14703 65.584C4.19015 65.199 3.36783 64.6645 2.67633 63.973C1.98484 63.2815 1.44285 62.4592 1.05412 61.5023C0.661651 60.5454 0.467285 59.4951 0.467285 58.3513C0.467285 57.2076 0.665389 56.1348 1.06534 55.1817C1.46154 54.2248 2.01474 53.4099 2.71745 52.7334C3.42016 52.0569 4.25743 51.5298 5.21804 51.1523C6.1824 50.7748 7.22899 50.5879 8.35781 50.5879C8.85867 50.5879 9.37075 50.6365 9.88657 50.7337C10.4024 50.8308 10.8995 50.9766 11.3742 51.1747C11.8489 51.3691 12.2825 51.6121 12.6712 51.8961C13.06 52.1839 13.3964 52.5129 13.6767 52.8904L12.0433 54.1463C11.6807 53.6454 11.1686 53.2268 10.5033 52.9016C9.84172 52.5727 9.12406 52.4082 8.35781 52.4082C7.49063 52.4082 6.71317 52.5652 6.02541 52.8792C5.33392 53.1931 4.74334 53.623 4.25743 54.1687C3.76777 54.7144 3.39025 55.3424 3.12861 56.0638C2.86322 56.7814 2.7324 57.5477 2.7324 58.3551C2.7324 59.1624 2.85948 59.9735 3.12113 60.6987C3.37904 61.4238 3.74908 62.0555 4.23126 62.5937C4.71344 63.132 5.30028 63.5544 5.98803 63.8609C6.67953 64.1674 7.44952 64.3206 8.30174 64.3206C9.15396 64.3206 9.89779 64.1524 10.5818 63.8197C11.2658 63.4833 11.8302 62.9974 12.2788 62.3545L13.9309 63.4833L13.9271 63.4796Z" fill="#0C2738"/>
      <path className="cls-2" d="M26.1534 60.7994C26.1534 61.5656 26.0189 62.2721 25.746 62.915C25.4731 63.5579 25.0956 64.1111 24.6172 64.5783C24.135 65.0455 23.5669 65.4081 22.9127 65.666C22.2586 65.9239 21.5522 66.0547 20.7971 66.0547C20.0421 66.0547 19.3394 65.9277 18.6815 65.666C18.0274 65.4081 17.4593 65.0455 16.9846 64.5783C16.5099 64.1111 16.1361 63.5579 15.8632 62.915C15.5904 62.2721 15.4558 61.5694 15.4558 60.7994C15.4558 60.0294 15.5904 59.3304 15.8632 58.695C16.1361 58.0596 16.5099 57.5138 16.9846 57.0541C17.4593 56.5943 18.0237 56.2355 18.6815 55.9776C19.3357 55.7197 20.0421 55.5889 20.7971 55.5889C21.5522 55.5889 22.2549 55.7197 22.9127 55.9776C23.5669 56.2355 24.1387 56.5943 24.6172 57.0541C25.0994 57.5138 25.4769 58.0633 25.746 58.695C26.0189 59.3304 26.1534 60.0331 26.1534 60.7994ZM24.1014 60.8106C24.1014 60.3359 24.0266 59.8874 23.8808 59.4538C23.7351 59.0239 23.522 58.6427 23.2417 58.3175C22.9613 57.9923 22.6137 57.7306 22.2026 57.5363C21.7914 57.3419 21.3167 57.2447 20.7859 57.2447C20.2552 57.2447 19.7805 57.3419 19.3693 57.5363C18.9581 57.7306 18.6105 57.9923 18.3302 58.3175C18.0499 58.6427 17.8368 59.0239 17.691 59.4538C17.5452 59.8836 17.4705 60.3359 17.4705 60.8106C17.4705 61.2853 17.5452 61.7376 17.691 62.1674C17.8368 62.5973 18.0499 62.9823 18.3302 63.3149C18.6105 63.6476 18.9581 63.913 19.3693 64.1073C19.7805 64.3017 20.2552 64.3989 20.7859 64.3989C21.3167 64.3989 21.7914 64.3017 22.2026 64.1073C22.6137 63.913 22.9613 63.6476 23.2417 63.3149C23.522 62.9823 23.7351 62.5973 23.8808 62.1674C24.0266 61.7376 24.1014 61.2853 24.1014 60.8106Z" fill="#0C2738"/>
      <path className="cls-2" d="M29.8164 55.858C29.8426 56.1084 29.865 56.3925 29.88 56.7065C29.8949 57.0205 29.9024 57.2821 29.9024 57.4914H29.9659C30.093 57.226 30.2612 56.9793 30.478 56.7476C30.6948 56.5158 30.9415 56.314 31.2218 56.1421C31.5022 55.9701 31.8087 55.8318 32.1413 55.7346C32.4778 55.6375 32.8254 55.5889 33.1879 55.5889C33.8308 55.5889 34.3803 55.6973 34.8401 55.9141C35.2998 56.1308 35.6848 56.4187 35.9913 56.7812C36.2978 57.1438 36.5258 57.5699 36.6716 58.0596C36.8174 58.5492 36.8921 59.0725 36.8921 59.6294V65.7819H34.926V60.2761C34.926 59.8724 34.8961 59.4874 34.8326 59.1248C34.769 58.7623 34.6532 58.4408 34.4887 58.1605C34.3205 57.8801 34.0925 57.6596 33.7972 57.4914C33.5057 57.3232 33.1281 57.241 32.6684 57.241C31.8872 57.241 31.248 57.5363 30.7546 58.1306C30.2575 58.7249 30.0108 59.5173 30.0108 60.5078V65.7819H28.0447V57.996C28.0447 57.7306 28.0372 57.3905 28.0223 56.9719C28.0073 56.5532 27.9886 56.1832 27.9587 55.8617H29.8202L29.8164 55.858Z" fill="#0C2738"/>
      <path className="cls-2" d="M40.7422 55.858C40.7684 56.1084 40.7908 56.3925 40.8058 56.7065C40.8207 57.0205 40.8282 57.2821 40.8282 57.4914H40.8917C41.0188 57.226 41.187 56.9793 41.4038 56.7476C41.6206 56.5158 41.8673 56.314 42.1476 56.1421C42.428 55.9701 42.7345 55.8318 43.0671 55.7346C43.4035 55.6375 43.7512 55.5889 44.1137 55.5889C44.7566 55.5889 45.3061 55.6973 45.7658 55.9141C46.2256 56.1308 46.6106 56.4187 46.9171 56.7812C47.2236 57.1438 47.4516 57.5699 47.5974 58.0596C47.7431 58.5492 47.8179 59.0725 47.8179 59.6294V65.7819H45.8518V60.2761C45.8518 59.8724 45.8219 59.4874 45.7584 59.1248C45.6948 58.7623 45.5789 58.4408 45.4145 58.1605C45.2463 57.8801 45.0183 57.6596 44.723 57.4914C44.4314 57.3232 44.0539 57.241 43.5942 57.241C42.813 57.241 42.1738 57.5363 41.6804 58.1306C41.1833 58.7249 40.9366 59.5173 40.9366 60.5078V65.7819H38.9705V57.996C38.9705 57.7306 38.963 57.3905 38.9481 56.9719C38.9331 56.5532 38.9144 56.1832 38.8845 55.8617H40.746L40.7422 55.858Z" fill="#0C2738"/>
      <path className="cls-2" d="M59.1621 60.7362V61.0726C59.1621 61.1847 59.1546 61.2968 59.1397 61.4052H51.2492C51.2641 61.8201 51.3576 62.2126 51.5332 62.5789C51.7089 62.9452 51.9407 63.2666 52.2359 63.5358C52.5275 63.8049 52.8676 64.0179 53.2526 64.1712C53.6376 64.3244 54.045 64.3992 54.4786 64.3992C55.1477 64.3992 55.7271 64.2534 56.2167 63.9581C56.7064 63.6666 57.0876 63.3078 57.368 62.8891L58.7509 63.9993C58.2202 64.702 57.596 65.2215 56.8783 65.5505C56.1606 65.8831 55.3608 66.0476 54.4824 66.0476C53.7273 66.0476 53.0321 65.9205 52.3892 65.6701C51.7463 65.4196 51.1968 65.0683 50.7371 64.6123C50.2773 64.16 49.9148 63.6105 49.6494 62.9714C49.384 62.3285 49.2532 61.6183 49.2532 60.8371C49.2532 60.0559 49.384 59.3606 49.6419 58.714C49.8998 58.0636 50.2624 57.5104 50.7221 57.0507C51.1819 56.5909 51.7276 56.2321 52.3593 55.9742C52.991 55.7163 53.6675 55.5854 54.3927 55.5854C55.1178 55.5854 55.7794 55.7051 56.3737 55.9405C56.968 56.176 57.4726 56.5199 57.8838 56.9647C58.2949 57.4132 58.6127 57.9515 58.8369 58.5869C59.0612 59.2223 59.1733 59.9363 59.1733 60.7324L59.1621 60.7362ZM57.1512 59.94C57.1362 59.5513 57.0727 59.185 56.9531 58.8411C56.8335 58.5009 56.6615 58.2019 56.4298 57.9515C56.198 57.701 55.914 57.5029 55.5701 57.3534C55.2262 57.2077 54.8262 57.1329 54.3665 57.1329C53.9479 57.1329 53.5517 57.2077 53.1853 57.3534C52.8153 57.4992 52.4939 57.6973 52.221 57.9515C51.9481 58.2057 51.7239 58.5009 51.5519 58.8411C51.3762 59.185 51.2753 59.5475 51.2492 59.94H57.1512Z" fill="#0C2738"/>
      <path className="cls-2" d="M68.4131 58.3698C68.2038 58.0483 67.8898 57.7792 67.4712 57.5624C67.0525 57.3456 66.6077 57.2372 66.133 57.2372C65.6172 57.2372 65.1612 57.3344 64.7613 57.5288C64.3651 57.7231 64.0249 57.9848 63.7446 58.3137C63.4642 58.6426 63.2512 59.0239 63.1054 59.4537C62.9596 59.8873 62.8849 60.3396 62.8849 60.8143C62.8849 61.289 62.9596 61.7413 63.1054 62.1749C63.2512 62.6085 63.4642 62.9897 63.7446 63.3149C64.0249 63.6438 64.3651 63.9055 64.7687 64.0998C65.1724 64.2942 65.6322 64.3914 66.1517 64.3914C66.6713 64.3914 67.1123 64.2942 67.5235 64.0998C67.9347 63.9055 68.2748 63.6401 68.5402 63.3037L69.8596 64.4961C69.441 64.9857 68.9177 65.3632 68.2898 65.6361C67.6618 65.909 66.9516 66.0435 66.1555 66.0435C65.3593 66.0435 64.679 65.9164 64.0324 65.666C63.3857 65.4156 62.8213 65.0642 62.3466 64.6082C61.8719 64.1559 61.4982 63.6065 61.2253 62.9673C60.9524 62.3281 60.8179 61.6067 60.8179 60.8106C60.8179 60.0144 60.9487 59.3192 61.2141 58.6763C61.4795 58.0334 61.8495 57.4839 62.3242 57.0242C62.7989 56.5644 63.3596 56.2093 64.01 55.9552C64.6603 55.7047 65.3593 55.5776 66.1144 55.5776C66.8133 55.5776 67.5048 55.7197 68.1963 55.9963C68.8878 56.2766 69.4335 56.6803 69.841 57.2111L68.4168 58.3623L68.4131 58.3698Z" fill="#0C2738"/>
      <path className="cls-2" d="M77.9146 57.4726H75.2757V62.627C75.2757 63.2251 75.3879 63.6549 75.6121 63.9091C75.8364 64.167 76.1915 64.2941 76.6811 64.2941C76.8606 64.2941 77.0587 64.2754 77.268 64.238C77.4773 64.2006 77.6642 64.1408 77.8324 64.0661L77.8959 65.6771C77.6567 65.7593 77.3951 65.8266 77.111 65.8752C76.8232 65.9238 76.5279 65.9499 76.2214 65.9499C75.2869 65.9499 74.5693 65.692 74.0759 65.1762C73.5788 64.6604 73.3321 63.8867 73.3321 62.855V57.4763H71.4258V55.8653H73.3321V53.0171H75.2795V55.8653H77.9184V57.4763L77.9146 57.4726Z" fill="#0C2738"/>
      <path className="cls-2" d="M101.067 58.3513C101.067 59.4951 100.869 60.5454 100.469 61.5023C100.073 62.4592 99.5231 63.2815 98.8241 63.973C98.1252 64.6645 97.2954 65.2027 96.3347 65.584C95.3704 65.969 94.3238 66.1596 93.195 66.1596C92.0662 66.1596 91.0196 65.969 90.0664 65.584C89.1095 65.199 88.2835 64.6645 87.5845 63.973C86.8855 63.2815 86.3436 62.4592 85.9511 61.5023C85.5586 60.5454 85.3643 59.4951 85.3643 58.3513C85.3643 57.2076 85.5586 56.1348 85.9511 55.1817C86.3398 54.2248 86.8855 53.4099 87.5845 52.7334C88.2835 52.0569 89.1095 51.5298 90.0664 51.1523C91.0233 50.7748 92.0662 50.5879 93.195 50.5879C94.3238 50.5879 95.3704 50.7748 96.3347 51.1523C97.2991 51.5298 98.1289 52.0569 98.8241 52.7334C99.5231 53.4099 100.069 54.2248 100.469 55.1817C100.865 56.1385 101.067 57.1963 101.067 58.3513ZM98.8279 58.3513C98.8279 57.544 98.6933 56.7777 98.4316 56.06C98.1663 55.3424 97.7887 54.7107 97.3028 54.165C96.8132 53.6193 96.2263 53.1931 95.5348 52.8754C94.8433 52.5615 94.0659 52.4045 93.2025 52.4045C92.339 52.4045 91.5802 52.5615 90.8887 52.8754C90.1972 53.1894 89.6104 53.6193 89.132 54.165C88.6498 54.7107 88.276 55.3386 88.0106 56.06C87.7452 56.7777 87.6144 57.544 87.6144 58.3513C87.6144 59.1587 87.7452 59.9511 88.0106 60.6762C88.276 61.4014 88.6535 62.0331 89.1394 62.5713C89.6291 63.1096 90.2122 63.5319 90.8962 63.8384C91.5802 64.1449 92.3465 64.2982 93.1987 64.2982C94.0509 64.2982 94.8247 64.1449 95.5236 63.8384C96.2226 63.5319 96.8132 63.1096 97.3028 62.5713C97.7925 62.0331 98.1663 61.4014 98.4316 60.6762C98.697 59.9511 98.8279 59.1774 98.8279 58.3513Z" fill="#0C2738"/>
      <path className="cls-2" d="M110.359 65.7821C110.329 65.5317 110.31 65.2476 110.295 64.9336C110.28 64.6197 110.273 64.358 110.273 64.1487H110.232C109.981 64.6795 109.559 65.128 108.965 65.498C108.37 65.8681 107.713 66.0512 106.987 66.0512C106.344 66.0512 105.791 65.9466 105.324 65.7373C104.857 65.528 104.472 65.2401 104.173 64.8701C103.874 64.5001 103.65 64.0702 103.504 63.5806C103.358 63.0909 103.283 62.5676 103.283 62.0107V55.8582H105.249V61.3416C105.249 61.7453 105.279 62.134 105.343 62.5041C105.406 62.8741 105.522 63.1993 105.687 63.4759C105.855 63.7562 106.083 63.9768 106.378 64.145C106.67 64.3132 107.047 64.3954 107.507 64.3954C108.303 64.3954 108.942 64.1001 109.432 63.5058C109.922 62.9115 110.165 62.1191 110.165 61.1285V55.8545H112.131V63.6404C112.131 63.9057 112.138 64.2459 112.153 64.6645C112.168 65.0832 112.187 65.4532 112.217 65.7746H110.355L110.359 65.7821Z" fill="#0C2738"/>
      <path className="cls-2" d="M120.406 57.4726H117.767V62.627C117.767 63.2251 117.879 63.6549 118.104 63.9091C118.328 64.167 118.683 64.2941 119.173 64.2941C119.352 64.2941 119.55 64.2754 119.759 64.238C119.969 64.2006 120.156 64.1408 120.324 64.0661L120.387 65.6771C120.148 65.7593 119.887 65.8266 119.602 65.8752C119.315 65.9238 119.019 65.9499 118.713 65.9499C117.778 65.9499 117.061 65.692 116.567 65.1762C116.07 64.6604 115.824 63.8867 115.824 62.855V57.4763H113.917V55.8653H115.824V53.0171H117.771V55.8653H120.41V57.4763L120.406 57.4726Z" fill="#0C2738"/>
      <path className="cls-2" d="M128.633 58.3698C128.409 58.0072 128.095 57.7119 127.691 57.4802C127.287 57.2484 126.831 57.1363 126.331 57.1363C126.106 57.1363 125.882 57.1625 125.662 57.2111C125.437 57.2597 125.239 57.3381 125.063 57.4428C124.888 57.5475 124.75 57.6783 124.645 57.839C124.54 57.9997 124.488 58.1978 124.488 58.4371C124.488 58.8557 124.675 59.1659 125.052 59.3678C125.43 59.5696 125.994 59.7565 126.749 59.921C127.224 60.0331 127.661 60.1639 128.069 60.3172C128.472 60.4704 128.824 60.6611 129.126 60.8928C129.426 61.1246 129.661 61.3974 129.829 61.7189C129.997 62.0403 130.08 62.4178 130.08 62.8514C130.08 63.4383 129.967 63.9316 129.743 64.3353C129.519 64.739 129.224 65.0717 128.854 65.3296C128.484 65.5875 128.061 65.7744 127.587 65.8828C127.112 65.9949 126.622 66.051 126.121 66.051C125.366 66.051 124.63 65.9052 123.912 65.6099C123.195 65.3184 122.597 64.8698 122.122 64.2718L123.524 63.0794C123.789 63.4682 124.155 63.7971 124.622 64.0625C125.09 64.3279 125.602 64.4587 126.162 64.4587C126.413 64.4587 126.656 64.4363 126.884 64.3839C127.116 64.3353 127.325 64.2568 127.512 64.1447C127.699 64.0326 127.848 63.8868 127.96 63.7036C128.072 63.5242 128.128 63.2925 128.128 63.0121C128.128 62.5524 127.908 62.2085 127.471 61.988C127.03 61.7637 126.387 61.5544 125.534 61.36C125.198 61.2778 124.862 61.1769 124.518 61.0573C124.174 60.9377 123.864 60.7732 123.587 60.5639C123.307 60.3546 123.082 60.0929 122.907 59.7789C122.731 59.465 122.645 59.0762 122.645 58.6165C122.645 58.0857 122.754 57.6297 122.97 57.2447C123.187 56.8597 123.467 56.5457 123.819 56.3028C124.166 56.0598 124.566 55.8767 125.011 55.7571C125.456 55.6374 125.919 55.5776 126.394 55.5776C127.104 55.5776 127.792 55.7197 128.457 55.9963C129.119 56.2766 129.631 56.6803 129.997 57.2111L128.637 58.3623L128.633 58.3698Z" fill="#0C2738"/>
      <path className="cls-2" d="M134.871 52.2808C134.871 52.6433 134.744 52.9424 134.486 53.1816C134.228 53.4208 133.922 53.5367 133.563 53.5367C133.204 53.5367 132.898 53.4133 132.651 53.1704C132.401 52.9274 132.277 52.6284 132.277 52.2808C132.277 51.9332 132.401 51.6154 132.651 51.3687C132.902 51.1258 133.204 51.0024 133.563 51.0024C133.922 51.0024 134.232 51.1258 134.486 51.3687C134.744 51.6117 134.871 51.9145 134.871 52.2808ZM134.557 65.7818H132.591V55.8616H134.557V65.7818Z" fill="#0C2738"/>
      <path className="cls-2" d="M145.483 64.2121C145.106 64.8139 144.594 65.2699 143.943 65.5839C143.293 65.8978 142.613 66.0548 141.902 66.0548C141.136 66.0548 140.445 65.9203 139.832 65.6474C139.219 65.3745 138.695 65.0008 138.262 64.5261C137.828 64.0513 137.495 63.4944 137.256 62.8515C137.017 62.2086 136.901 61.5246 136.901 60.7995C136.901 60.0743 137.021 59.394 137.256 58.7586C137.492 58.1232 137.832 57.57 138.273 57.0953C138.714 56.6206 139.237 56.2505 139.854 55.9851C140.467 55.7198 141.144 55.5889 141.884 55.5889C142.695 55.5889 143.397 55.7609 143.999 56.101C144.597 56.4449 145.072 56.8598 145.423 57.3457H145.464V49.9561H147.431V65.782H145.524V64.2121H145.483ZM138.953 60.8107C138.953 61.2704 139.024 61.7152 139.162 62.1451C139.301 62.5749 139.51 62.9599 139.79 63.2926C140.071 63.6252 140.411 63.8944 140.815 64.0962C141.218 64.298 141.693 64.399 142.239 64.399C142.74 64.399 143.199 64.3018 143.61 64.1074C144.022 63.913 144.373 63.6514 144.668 63.3262C144.964 63.001 145.188 62.6198 145.349 62.1899C145.509 61.7601 145.588 61.3078 145.588 60.8331C145.588 60.3584 145.506 59.9061 145.349 59.4763C145.188 59.0464 144.96 58.6614 144.668 58.3288C144.377 57.9961 144.022 57.7307 143.61 57.5363C143.199 57.342 142.743 57.2448 142.239 57.2448C141.693 57.2448 141.218 57.342 140.815 57.5363C140.411 57.7307 140.067 57.9961 139.79 58.3288C139.51 58.6614 139.301 59.0464 139.162 59.4763C139.024 59.9061 138.953 60.3509 138.953 60.8107Z" fill="#0C2738"/>
      <path className="cls-2" d="M159.298 60.7362V61.0726C159.298 61.1847 159.291 61.2968 159.276 61.4052H151.385C151.4 61.8201 151.494 62.2126 151.669 62.5789C151.845 62.9452 152.077 63.2666 152.372 63.5358C152.663 63.8049 153.004 64.0179 153.389 64.1712C153.774 64.3244 154.181 64.3992 154.615 64.3992C155.284 64.3992 155.863 64.2534 156.353 63.9581C156.842 63.6666 157.224 63.3078 157.504 62.8891L158.887 63.9993C158.356 64.702 157.732 65.2215 157.014 65.5505C156.297 65.8831 155.497 66.0476 154.618 66.0476C153.863 66.0476 153.168 65.9205 152.525 65.6701C151.882 65.4196 151.333 65.0683 150.873 64.6123C150.413 64.16 150.051 63.6105 149.785 62.9714C149.52 62.3285 149.389 61.6183 149.389 60.8371C149.389 60.0559 149.52 59.3606 149.778 58.714C150.036 58.0636 150.398 57.5104 150.858 57.0507C151.318 56.5909 151.864 56.2321 152.495 55.9742C153.127 55.7163 153.804 55.5854 154.529 55.5854C155.254 55.5854 155.915 55.7051 156.51 55.9405C157.104 56.176 157.609 56.5199 158.02 56.9647C158.431 57.4132 158.749 57.9515 158.973 58.5869C159.197 59.2223 159.309 59.9363 159.309 60.7324L159.298 60.7362ZM157.287 59.94C157.272 59.5513 157.209 59.185 157.089 58.8411C156.969 58.5009 156.797 58.2019 156.566 57.9515C156.334 57.701 156.05 57.5029 155.706 57.3534C155.362 57.2077 154.962 57.1329 154.502 57.1329C154.084 57.1329 153.688 57.2077 153.321 57.3534C152.951 57.4992 152.63 57.6973 152.357 57.9515C152.084 58.2057 151.86 58.5009 151.688 58.8411C151.512 59.185 151.411 59.5475 151.385 59.94H157.287Z" fill="#0C2738"/>
    </svg>
  )
}

export const Logo = ({ height = '30px', style = {}, light = false, type = 'standard' }: Props) => (
  <LogoWrapper light={light}>
    {type === 'connect' 
      ? <ConnectLogo height={height} style={style} /> 
      : <StandardLogo height={height} style={style} />
    }
  </LogoWrapper>
)
