import { addUserToEmailList } from "../retail-api";

export const identify = (email: string) => {
  if (typeof window !== 'undefined' && window._learnq) {
    window._learnq.push(['identify', {
      // Change the line below to dynamically print the user's email.
      '$email': email
    }]);
  }
}

export const subscribeToKlaviyoEmailList = async (email: string) => {
  await addUserToEmailList(email);
}

export const subscribeToKlaviyoList = async (email: string, listId: string) => {
  await addUserToEmailList(email, listId);
}