import { resolveCheckout } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { CHECKOUT_LINE_ITEMS_UPDATE_MUTATION } from "../graphql";
import { Checkout, CheckoutLineItemUpdateInput } from "../types/storefront.types";
import { ShopifyCheckout } from "../types/custom.types";

type CheckoutResponse = { 
  checkoutLineItemsUpdate: {
    checkout:Checkout 
  }
}

export const updateLineItems = async (checkoutId: string, lineItems: CheckoutLineItemUpdateInput[]): Promise<ShopifyCheckout> => {
  const { data, errors } = await storefrontAPIClient.request<CheckoutResponse>(
    CHECKOUT_LINE_ITEMS_UPDATE_MUTATION, 
    { 
      variables: {
        checkoutId, 
        lineItems
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.checkoutLineItemsUpdate?.checkout) throw new Error('Could not update line items in checkout!');

  return resolveCheckout(data.checkoutLineItemsUpdate.checkout);
}