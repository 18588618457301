import React from 'react'
import { SanitySection } from './SanitySection'
import { SectionLeft, SectionRight } from 'components/sections'
import { Button } from 'components/elements'
import { Grid } from 'styles'
import { Link } from 'gatsby'
import { SocialShareButtons } from 'components/elements'
import { Article } from 'types/misc'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

type Props = {
  article: Article
}

export const SanityBlogHero = ({ article }: Props) => {
  const { 
    title, 
    mainImage, 
    mainImageAltText, 
    slug, 
    seoDescription 
  } = article

  const Content = (
    <>
      <SectionLeft className="Section--left">
        <>
          <Link to={`/blog/${slug}`}>
            <h2 className={'no-margins white-text'}>{title}</h2>
          </Link>
          <div className="divider"></div>
          <div className="preview-content white-text">
            {seoDescription && seoDescription.slice(0, 100)}...
          </div>
          <Button color="white" as={Link} to={`/blog/${slug}`}>
            Read More
          </Button>
          <SocialShareButtons isPc={false} />
        </>
      </SectionLeft>
      <SectionRight className="Section--right"></SectionRight>
    </>
  )

  return (
    <div style={{ position: 'relative' }}>
      <GatsbyImage
        style={{ position: 'absolute', height: '100%' }}
        alt={mainImageAltText ? mainImageAltText : `${title} image`}
        className="Hero"
        image={mainImage.asset.gatsbyImageData}
      />
      <div
        style={{
          position: 'inherit',
          background:
            'linear-gradient(90.12deg, rgba(2,2,2,0.5) 40%, rgba(0, 0, 0, 0) 100%)',
        }}
      >
        <SanitySection>
          <BlogHeroContainer>
            <Grid cols={[1, 1, 2]}>{Content}</Grid>
          </BlogHeroContainer>
        </SanitySection>
        <SocialShareButtons isPc={true} />
      </div>
    </div>
  )
}

const BlogHeroContainer = styled.div`
  h2 {
    font-weight: 100;
    line-height: 1.2;
    font-size: 3em;
    transition: color 0.5s;
  }
  h2:hover {
    color: var(--primaryBlue);
  }
  .text-right {
    text-align: right;
  }
  .divider {
    height: 1px;
    background: #fff;
  }
  .preview-content {
    margin: var(--margin) 0;
  }
`
