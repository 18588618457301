import React from 'react'
import styled, { css } from 'styled-components'
import loadable from '@loadable/component'
import { useCustomDate } from 'hooks'
import { TextAlign } from 'types/misc'
const Button = loadable(() => import('components/elements'), {
  resolveComponent: (components) => components.Button,
})
const PortableText = loadable(() => import('components/elements'), {
  resolveComponent: (components) => components.PortableText,
})
const Link = loadable(() => import('gatsby'), {
  resolveComponent: (components) => components.Link,
})

import checkboxIcon from "img/icons/icon-circle-checkmark.svg"
import { below } from 'styles'

type LinkProps = {
  slug: string,
  title: string
  publishedAt?: string
}
const ListItemLink = ({ slug, title, publishedAt }: LinkProps) => {
  const publishedAtParsed = useCustomDate(publishedAt || "");
  return (
    <>
      <Link
        to={slug}
      >
        {title} {publishedAt && `(${publishedAtParsed})`}
      </Link>
    </>
  )
}

type ListItemTextProps = { 
  item: string | LinkProps, 
  listHasAccentText: boolean 
}
const ListItemText = ({ item, listHasAccentText }: ListItemTextProps) => {
  if (typeof item === "string") {
    return (
      <>
        {listHasAccentText ? <h5>{item}</h5> : item}
      </>
    )
  }

  return <ListItemLink {...item} />
}

type ListItemProps = {
  listHasMargin?: boolean,
  listHasAccentText: boolean,
  item: LinkProps | string,
  listBulletsType?: string
}
const ListItem = ({ listHasMargin, listHasAccentText, item, listBulletsType }: ListItemProps) => {
  return (
    <li
      className={`${listHasMargin || listHasAccentText ? 'list-has-margin' : ''}`}
    >
      {listBulletsType === 'checkmark' ? <img className="checkmark-icon" alt="checkmark icon" src={checkboxIcon} /> : null}
      <ListItemText 
        item={item}
        listHasAccentText={listHasAccentText} 
      />
    </li>
  )
}

type ListProps = { 
  listBulletsType?: string, 
  listHasAccentText: boolean, 
  list: string[] | LinkProps[], 
  listHasMargin: boolean
}
const List = ({ listBulletsType, listHasAccentText, list, listHasMargin }: ListProps) => {
  const ListItems = list.map((item, index) => (
    <ListItem
      key={`${typeof item === 'object' ? JSON.stringify(item) : item}-${index}`}
      item={item}
      listHasAccentText={listHasAccentText}
      listHasMargin={listHasMargin}
      listBulletsType={listBulletsType}
    />
  ))
  if (!listBulletsType || listBulletsType === 'disc') { 
    return (
      <div className="list-wrapper">
        <ul className={listHasAccentText ? 'top-margin-small' : 'disc top-margin-small'}>
          {ListItems}
        </ul>
      </div>
    )
  }

  return (
    <div className="list-wrapper">
      <FormatedUl className={listBulletsType}>
        {ListItems}
      </FormatedUl>
    </div>
  )
}

type Props = {
  subtitle: string
  title: string
  _rawParagraph: any[]
  list: string[] | LinkProps[]
  listBulletsType?: string
  listHasMargin: boolean
  listHasAccentText: boolean
  buttonLink: string
  buttonText: string
  buttonTheme: string
  secondaryButtonLink: string
  secondaryButtonText: string
  secondaryButtonTheme: string
  textAlign?: TextAlign
  isTitleH1?: boolean
}
const SimpleList = ({
  subtitle,
  title,
  _rawParagraph,
  list,
  listBulletsType,
  listHasAccentText,
  listHasMargin,
  buttonText,
  buttonLink,
  buttonTheme,
  secondaryButtonText,
  secondaryButtonLink,
  secondaryButtonTheme,
  textAlign,
  isTitleH1
}: Props) => {
  return (
    <SimpleListContainer textAlign={textAlign}>
      {subtitle && <div className='h6'>{subtitle}</div>}
      {isTitleH1 ? <h1 className='no-margins'>{title}</h1> : <h2 className="no-margins">{title}</h2>}
      {_rawParagraph && (
        <MarkedStyles>
          <PortableText _rawContent={_rawParagraph} />
        </MarkedStyles>
      )}
      {list && (
        <List 
          listBulletsType={listBulletsType} 
          listHasAccentText={listHasAccentText}
          list={list}
          listHasMargin={listHasMargin}
        />
      )}
      {buttonText && buttonLink && (
        <div className={`
          full-width 
          top-margin-small
          ${secondaryButtonText && secondaryButtonLink ? 'buttons' : ''}
        `}>
          <Button color={buttonTheme} as={Link} to={buttonLink}>
            {buttonText}
          </Button>
          {secondaryButtonText && secondaryButtonLink && (
            <Button color={secondaryButtonTheme} as={Link} to={secondaryButtonLink}>
              {secondaryButtonText}
            </Button>
          )}
        </div>
      )}
    </SimpleListContainer>
  )
}

export default SimpleList

const SimpleListContainer = styled.div<{ textAlign?: TextAlign}>`
  ul, ol {
    text-align: left;
    margin-top: 0;
  }
  .list-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .buttons {
    a {
      margin-left: 5px;
    }

    ${below.large`
      a {
        padding: 10px 20px;
      }
    `}

    ${below.xSmall`
      margin-top: calc(var(--margin) * .5);
      a {
        display: inline-block;
        max-width: inherit;
        margin-top: 0;
      }
    `}
  }

  ${({ textAlign }) => {
    let styles = [`text-align: ${textAlign};`]
    if (textAlign === 'center' || textAlign === 'right') {
      styles.push(`
        .list-wrapper {
          justify-content: center;
          align-items: center;
        }
      `)
    }

    return css`${styles.join('')}`
  }}
`

export const FormatedUl = styled.ul`
  counter-reset: section;
  font-weight: normal;
  li {
    margin: var(--margin) 0;
    display: flex;
    align-items: center;
  }
  li::before {
    counter-increment: section;
    content: counter(section);
    width: 30px;
    height: 30px;
    background: var(--primaryColor);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: var(--margin);
  }
  &.checkmark {
    li::before {
      display: none;
    }
    li {
      list-style: none;
      content: ' ';
      .checkmark-icon {
        line-height: 0;
        width: 25px;
        height: 25px;
        margin-right: 15px;
      } 
    }
  }
`

export const MarkedStyles = styled.div`
  ul,
  ol {
    list-style: disc;
  }
  a {
    text-decoration: underline;
  }
`
