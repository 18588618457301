import React from 'react'
import { Wrapper } from 'styles'
import { ProductOverview } from 'components/product'
import { transformShopifyStorefrontImagesToGatsbyImage } from 'utilities'
import styled from 'styled-components'
import { ProductCopy } from 'types/misc'
import { useFetchShopifyProductByHandle } from 'hooks'
import { GatsbyShopifyProduct, GatsbyShopifyStorefrontProduct } from 'lib/shopify/storefront-api-client/types/custom.types'

type Props = {
  initialShopifyProduct?: GatsbyShopifyProduct
  productPage: ProductCopy
}

const SanityShopifyProduct = ({ productPage, initialShopifyProduct }: Props) => {
  const shopifyProduct = useFetchShopifyProductByHandle(productPage.handle)
  let productTransformed: GatsbyShopifyStorefrontProduct | null = null;

  if (!shopifyProduct) return null;

  if (initialShopifyProduct) {
    productTransformed = {
      ...initialShopifyProduct,
      variants: shopifyProduct.variants,
      availableForSale: shopifyProduct.availableForSale
    }
  } else {
    productTransformed = transformShopifyStorefrontImagesToGatsbyImage(shopifyProduct);
  }

  if (!productTransformed) return null;

  return (
    <div>
      <ProductWrapper>
        <ProductOverview
          product={productTransformed}
          productPage={productPage}
          productMedia={productTransformed.media}
        />
      </ProductWrapper>
    </div>
  )
}

const ProductWrapper = styled(Wrapper)`
  padding-top: 4rem;
`

export default SanityShopifyProduct
