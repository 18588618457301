import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import Papa, { ParseResult } from "papaparse";
import { SpecsChart } from "components/elements";
import { Wrapper } from "styles";
import { Section } from "./Section";
import { Spec } from "types/misc";

const transformSpec = (row: string[]) => {
  const spec = {} as Spec
  spec.title = row[0];

  const isDescriptionList = row[1].match(new RegExp("\n", "g"))?.length;
  if (typeof isDescriptionList === "number" && isDescriptionList > 0) {
    spec.descriptionList = row[1].split('\n');
    return spec;
  }

  spec.description = row[1].trim();
  return spec;
}

const parseCsv = async (
  url: string, 
  onComplete: (specs: Spec[]) => any, 
  removeHeaders?: boolean,
) => {
  try {
    const res = await fetch(url);
    const csvString = await res.text();
    const parseResult = Papa.parse(csvString) as ParseResult<string[]>
  
    if (!parseResult.data || (parseResult.data[0].length < 2 || parseResult.data[0].length > 2)) {
      throw new Error('CSV spec chart is not properly formatted. Please reformat and re-upload.');
    }

    if (removeHeaders) {
      parseResult.data.shift()
    }
  
    const specs: Spec[] = parseResult.data.map(transformSpec)
  
    onComplete(specs); 
  } catch (error) {
    console.error(error);
  }
}

type Props = {
  title: string,
  csvUrl: string,
  removeHeaders?: boolean
}
const SanityProductSpecChart = ({ 
  title, 
  csvUrl, 
  removeHeaders 
}: Props) => {
  const [specs, setSpecs] = useState<Spec[]|null>(null);

  useEffect(() => {
    parseCsv(csvUrl, setSpecs, removeHeaders)
  }, [])

  if (!specs) return null;

  return (
    <Section size="small" width='tight'>
      <Wrapper>
        <h2 className="center-text no-top-margin">{title}</h2>
        <SpecsChart specs={specs} />
      </Wrapper>
    </Section>
  )
}

export default SanityProductSpecChart;