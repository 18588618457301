import { useEffect, useState } from "react"
import { fetchIpLocation } from "./ip-geolocation-service";
import { GeoIpLocationResponse } from "./types";

const SESSION_STORAGE_KEY = 'ip_geolocation_data';

export const useIpGeolocation = () => {
  const [isInternational, setIsInternational] = useState(false);
  const [geolocationData, setGeolocationData] = useState<GeoIpLocationResponse>({
    postal_code: "",
    country_code: ""
  });
  const isBrowser = typeof window !== 'undefined';
  const cachedIpLocation = isBrowser ? sessionStorage.getItem(SESSION_STORAGE_KEY) : null;

  useEffect(() => {
    const checkIpInternational = (ipLocation: GeoIpLocationResponse) => {
      setIsInternational(ipLocation.country_code !== "US");
      setGeolocationData(ipLocation);
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(ipLocation));
    };

    if (isBrowser && cachedIpLocation === null) {
      fetchIpLocation().then(checkIpInternational).catch(console.error);
    } else {
      const cachedIpLocationData: GeoIpLocationResponse = JSON.parse(cachedIpLocation as string);
      setIsInternational(cachedIpLocationData.country_code !== "US");
      setGeolocationData(cachedIpLocationData);
    }
  }, []);

  return {
    geolocationData,
    isInternational,
    internationalLink: {
      href: "https://www.bigapplebuddy.com/brands/rachio?utm-medium=rachio",
      copy: "Shop International"
    }
  }
}