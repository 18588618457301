declare global {
  interface Window {
    dataLayer: {
      push: (params: any) => void
    };
  }
}

export const trackEvent = (eventName: string, properties: any) => {
  window.dataLayer.push({
    event: eventName,
    ...properties
  })
}

export const identify = (email: string) => {
  window.dataLayer.push({
    event: 'identify',
    email
  })
}