import { useEffect } from 'react'

// Listens to see if window.dataLayer is loaded and only set callback if it is
// ? this is used for tracking events that get hit when first going to a page. Segment middleware won't load until client ID is made available on window.ga so we wait until it is loaded to fire our tracking event
export const useGaCheck = (trackEvent: () => any, rerunProps = []) => {
  useEffect(() => {
    const waitForClientId = () => {
      let timesTried = 0
      const timesToWait = 10
      if (window.dataLayer) {
        trackEvent()
      } else if (timesTried < timesToWait) {
        setTimeout(waitForClientId, 200)
      }
    }
    setTimeout(waitForClientId, 200)
  }, rerunProps)
}
