import React from 'react'
import loadable from '@loadable/component'
import { SanitySection } from 'components/sections'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { SANITY_MOBILE_RESPONSIVE_IMAGE_BREAKPOINT, SanityButtonTheme, SectionPaddingSize, SectionSize, SectionTheme, TextAlign } from 'types/misc'
const Link = loadable(() => import('gatsby'), {
  resolveComponent: (components) => components.Link,
})
const CustomGatsbyImage = loadable(() => import('components/elements'), {
  resolveComponent: (components) => components.CustomGatsbyImage,
})
import { Button, PortableText } from 'components/elements'
import styled from 'styled-components'
import { SectionOuter } from './sectionStyle'
import { media, sizes } from 'styles'
import { useWindowWidth } from 'hooks'

type Props = {
  layout?: 'full-width' | 'contained'
  title: string
  _rawParagraph: any[]
  buttonLink?: string
  buttonText?: string
  buttonTheme?: SanityButtonTheme
  theme?: SectionTheme
  size?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  backgroundImage: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  mobileBackgroundImage: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  altText: string
  disableBorder?: boolean
  textAlign?: TextAlign
}
export const SanitySimple = ({
  layout,
  title,
  _rawParagraph,
  size = '',
  buttonLink = '',
  buttonText = '',
  buttonTheme,
  theme,
  paddingTop = '',
  paddingBottom = '',
  image,
  altText,
  disableBorder,
  textAlign = "center",
  backgroundImage,
  mobileBackgroundImage
}: Props) => {
  const width = useWindowWidth();
  const primaryImage = mobileBackgroundImage && Number(width) <= SANITY_MOBILE_RESPONSIVE_IMAGE_BREAKPOINT
    ? mobileBackgroundImage
    : backgroundImage;

  const Content = (
    <div className={`content ${textAlign || 'center'}-text`}>
      <h2 className="no-margins">{title}</h2>
      <div className={`
        description
        ${textAlign === 'center' ? 'max-width-small' : ''}
      `}>
        <PortableText _rawContent={_rawParagraph} />
      </div>

      {image && (
        <CustomGatsbyImage
          image={image.asset.gatsbyImageData}
          alt={altText}
        />
      )}
      {buttonLink && buttonText && (
        <Button as={Link} to={buttonLink} color={buttonTheme || 'dark'}>
          {buttonText}
        </Button>
      )}
    </div>
  ) 

  if (!layout) {
    return (
      <div 
        style={{ 
          borderBottom: (!_rawParagraph && !buttonLink) || disableBorder ? 'none' : '1px solid var(--lineColor)',
          position: 'relative'
        }}
      >
        <div className="content-wrapper">
          <SanitySection
            width="tight"
            size={size}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            theme={theme || 'white'}
          >
            {Content}
          </SanitySection>
        </div>
      </div>
    )
  }

  return (  
    layout === 'full-width' ? (
      <FullWidthLayout 
        style={{ 
          borderBottom: (!_rawParagraph && !buttonLink) || disableBorder ? 'none' : '1px solid var(--lineColor)',
          position: 'relative'
        }}
      >
        {primaryImage && (
          <GatsbyImage 
            image={primaryImage.asset.gatsbyImageData}
            alt={title}
            objectFit='contain'
          />
        )}
        <div className="content-wrapper">
          <SanitySection
            width="tight"
            size={size}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            theme={theme || 'white'}
          >
            {Content}
          </SanitySection>
        </div>
      </FullWidthLayout>
    ) : (
      <section className='white-bg'>
        <ContainedWrapper>
          <ContainedLayout 
            className={`
              Section--theme__${theme || 'white'}
            `}
          >
            {primaryImage && (
              <GatsbyImage 
                image={primaryImage.asset.gatsbyImageData}
                alt={title}
                className='background-image'
                objectFit='cover'
              />
            )}
            <div className='overlay' />
            <div className='inner'>
              {Content}
            </div>
          </ContainedLayout>
        </ContainedWrapper>
      </section>
    )
  )
}

const ContainedWrapper = styled.div`
  padding: var(--basePadding) var(--basePadding) 0;
  margin: 0px auto;
  max-width: var(--layoutWidthMax);
`

const FullWidthLayout = styled.div`
  margin-top: 5rem;
  .content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .Section {
      background: transparent;
      color: white;
    }
  }
`

const ContainedLayout = styled(SectionOuter)`
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  padding: var(--basePadding);
  .overlay {
    background-color: rgba(0,0,0,.3);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .inner {
    max-width: 768px;
    position: relative;
    z-index: 3;
    min-height: 320px;
    display: flex;
    align-items: center;

    h2 {
      font-size: var(--h4);
    }

    .description {
      max-width: ${sizes.small}px;
      line-height: 2rem;
      margin-top: 1rem;
    }
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
  }

  ${media.medium`
    padding: calc(var(--basePadding) * 2);
  `}
`

export default SanitySimple
