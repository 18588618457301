import { resolveCheckout } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { CHECKOUT_DISCOUNT_CODE_APPLY_MUTATION } from "../graphql";
import { ShopifyCheckout } from "../types/custom.types";
import { Checkout } from "../types/storefront.types";

type CheckoutResponse = { 
  checkoutDiscountCodeApplyV2: {
    checkout:Checkout 
  }
}

export const addDiscount = async (checkoutId: string, discountCode: string): Promise<ShopifyCheckout> => {
  const { data, errors } = await storefrontAPIClient.request<CheckoutResponse>(
    CHECKOUT_DISCOUNT_CODE_APPLY_MUTATION, 
    { 
      variables: {
        checkoutId, 
        discountCode
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.checkoutDiscountCodeApplyV2?.checkout) throw new Error('Could not apply discount!');

  return resolveCheckout(data.checkoutDiscountCodeApplyV2.checkout);
}