import React from "react";

declare var window: any

const YOTPO_WIDGET_GUID = 'gXYy9uEnI0O3E7udq2fa7g';

type Props = {
  widgetInstanceId: number
}
export const YotpoWidget = ({ widgetInstanceId }: Props) => {
  React.useEffect(() => {
    if (typeof window !== 'undefined' && !window.yotpoWidgetsContainer) {
      const script = document.createElement('script')
      script.id = 'yotpo-script-widget-handler'
      script.setAttribute(
        'src',
        `https://cdn-widgetsrepository.yotpo.com/v1/loader/${YOTPO_WIDGET_GUID}`
      )
      document['body'].appendChild(script)
    } else {
      if (window.yotpoWidgetsContainer.guids[YOTPO_WIDGET_GUID]?.config?.widgets[widgetInstanceId]) {
        window.yotpoWidgetsContainer.guids[YOTPO_WIDGET_GUID].initWidgets();
      } else {
        throw new Error(`YotPo Widget Not Found: ${widgetInstanceId}`)
      }
    }
  }, [])

  return (
    <div 
      className="yotpo-widget-instance" 
      data-yotpo-instance-id={widgetInstanceId} 
      id={`yotpo-widget-instance-${widgetInstanceId}`}
    />
  )
}
