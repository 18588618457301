import { resolveCheckout } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { CHECKOUT_CREATE_MUTATION } from "../graphql";
import { ShopifyCheckout } from "../types/custom.types";
import { Attribute, Checkout, MailingAddress } from "../types/storefront.types";

type CheckoutCreateInput = {
  customAttributes?: Attribute[]
  email?: string
  lineItems?: {
    customAttributes: Attribute[]
    quantity: number
    variantId: string
  }[]
  note?: string
  shippingAddress?: MailingAddress
}

type CheckoutResponse = { 
  checkoutCreate: {
    checkout:Checkout 
  }
}
export const createCheckout = async (input?: CheckoutCreateInput): Promise<ShopifyCheckout> => {
  const { data, errors } = await storefrontAPIClient.request<CheckoutResponse>(
    CHECKOUT_CREATE_MUTATION, 
    { 
      variables: {
        input: input || { note: "" }
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.checkoutCreate) throw new Error('Could not create checkout!');

  return resolveCheckout(data.checkoutCreate.checkout);
}