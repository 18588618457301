import React from "react"
import { Rating } from "react-simple-star-rating"
import styled from "styled-components"

type Props = {
  rating: number,
  ratingText?: string
}
export const ProductRating = ({ rating, ratingText }: Props) => (
  <ProductRatingWrapper>
    <Rating
      initialValue={rating}
      allowFraction={true}
      size={20}
      tooltipDefaultText={ratingText || String(rating)}
      tooltipStyle={{
        marginLeft: '5px',
        padding: '0px 15px',
        position: 'relative',
        top: '-3px'
      }}
      showTooltip={true}
      readonly={true}
    />
  </ProductRatingWrapper>
)

const ProductRatingWrapper = styled.div``