import { useEffect, useState } from "react"
import { fetchRebates } from "./ecorebates-service";
import { EcoRebatesResponse } from "./types";

export const useEcoRebates = (zipCode: number, skus: string[]) => {
  const [totalRebates, setTotalRebates] = useState(0);

  useEffect(() => {
    if (zipCode && skus) {
      fetchRebates(zipCode, skus).then((rebates: EcoRebatesResponse) => {
        if (rebates.EcoRebatesResponse?.totalItemCount) {
          setTotalRebates(rebates.EcoRebatesResponse.totalItemCount)
        }
      });
    }
  }, [zipCode, skus]);

  return {
    totalRebates
  }
}