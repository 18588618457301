import styled from 'styled-components'
import { below } from 'styles'

export const TextBubble = styled.div`
  margin: .5rem auto;
  padding: 1.5rem 3rem;
  border: 1px solid var(--lineColor);
  border-radius: var(--cardRadius);
  background: white;
  a {
    text-decoration: underline;
  }

  ${below.small`
    padding: 1.5rem;
  `}
`

export const FormattedHtml = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol {
    margin-bottom: 4rem;
  }
  ul {
    list-style: disc;
    padding-left: 2rem;
  }
  table {
    tr {
      td,
      th {
        border-bottom: 1px solid var(--lineColor);
        padding: var(--basePadding);
        &:nth-child(2) {
          border-left: 1px solid var(--lineColor);
        }
      }
    }
    ${below.medium`
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      tr {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--lineColor);
        justify-content: center;
        th, td {
          text-align: left;
          border: none;
          margin: 0;
          display: block;
          padding: var(--basePadding) 0;
          &:nth-child(2) {
          border: none;
        }
        }
      }
    `};
  }
`
