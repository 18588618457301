;import React, { useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { below } from 'styles'
import { useDiscount } from 'global'
import { Price, ProductFormMini } from 'components/product'
import { Link } from 'gatsby'
import { getBasePrice, getDefaultShopifyProductImage } from 'utilities'
import { useAddToCart } from 'hooks'
import { GatsbyShopifyStorefrontCopyUpsellProduct, ShopifyProductVariant } from 'lib/shopify/storefront-api-client/types/custom.types'

export type ProductUpsellProps = {
  shopifyStorefrontCopyProduct: GatsbyShopifyStorefrontCopyUpsellProduct
  noRedirectToCart?: boolean
  testId?: string
  bordered?: boolean
}

export const ProductUpsell = ({
  shopifyStorefrontCopyProduct,
  testId,
  noRedirectToCart,
  bordered
}: ProductUpsellProps) => {
  const { getDiscountAmount } = useDiscount();

  const [selectedVariant, setSelectedVariant] = useState<ShopifyProductVariant>(shopifyStorefrontCopyProduct.variants[0]);

  const { addToCart, buttonText, buttonTheme } = useAddToCart({ 
    product: shopifyStorefrontCopyProduct,
    selectedVariant,
    noRedirectToCart,
    customButtonText: 'Add',
    customButtonTheme: 'dark'
  });

  if (!selectedVariant || !shopifyStorefrontCopyProduct) return null;

  const productImage = getDefaultShopifyProductImage(shopifyStorefrontCopyProduct);

  return (
    <ItemStyles style={{ border: bordered ? '1px solid var(--lineColor)' : '' }}>
      <div className="upsell-content" data-testid={testId || 'standard-product-upsell'}>
        <div className='product-details'>
          <div className="image-container">
            <div>
              <Link to={shopifyStorefrontCopyProduct.copy.seoPathname}>
                <GatsbyImage
                  image={productImage.image.gatsbyImageData}
                  alt={productImage.altText || `${shopifyStorefrontCopyProduct.title} featured image`}
                />
              </Link>
            </div>
          </div>
          <div className="heading-content">
            <div className="product-title">
              <Link to={shopifyStorefrontCopyProduct.copy.seoPathname}>
                {shopifyStorefrontCopyProduct.title}
              </Link>
            </div>

            <Price 
              basePrice={getBasePrice(selectedVariant)} 
              discountAmount={getDiscountAmount(selectedVariant)}
            />
          </div>
          
          <ProductFormMini 
            shopifyProductLike={shopifyStorefrontCopyProduct}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
            addToCart={addToCart}
            buttonText={buttonText}
            buttonTheme={buttonTheme}
            hidePrice={true}
            singleVariantLayout={false}
            testId={
              selectedVariant.availableForSale 
              ? 'cart-product-upsell-add-to-cart-button' 
              : 'cart-product-upsell-out-of-stock-button'
            }
          />
        </div>
      </div>
    </ItemStyles>
  )
}

const ItemStyles = styled.div`
  padding: 10px var(--cardPadding);
  margin-top: 1em;
  border-radius: var(--cardRadius);
  background: white;
  
  .upsell-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 20px auto;
    .heading-content {
      width: calc(100% - 200px);
      padding: 0 10px;
      .product-title {
        margin-bottom: 5px;
        a {
          font-size: 1.5rem;
          color: var(--darkNavy);
        }
      }
    }
  }

  .product-details {
    width: 100%;
    display: flex;
    align-items: center;
    .heading-content {
      flex: 1;
    }
  }

  .image-container {
    isolation: isolate;
    border-radius: 10px;
    overflow: hidden; 
    width: 80px;
  }

  .add-to-cart {
    display: flex;
    div {
      flex: 1;
    }
  }

  .variant-select-input {
    padding: 9px 30px 9px 20px;
    margin: 0;
  }

  .add-to-cart-button {
    padding: 9px 20px;
    margin-left: 5px;
    font-size: 13px;
  }

  p.price,
  p.discount {
    line-height: 20px;
  }

  p.discount {
    font-weight: bold;
    font-family: var(--bodyFont);
  }

  ${below.mediumLarge`
    .upsell-content .heading-content {
      line-height: 20px;
    }

    .variant-select-input {
      font-size: 13px;
    }
  `}

  ${below.small`
    .add-to-cart {
      display: block;

      div, 
      .add-to-cart-button {
        width: 100%;
      }
    
      .add-to-cart-button {
        padding: 9px 10px;
        margin-left: 0;
      }

      .variant-select-input,
      .add-to-cart-button {
        font-size: 12px;
      }
      
      div {
        margin-bottom: 5px;
      }
    }
  `}
`
