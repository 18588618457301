import { CollapsibleText } from "components/elements";
import React from "react";
import styled from "styled-components";
import { below, sizes } from "styles";

type Props = {
  title: string,
  collectionLength: number
  description?: string
}
export const CollectionHeader = ({ title, description, collectionLength }: Props) => {
  return (
    <Container data-testid="collection-header">
      <div className="title-wrapper">
        <h2 className='no-top-margin'>{title}</h2>
        <span>{collectionLength} Products</span>
      </div>
      {description && <CollapsibleText text={description}/>}
    </Container>
  )
}

const Container = styled.div`
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: var(--h3);
      max-width: 75%;
    }
    ${below.small`
      h2 {
        font-size: var(--h4);
      }
    `}

    @media (max-width: 399px) {
      span {
        display: none;
      }
      h2 {
        max-width: 100%;
      }
    }
  }

  .collapsible-text {
    max-width: ${sizes.medium}px;
    p {
      margin-top: 0;
      margin-bottom: 25px;
    }
  }
`