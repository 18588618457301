import { EmailInput } from "components/inputs";
import React from "react";
import { subscribeToKlaviyoList } from "../../lib/klaviyo";
import styled from "styled-components";
import { getProductModalDisplayName, getProductWaitingListIdFromKlaviyo } from "utilities";

type Props = {
  productPage: { 
    klaviyoBackInStockListId?: string, 
    handle: string 
  },
  product: { 
    title: string 
  },
  selectedVariant: { 
    title: string 
  }
}
export const BackInStockAlert = ({ productPage, product, selectedVariant }: Props) => {
  const onSubmit = async (email: string) => {
    subscribeToKlaviyoList(
      email,
      getProductWaitingListIdFromKlaviyo(productPage.klaviyoBackInStockListId)
    )
  }

  return (
    <OutOfStockAlert>
      <h5 className='no-top-margin'>
        {getProductModalDisplayName(productPage, product, selectedVariant)} will be available
        again soon
      </h5>
      <p>
        Please enter your email below and we will notify you when this product is back in stock.
      </p>
      <EmailInput
        id="newsletter-submit"
        confirmationText="Thanks, we'll be in touch."
        onSubmit={onSubmit}
      />
    </OutOfStockAlert>
  )    
}

export const OutOfStockAlert = styled.div`
  margin-bottom: var(--margin);
  background: white;
  padding: var(--cardPadding);
  border-radius: var(--cardRadius);
  h4 {
    font-weight: var(--fontWeightBold);
  }
  p {
    margin: 10px 0;
  }
  button {
    padding: 0 30px;
  }
`
