import { GeoIpLocationResponse } from "./types";

const BASE_URL = 'https://ipgeolocation.abstractapi.com/v1';

export const fetchIpLocation = async (): Promise<GeoIpLocationResponse> => {
  const endpoint = `${BASE_URL}?api_key=${process.env.GATSBY_ABSTRACT_IP_GEOLOCATION_API_KEY}`;

  const res = await fetch(endpoint);

  if (!res.ok) {
    throw new Error("Could not fetch geoip address.");
  }

  return await res.json();
}