import React from 'react'
import styled from 'styled-components'
import { Icon } from '../elements'

type Props = {
  decrement: () => void
  increment: () => void
  selectedAmount: number
  limit?: number | null
  minAmount?: number
  disabled?: boolean
}

export const QuantityInput = ({
  decrement,
  increment,
  selectedAmount,
  limit = null,
  minAmount,
  disabled = false
}: Props) => {
  const isDisabledDecrement = (
    disabled 
    || (minAmount ? selectedAmount <= minAmount : selectedAmount <= 0)
  )
  const isDisabledIncrement = (
    disabled 
    || (!!limit && selectedAmount >= limit)
  )
  return (
    <Quantity className='quantity-input'>
      <button
        onClick={decrement}
        disabled={isDisabledDecrement}
        data-testid="subtract-btn"
      >
        <Icon
          name="subtract"
          color={
            isDisabledDecrement ? 'var(--lighterGray)' : 'var(--primaryColor)'
          }
        />
      </button>
      <span className="quantity" data-testid="input-quantity">
        {selectedAmount}
      </span>
      <button
        onClick={increment}
        disabled={isDisabledIncrement}
        data-testid="add-btn"
      >
        <Icon
          name="add"
          color={
            isDisabledIncrement
              ? 'var(--lighterGray)'
              : 'var(--primaryColor)'
          }
        />
      </button>
    </Quantity>
  )
}

const Quantity = styled.div`
  display: flex;
  * {
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    width: 20px;
    height: 20px;
  }
  .quantity {
    background: white;
    padding: 5px 10px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    color: var(--darkerGray);
    border-radius: var(--inputRadius);
    border: 1px solid var(--lineColor);
    margin: 0 var(--smallMargin);
    pointer-events: none;
  }
`
