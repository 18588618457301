import React, { KeyboardEvent, MouseEvent, forwardRef } from 'react'
import styled, { css } from 'styled-components'

type InputProps = {
  name: string
  disabled?: boolean
  value: string | number | null
  label?: string
  type?: string
  placeholder?: string
  pattern?: string | undefined
  title?: string
  onChange: (args: any) => void
  onClick?: (e: MouseEvent) => void
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void
  onBlur?: () => void
  required?: boolean
  testId?: string
}

export const Input = forwardRef(
  (
    {
      name,
      label = '',
      onChange,
      onClick,
      onKeyPress,
      value = '',
      onBlur,
      type = 'text',
      disabled = false,
      placeholder = '',
      pattern = undefined,
      title = '',
      required = false,
      testId = '',
    }: InputProps,
    ref
  ) => (
    <Label className="input-label" htmlFor={name}>
      {label && (
        <span className="input-title">
          {label}
          {required && '*'}
        </span>
      )}
      <input
        // @ts-expect-error - TS chokes on forwarded Ref
        ref={ref}
        className="input-text"
        placeholder={placeholder}
        pattern={pattern}
        title={title}
        onChange={onChange}
        onClick={onClick}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        id={name}
        data-testid={testId}
        required={required}
        name={name}
        type={type}
        value={value}
        disabled={disabled}
      />
    </Label>
  )
)

type TextareaProps = {
  name: string
  value: string
  label?: string
  onChange: (args: any) => void
  required?: boolean
  rows?: number
  placeholder?: string
  maxLength?: number
}

export const Textarea = ({
  name,
  label = '',
  onChange,
  value = '',
  required = false,
  placeholder = '',
  maxLength = 1020,
  rows = 6,
}: TextareaProps) => (
  <Label className="input-label" htmlFor={name}>
    {label && (
      <span className="input-title">
        {label}
        {required && '*'}
      </span>
    )}
    <textarea
      rows={rows}
      required={required}
      placeholder={placeholder}
      maxLength={maxLength}
      className="input-textarea"
      onChange={onChange}
      id={name}
      name={name}
      value={value}
    />
  </Label>
)

type Option = {
  value: string
  name: string
}

type SelectProps = {
  name: string
  label?: string
  onChange: (args: any) => void
  value: string
  required?: boolean
  options: Option[]
  hasDefaultOption: boolean
  defaultOption?: Option
  isCard?: boolean // Adds card style
  disabled?: boolean
  testId?: string
  id?: string
}

export const Select = ({
  name,
  label = '',
  onChange,
  value = '',
  required = false,
  options,
  hasDefaultOption = false,
  defaultOption = { value: '', name: 'Select an option' },
  disabled = false,
  isCard = false,
  testId = 'select-input',
  id
}: SelectProps) => (
  <Label className="input-label" htmlFor={name} isCard={isCard}>
    {label ? (
      <span className="input-title">
        {label}
        {required && '*'}
      </span>
    ) : (
      <span className='sr-only'>{name}</span>
    )}
    <select
      className="input-select"
      data-testid={testId}
      id={id}
      name={name}
      value={value}
      required={required}
      onChange={onChange}
      disabled={disabled}
    >
      {hasDefaultOption && (
        <option value={defaultOption.value} disabled hidden>
          {defaultOption.name}
        </option>
      )}

      {options.map((option: Option) => (
        <option value={option.value} key={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  </Label>
)

const Label = styled.label`
  > input,
  select,
  textarea {
    margin-top: 0.5rem;
  }
  ${({ isCard }) =>
    isCard &&
    css`
      select {
        cursor: pointer;
        box-shadow: var(--elevation-1);
        border: none;
        padding: var(--cardPadding);
        border-radius: var(--cardRadius);
        font-size: var(--largeFontSize);
        color: var(--secondaryColor);
      }
    `};
`
