export const addUserToEmailList = async (email: string, listId?: string) => {
  const config: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      email,
      listId: listId || process.env.GATSBY_KLAVIYO_EMAIL_SIGN_UP_LIST_ID
    }),
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    }
  }

  const endpoint = `${process.env.GATSBY_RETAIL_API_URL}/tracking/email-newsletter-signup`;

  const res = await fetch(endpoint, config);

  if (!res.ok) {
    throw new Error("Retail API - Could not sign-up user to newsletter list.");
  }
}

export enum DISCOUNT_STATUS {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  SCHEDULED = "SCHEDULED"
}
export type DiscountResponse = {
  endsAt: string | null,
  startsAt: string | null,
  minimumRequirement: {
    greaterThanOrEqualToQuantity: string
  } | null,
  customerGetsQuantity?: string,
  code: string,
  status: DISCOUNT_STATUS,
  summary: string,
  variants: { sku: string }[],
  percentage?: number
  amount?: number
}
export const fetchDiscountByCode = async (code: string): Promise<DiscountResponse> => {
  const config: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      code
    }),
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    }
  }

  const endpoint = `${process.env.GATSBY_RETAIL_API_URL}/shopify/discounts/fetch-discount-by-code`;

  const res = await fetch(endpoint, config);

  if (!res.ok) {
    throw new Error(`Retail API - Could not fetch discount ${code} from.`);
  }

  return await res.json();
}

