import { resolveCheckout } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { CHECKOUT_LINE_ITEMS_ADD_MUTATION } from "../graphql";
import { ShopifyCheckout } from "../types/custom.types";
import { Checkout, CheckoutLineItemInput } from "../types/storefront.types";

type CheckoutResponse = { 
  checkoutLineItemsAdd: {
    checkout:Checkout 
  }
}

export const addLineItems = async (checkoutId: string, lineItems: CheckoutLineItemInput[]): Promise<ShopifyCheckout> => {
  const { data, errors } = await storefrontAPIClient.request<CheckoutResponse>(
    CHECKOUT_LINE_ITEMS_ADD_MUTATION, 
    { 
      variables: {
        checkoutId, 
        lineItems
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.checkoutLineItemsAdd?.checkout) throw new Error('Could not add line items to cart!');

  return resolveCheckout(data.checkoutLineItemsAdd.checkout);
}