import { CHECKOUT_FRAGMENT } from "./fragments/checkout-fragment";


export const CHECKOUT_NODE_QUERY = `
  ${CHECKOUT_FRAGMENT.fragment}
  
  query ($id: ID!) {
    node(id: $id) {
      ${CHECKOUT_FRAGMENT.ref}
    }
  }
`