import { FragmentHelpers } from "../../types"
import { APPLIED_GIFT_CARD_FRAGMENT } from "./applied-gift-card-fragment"
import { DISCOUNT_APPLICATION_FRAGMENT } from "./discount-application-fragment"
import { VARIANT_WITH_PRODUCT_FRAGMENT } from "./variant-with-product-fragment"

const MAILING_ADDRESS_FRAGMENT: FragmentHelpers = {
  fragment: `
    fragment MailingAddressFragment on MailingAddress {
      id
      address1
      address2
      city
      company
      country
      firstName
      formatted
      lastName
      latitude
      longitude
      phone
      province
      zip
      name
      countryCode: countryCodeV2
      provinceCode
    }
  `,
  ref: `...MailingAddressFragment`
}

export const CHECKOUT_FRAGMENT: FragmentHelpers = {
  fragment: `
    ${MAILING_ADDRESS_FRAGMENT.fragment}
    ${DISCOUNT_APPLICATION_FRAGMENT.fragment}
    ${APPLIED_GIFT_CARD_FRAGMENT.fragment}
    ${VARIANT_WITH_PRODUCT_FRAGMENT.fragment}

    fragment CheckoutFragment on Checkout {
      id
      ready
      requiresShipping
      note
      paymentDue {
        amount
        currencyCode
      }
      paymentDueV2: paymentDue {
        amount
        currencyCode
      }
      webUrl
      orderStatusUrl
      taxExempt
      taxesIncluded
      currencyCode
      totalTax {
        amount
        currencyCode
      }
      totalTaxV2: totalTax {
        amount
        currencyCode
      }
      lineItemsSubtotalPrice {
        amount
        currencyCode
      }
      subtotalPrice {
        amount
        currencyCode
      }
      subtotalPriceV2: subtotalPrice {
        amount
        currencyCode
      }
      totalPrice {
        amount
        currencyCode
      }
      totalPriceV2: totalPrice {
        amount
        currencyCode
      }
      completedAt
      createdAt
      updatedAt
      email
      discountApplications(first: 10) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ${DISCOUNT_APPLICATION_FRAGMENT.ref}
          }
        }
      }
      appliedGiftCards {
        ${APPLIED_GIFT_CARD_FRAGMENT.ref}
      }
      shippingAddress {
        ${MAILING_ADDRESS_FRAGMENT.ref}
      }
      shippingLine {
        handle
        price {
          amount
          currencyCode
        }
        priceV2: price {
          amount
          currencyCode
        }
        title
      }
      customAttributes {
        key
        value
      }
      order {
        id
        processedAt
        orderNumber
        subtotalPrice {
          amount
          currencyCode
        }
        subtotalPriceV2: subtotalPrice {
          amount
          currencyCode
        }
        totalShippingPrice {
          amount
          currencyCode
        }
        totalShippingPriceV2: totalShippingPrice {
          amount
          currencyCode
        }
        totalTax {
          amount
          currencyCode
        }
        totalTaxV2: totalTax {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        totalPriceV2: totalPrice {
          amount
          currencyCode
        }
        currencyCode
        totalRefunded {
          amount
          currencyCode
        }
        totalRefundedV2: totalRefunded{
          amount
          currencyCode
        }
        customerUrl
        shippingAddress {
          ${MAILING_ADDRESS_FRAGMENT.ref}
        }
        lineItems (first: 250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              title
              variant {
                ${VARIANT_WITH_PRODUCT_FRAGMENT.ref}
              }
              quantity
              customAttributes {
                key
                value
              }
            }
          }
        }
      }
      lineItems (first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            title
            variant {
              ${VARIANT_WITH_PRODUCT_FRAGMENT.ref}
            }
            quantity
            customAttributes {
              key
              value
            }
            discountAllocations {
              allocatedAmount {
                amount
                currencyCode
              }
              discountApplication {
                ...DiscountApplicationFragment
              }
            }
          }
        }
      }
    }
  `,
  ref: '...CheckoutFragment'
}

