import React, { useState } from "react";
import { CustomerStory, CustomerStoryLayout, SectionSize, SectionTheme } from "types/misc";
import { Section } from "./Section";
import { Link } from "gatsby";
import { Button } from "@rachio-npm/rachio-retail-common";
import { CustomerStoryCard, CustomerStoryModal } from "./customer-stories";
import SliderSection from "./SliderSection";
import styled from "styled-components";

type Props = {
  header?: string,
  description?: string,
  buttonText?: string,
  buttonUrl?: string,
  layout: CustomerStoryLayout,
  paddingBottom: SectionSize,
  paddingTop: SectionSize,
  theme: SectionTheme,
  customerStories: CustomerStory[]
}
const SanityCustomerStoryListSection = ({
  header,
  description,
  buttonText,
  buttonUrl,
  layout,
  paddingBottom,
  paddingTop,
  theme,
  customerStories,
}: Props) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [selectedStory, setSelectedStory] = useState<CustomerStory | null>(null);
  const onSetSelectedStory = (story: CustomerStory) => {
    setSelectedStory(story)
    setIsModalActive(true)
  }
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1028,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }

  return (
    <div>
      {selectedStory && (
        <CustomerStoryModal 
          isModalActive={isModalActive} 
          customerStory={selectedStory} 
          onClose={() => setIsModalActive(false)}
        />
      )}
      {layout === 'slider' ? (
        <SliderContainer>
          <SliderSection
            headline={String(header)}
            id="customer-stories-slider"
            settings={settings}
            buttonText="Read more stories"
            buttonLink="/customer-stories/"
            hideButtonOnMobile={false}
            description={description}
          >
            {customerStories.map(customerStory => (
              <CustomerStoryCard 
                key={customerStory._id} 
                customerStory={customerStory} 
                setSelectedStory={onSetSelectedStory}
                layout="slider"
              />
            ))}
          </SliderSection>
        </SliderContainer>
      ) : (
        <Section
          theme={theme}
          paddingTop={paddingTop || 'small'}
          paddingBottom={paddingBottom || 'small'}
        >
          {header && (
            <div className="center-text">
              <h2 className="no-margins bottom-margin">{header}</h2>
              {description && <p className="no-top-margin">{description}</p>}
              {buttonUrl && buttonText && (
                <Button as={Link} to={buttonUrl}>
                  {buttonText}
                </Button>
              )}
            </div>
          )}
          <GridList>
            {customerStories.map(customerStory => (
              <CustomerStoryCard 
                key={customerStory._id} 
                customerStory={customerStory} 
                setSelectedStory={onSetSelectedStory}
                layout={layout}
              />
            ))}
          </GridList>
        </Section>
      )}
    </div>
  )
}

const GridList = styled.div`
  width: 100%;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 991px) {
    gap: 1rem;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const SliderContainer = styled.div`
  .slick-track {
    margin-top: 2rem;
  }
  .slick-dots {
    bottom: -3rem;
  }
  div.slick-slide {
    padding: 0 1rem;
    width: 32rem;
  }
  .customer-story-card {
    .summary, 
    .customer-card-image-container .gatsby-image-wrapper {
      height: 32rem;
      @media (max-width: 400px) {
        height: 28rem;
      }
    }
  }
`

export default SanityCustomerStoryListSection