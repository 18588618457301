import { FragmentHelpers } from "../../types";
import { VARIANT_FRAGMENT } from "./variant-fragment";

export const VARIANT_WITH_PRODUCT_FRAGMENT: FragmentHelpers = {
  fragment: `
    ${VARIANT_FRAGMENT.fragment}
    fragment VariantWithProductFragment on ProductVariant {
      ${VARIANT_FRAGMENT.ref}
      product {
        id
        handle
        tags
      }
    }
  `,
  ref: '...VariantWithProductFragment'
}