import HeadlineModal from "components/elements/HeadlineModal";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

export const ControllerZoneInfoModal = () => {
  const { backyardTwoZone, rachio38zFlat, rachio38zFlatCloseUp } = useStaticQuery(graphql`
    query {
      backyardTwoZone: file(
        relativePath: { eq: "pages/rachio3/backyard-2-zone.jpg" }
      ) {
        ...fullWidthImageFile
      }
      rachio38zFlat: file(
        relativePath: { eq: "pages/rachio3/rachio-3-8z-flat-open.jpeg" }
      ) {
        ...fullWidthImageFile
      }
      rachio38zFlatCloseUp: file(
        relativePath: { eq: "pages/rachio3/rachio-3-8z-flat-open-close-up.jpeg" }
      ) {
        ...fullWidthImageFile
      }
    }
  `)

  return (
    <HeadlineModal
      title='Choose a zone count:'
      buttonText='What is a zone count?'
      modalContent={() => (
        <>
          <h2 className='no-margins top-margin-large'>What are irrigation zones?</h2>
          <p>
            Yards with sprinkler systems are organized into irrigation zones,
            which are specific areas of your yard activated by a single valve.
            Each zone is typically a grouping of one or more sprinkler heads that
            water the specific area. Ideally, each zone will consist of similar plant
            types to ensure the most efficient watering possible.
          </p>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <h4 className='small-margins'>This backyard has 2 zones</h4>
              <GatsbyImage
                image={backyardTwoZone.childImageSharp.gatsbyImageData}
                alt="enclosure image"
              />
              <p>
                How many zones your yard contains is determined by yard size and landscape irrigation design.
                Each Rachio controller is designed to support a maximum number of zones (specified on the controller’s
                packaging and within its wiring bay). Rachio controllers may support 4, 8, or 16 zones.
              </p>
            </div>
          </div>
          <div>
            <h4 className='small-margins'>Rachio 8 zone controller</h4>
            <div>
              <GatsbyImage
                image={rachio38zFlatCloseUp.childImageSharp.gatsbyImageData}
                alt="enclosure image"
              />
            </div>
            <div>
              <GatsbyImage
                image={rachio38zFlat.childImageSharp.gatsbyImageData}
                alt="enclosure image"
              />
            </div>
          </div>
        </>
      )}
    />
  )
}