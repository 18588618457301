import React from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const LoadingBar = ({ style = {} }: { style?: object }) => (
  <Bar
    style={style}
    initial={{ top: -10 }}
    animate={{ top: 0 }}
    exit={{ top: -10 }}
    transition={{ damping: 10 }}
  />
)

const Bar = styled(motion.div)`
  animation: glow 5s ease infinite;
  background: linear-gradient(
    270deg,
    #1f9ed4,
    #2aadda,
    #5ad4ff,
    #6ad2ed,
    #5ad4ff,
    #2aadda,
    #1f9ed4
  );
  background-size: 200% 1rem;
  box-shadow: 0 0 0 0 white, 0 8px 18px rgba(0, 0, 0, 0.2);
  height: 1rem;
  width: 100%;
  @keyframes glow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

export const LoadingSpinner = ({
  isSmall = false,
  style = {},
}: {
  isSmall?: boolean
  style?: object
}) => (
  <Spinner isSmall={isSmall} style={style} data-testid="loading-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </Spinner>
)

const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--primaryColor);
    border-color: #cef transparent transparent transparent;
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  ${({ isSmall = false }: { isSmall?: boolean }) => {
    if (isSmall) {
      return css`
        width: var(--largeFontSize);
        height: var(--largeFontSize);
        div {
          width: var(--baseFontSize);
          height: var(--baseFontSize);
          border-width: 4px;
        }
      `
    }
  }};
`
