import { CHECKOUT_FRAGMENT } from "./fragments/checkout-fragment";
import { CHECKOUT_USER_ERROR_FRAGMENT } from "./fragments/checkout-user-error-fragment";
import { USER_ERROR_FRAGMENT } from "./fragments/user-error-fragment";

export const CHECKOUT_DISCOUNT_CODE_REMOVE_MUTATION = `
  ${USER_ERROR_FRAGMENT.fragment}
  ${CHECKOUT_USER_ERROR_FRAGMENT.fragment}
  ${CHECKOUT_FRAGMENT.fragment}

  mutation checkoutDiscountCodeRemove($checkoutId: ID!) {  
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      userErrors {
        ${USER_ERROR_FRAGMENT.ref}
      }
      checkoutUserErrors {
        ${CHECKOUT_USER_ERROR_FRAGMENT.ref}
      }
      checkout {
        ${CHECKOUT_FRAGMENT.ref}
      }
    }
  }
`