import React from 'react'
import styled from 'styled-components'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { below } from 'styles'
import { CardBase } from './CardBase'

type Props = {
  card: {
    customerType: string
    featured: string
    img: string
    name: string
    type: string
  }
  reviewsImages: {
    [key: string]: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
  }
}

export const CardReview = ({ card, reviewsImages }: Props) => {
  const { featured, img, name } = card

  return (
    <CardReviewContainer
      className="review-card"
      image={reviewsImages[img].childImageSharp.gatsbyImageData}
      alt="Customer-submitted image of their lawn"
    >
      <>
        <p className="quote">"{featured}"</p>
        <p className="no-top-margin name">{name}</p>
      </>
    </CardReviewContainer>
  )
}

const CardReviewContainer = styled(CardBase)`
  .gatsby-image-wrapper {
    width: 100%;
  }

  .quote {
    font-size: var(--largeFontSize);
    line-height: 30px;
  }
  .name {
    font-family: var(--headlineFont);
    width: 100%;
  }
  .content-section {
    ${below.small`
      .quote {
        font-size: 16px;
        line-height: 22px;
      }
    `}
  }
`
