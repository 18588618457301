import { disable } from "gatsby/dist/schema/infer/inference-metadata"
import React from "react"
import { formatVideoEmbedUrl } from "utilities"

enum VIDEO_PROVIDER {
  YOUTUBE = 'youtube'
}

interface IframeVideoProps {
  videoId: string
  videoEmbedUrl?: string
  disableControls?: boolean
  enableLoop?: boolean
  title: string
}

interface Props extends IframeVideoProps  {
  provider?: VIDEO_PROVIDER
}

const YouTubeIframeVideo = ({ videoId, videoEmbedUrl, enableLoop, title, disableControls }: IframeVideoProps) => {
  const url = new URL(videoEmbedUrl ? formatVideoEmbedUrl(videoEmbedUrl) : `https://www.youtube.com/embed/${videoId}`);
  const params = new URLSearchParams();
  params.append('rel', '0');

  if (enableLoop) {
    params.append('playlist', videoId);
    params.append('loop', '1')
  }

  if (disableControls) {
    params.append('controls', '0');
  }

  url.search = params.toString();

  return (
    <iframe
      key={videoId}
      className="video-iframe"
      id={`video-iframe-${videoId}`}
      src={url.toString()}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}


export const VideoIframe = ({ 
  provider = VIDEO_PROVIDER.YOUTUBE, 
  videoId,
  videoEmbedUrl,
  enableLoop,
  title
}: Props) => {
  if (provider === VIDEO_PROVIDER.YOUTUBE) {
    return (
      <YouTubeIframeVideo 
        videoId={videoId}
        videoEmbedUrl={videoEmbedUrl}
        enableLoop={enableLoop}
        title={title}
      />
    )
  }

  return null;
}