import React from 'react'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

type Props = {
  image: IGatsbyImageData
  alt: string
  style?: React.CSSProperties
  objectFit?: string
}

export const CustomGatsbyImage = ({
  image,
  alt,
  style,
  objectFit = 'contain',
}: Props) => {
  let customImage = {
    ...image,
  }

  customImage.images = {
    fallback: {
      sizes: '',
      src: '',
      srcSet: '',
    },
    ...customImage.images,
  }

  return (
    <GatsbyImage
      image={customImage}
      alt={alt}
      style={style}
      objectFit={objectFit}
    />
  )
}
