import React from 'react'
import styled from 'styled-components'
import { Icon } from './Icon'

export function SocialMedia() {
  return (
    <Social className="social-media">
      <a
        href="https://www.facebook.com/rachioco/"
        target="_blank"
        rel="nofollow noopener noreferrer"
        title="Rachio on Facebook (opens in a new tab)"
      >
        <Icon name="facebook" color="var(--white)" accent='var(--darkNavy)' />
      </a>

      <a
        href="https://twitter.com/rachioco/"
        target="_blank"
        rel="nofollow noopener noreferrer"
        title="Rachio on Twitter (opens in a new tab)"
      >
        <Icon name="twitter" color="var(--white)" accent='var(--darkNavy)' />
      </a>

      <a
        href="https://instagram.com/rachioco/"
        target="_blank"
        rel="nofollow noopener noreferrer"
        title="Rachio on Instagram (opens in a new tab)"
      >
        <Icon name="instagram" color="var(--white)" accent='var(--darkNavy)' />
      </a>

      <a
        href="https://www.linkedin.com/company/rachio/"
        target="_blank"
        rel="nofollow noopener noreferrer"
        title="Rachio on LinkedIn (opens in a new tab)"
      >
        <Icon name="linkedin" color="var(--white)" accent='var(--darkNavy)' />
      </a>
    </Social>
  )
}

const Social = styled.div`
  a:not(:first-child) {
    margin-left: var(--smallMargin);
  }
`
