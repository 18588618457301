import React from 'react'
import styled from 'styled-components'
import { below } from 'styles'
import { SanitySection } from './SanitySection'
import { Logo, SectionTheme } from 'types/misc'
import { CustomGatsbyImage } from 'components/elements'
import { SectionPaddingSize, SectionSize } from 'types/misc'
import Ticker from 'react-ticker'

/**
 * @prop title a string with carousel's title
 * @prop logos an array of objects with the logos
 **/

interface Props {
  title: string
  logos: Logo[]
  theme?: SectionTheme
  layout?: 'ticker' | 'static'
  size?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | '',
  displayTitle?: boolean
  imageSize?: 'small' | 'large'
}

type LogosProps = {
  logos: Logo[],
  displayTitle?: boolean,
  layout?: 'ticker' | 'static',
  imageSize?: 'small' | 'large'
}
const Logos = ({ logos, displayTitle, layout, imageSize = 'small' }: LogosProps) => (
  <LogoWrapper className={layout}>
    {logos.map((logo, idx) => {
      return (
        <a
          key={`${logo.title}-${idx}`}
          target="_blank"
          rel="nofollow noopener noreferrer"
          title={`${logo.title} (opens in a new tab)`}
          href={logo.url}
        >
          <div className={`image-container margins logo logo-${idx + 1}`}>
            <div>
              <CustomGatsbyImage
                image={imageSize === 'large' ? logo.imageLarge.asset.gatsbyImageData : logo.image.asset.gatsbyImageData}
                alt={logo.title}
              />
              {displayTitle && <span className='logo-title'>{logo.title}</span>}
            </div>
          </div>
        </a>
      )
    })}
  </LogoWrapper>
)

const LogoTicker = ({ logos, imageSize = 'small' }: { logos: Logo[], imageSize ?: 'small' | 'large' }) => {
  return (
    <TickerContainer>
      <div className="ticker">
        <Ticker>
          {() => (
            <Logos logos={logos} imageSize={imageSize} />
          )}
        </Ticker>
      </div>

      <div className="logos">
        <Logos logos={logos} imageSize={imageSize} />
      </div>
    </TickerContainer>
  )
}

const SanityCarousel = ({
  title,
  logos,
  theme = 'gray',
  layout = 'ticker',
  size = '',
  paddingTop = '',
  paddingBottom = '',
  displayTitle = false,
  imageSize = 'small'
}: Props) => {
  return (
    <SanitySection
      size={size}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      theme={theme}
      noGutters={true}
    >
      {displayTitle ? <h2 className="center-text margins bottom-margin-large">{title}</h2> : null}
      {
        !layout || layout === 'ticker'
          ? <LogoTicker logos={logos} imageSize={imageSize} />
          : <Logos logos={logos} displayTitle={displayTitle} layout={layout} imageSize={imageSize} />
      }
    </SanitySection>
  )
}

export default SanityCarousel

const LogoWrapper = styled.div`
  display: flex;
  a {
    margin: 0 var(--margin);
    color: inherit;
  }
  justify-content: space-between;
  align-items: center;
  .image-container {
    margin: 0;
    div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
  img {
    max-height: 85px;
    min-width: 200px;
  }
  &.static {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  .logo-title {
    margin: 20px auto 0;
    text-align: center;
  }

  ${below.mediumLarge`
    &.static {
      .logo-title {
        margin: 20px auto;
      }
      .image-container {
        margin-top: 40px;
      }
    }
  `}

  ${below.small`
    .image-container.logo-1 {
      margin-top: 0px;
    }
    img {
      min-width: 150px;
    }
  `}
`

const TickerContainer = styled.div`
  .ticker {
    display: none;
  }

  ${below.large`
    .ticker {
      display: block;
    }
    .logos {
      display: none;
    }
  `}
`