import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useClickOutside } from 'hooks'

type Props = {
  message: string
  position?: 'left' | 'right' | 'top' | 'bottom'
}

export function Tooltip({ message, position = 'right' }: Props) {
  const [isTooltipToggled, setIsTooltipToggled] = useState(false)
  const ref = useRef<HTMLDivElement>(null!)
  useClickOutside(ref, () => setIsTooltipToggled(false))

  if (!message) return null
  return (
    <TooltipWrapper ref={ref} position={position} className="tooltip">
      <span
        className="tooltip-icon"
        onClick={() => setIsTooltipToggled(!isTooltipToggled)}
      >
        i
      </span>

      <AnimatePresence>
        {isTooltipToggled && (
          <motion.span
            className="message"
            style={{
              pointerEvents: isTooltipToggled ? 'all' : 'none',
            }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
          >
            {message}
          </motion.span>
        )}
      </AnimatePresence>
    </TooltipWrapper>
  )
}

const TooltipWrapper = styled.span<{
  position: 'left' | 'right' | 'top' | 'bottom'
}>`
  position: relative;
  display: inline-flex;
  .tooltip-icon {
    margin-right: 0.5rem;
    line-height: 1;
    background: var(--primaryColor);
    font-weight: 500;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primaryColor);
    border-radius: 50%;
    height: 1.6rem;
    width: 1.6rem;
    font-size: var(--smallestFontSize);
    transform: translate3d(0, -2px, 0);
    cursor: pointer;
  }
  .message {
    color: var(--textColor);
    text-align: left;
    position: absolute;
    font-size: var(--smallFontSize);
    background: var(--white);
    border-radius: var(--cardRadius);
    padding: var(--cardPadding);
    min-width: 250px;
    z-index: calc(var(--headerLevel) - 1);
    box-shadow: var(--elevation-3);
    ${({ position = 'right' }) => {
      if (position === 'left')
        return css`
          top: 0;
          right: 3rem;
        `
      if (position === 'top')
        return css`
          bottom: calc(var(--smallMargin) + 100%);
          left: 50%;
          transform: translate3d(-50%, 0, 0);
        `
      if (position === 'bottom')
        return css`
          top: calc(var(--smallMargin) + 100%);
          left: 50%;
          transform: translate3d(-50%, 0, 0);
        `
      return css`
        top: 0;
        left: 3rem;
      `
    }};
  }
`
