import { Video, VideoIframe, VideoMimeType } from "components/elements";
import React from "react";
import { SanityVideo as SanityVideoProps, VIDEO_TYPE } from "types/misc";

type Props = {
  video: SanityVideoProps
}
export const SanityVideo = ({ video }: Props) => {
  if (video.videoType === VIDEO_TYPE.HOSTED) {
    return (
      <Video
        title={video.title}
        sources={[
          { 
            mimeType: VideoMimeType.MP4, 
            src: video.videoMp4Src 
          },
          { 
            mimeType: VideoMimeType.WEBM, 
            src: video.videoWebmSrc 
          }
        ]}
        posterImage={video.posterImage}
      />
    )
  }

  if (video.videoType === VIDEO_TYPE.IFRAME) {
    return (
      <VideoIframe
        videoId={video.videoId}
        enableLoop={video.enableLoop}
        title={video.title}
      />
    )
  }

  return null;
}