import React, { ReactNode } from 'react'
import { SplitSection } from './SplitSection'
import { SectionPaddingSize, SectionSize, TextAlign } from 'types/misc'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { SectionInner } from './sectionStyle'

// ? Used for sitewide heros that have a background image
// * fluid can take an array of a gradient and image path for image overlays
// * Caption and Title are required
// * Descriptions and buttons should be added in leftNode
// * Use isSplitLayout for a 2/3 (Cols) View
// * Use isTitleH2 for mid-page heros

type Props = {
  heroImage?: IGatsbyImageData
  darkness?: number
  alt?: string
  caption?: string
  title?: string
  isTitleH2?: boolean
  description?: string
  leftNode?: ReactNode
  rightNode?: ReactNode
  isSplitReverse?: boolean
  isMobileReverse?: boolean
  isSplitLayout?: boolean
  size?: SectionSize
  id?: string
  dataTestid?: string
  paddingTop?: SectionPaddingSize,
  paddingBottom?: SectionPaddingSize,
  textAlign?: TextAlign,
  contentMaxWidth?: string,
  backgroundColor?: string
}

export function Hero({
  heroImage,
  darkness = 0,
  alt = '',
  caption = '',
  title,
  isTitleH2 = false,
  description = '',
  leftNode = null,
  rightNode = null,
  isSplitReverse = false,
  isMobileReverse = false,
  isSplitLayout = false,
  paddingTop = 'small',
  paddingBottom = 'small',
  size = 'medium',
  id = '',
  dataTestid = '',
  contentMaxWidth = '768px',
  textAlign = 'center',
  backgroundColor = ''
}: Props) {
  return (
    <div style={{ position: 'relative', backgroundColor: backgroundColor || 'transparent' }} data-testid={dataTestid} id={id}>
      {
        !backgroundColor
          ? (
            <GatsbyImage
              style={{ position: 'absolute', height: '100%', width: '100%' }}
              alt={alt || String(title)}
              image={heroImage as IGatsbyImageData}
            />
          )
          : null
      }

      <div
        style={{
          position: 'inherit',
          background: `linear-gradient(90.12deg, rgba(2,2,2,${darkness}) 40%, rgba(0, 0, 0, 0) 100%)`,
        }}
      >
        <SplitSection
          isSplitLayout={isSplitLayout}
          isSplitReverse={isSplitReverse}
          isMobileReverse={isMobileReverse}
          size={size}
          paddingTop={paddingTop || 'small'}
          paddingBottom={paddingBottom || 'small'}
          leftNode={
            <SectionInner textAlign={textAlign} maxWidth={contentMaxWidth}>
              {caption && <h6 className="white-text">{caption}</h6>}
              {isTitleH2 ? (
                <h2 className={`${caption ? 'no-margins' : ''} white-text`}>
                  {title}
                </h2>
              ) : (
                <h1 className={`${caption ? 'no-margins' : ''} white-text`}>
                  {title}
                </h1>
              )}
              {description && <p className="white-text no-bottom-margin large">{description}</p>}
              {leftNode}
            </SectionInner>
          }
          rightNode={rightNode}
        />
      </div>
    </div>
  )
}
