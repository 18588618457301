import React, { ReactNode } from 'react'
import { Wrapper } from 'styles'
import { SectionTheme, SectionPaddingSize, SectionSize } from 'types/misc'
import { SectionOuter } from './SanitySectionStyles'

// ? Used for sections with standardized section padding

/**
 * @prop theme takes an string with the value of the background
 * @prop children React Node inside ContenfulSection
 * @prop width content width inside the section
 * @prop size a string with the padding size
 * @prop paddingTop a string with the padding top size
 * @prop paddingBottom a string with the padding bottom size
 **/

type Props = {
  theme?: SectionTheme | ''
  children: ReactNode
  id?: string
  width?: 'tight' | string // Max content width in px
  size?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
  noGutters?: boolean // removes left and right gutters
}

export function SanitySection({
  children,
  theme = '',
  id = '',
  width = '',
  size = '',
  paddingTop = '',
  paddingBottom = '',
  noGutters = false,
}: Props) {
  return (
    <SectionOuter
      id={id}
      className={`Section ${theme ? `Section--theme__${theme}` : ''}
    `}
    >
      <Wrapper width={width} padding={noGutters ? '0' : undefined}>
        <div
          className={`Section--padding
          ${size ? `Section--padding__${size}` : ''}
          ${paddingTop ? `Section--padding-top__${paddingTop}` : ''}
          ${paddingBottom ? `Section--padding-bottom__${paddingBottom}` : ''}
          `}
        >
          {children}
        </div>
      </Wrapper>
    </SectionOuter>
  )
}
