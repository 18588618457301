import React, { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  B1isSelected: boolean
  B2isSelected: boolean
  B1Callback: () => void
  B2Callback: () => void
  B1Content: ReactNode
  B2Content: ReactNode
}

export const ProductButtons = ({
  B1isSelected,
  B2isSelected,
  B1Callback,
  B2Callback,
  B1Content,
  B2Content,
}: Props) => {
  return (
    <InputButtons>
      <button
        onClick={() => B1Callback()}
        className={B1isSelected ? 'selected' : ''}
        data-testid="2-bottles-btn"
      >
        {B1Content}
      </button>
      <button
        onClick={() => B2Callback()}
        className={B2isSelected ? 'selected' : ''}
        data-testid="4-bottles-btn"
      >
        {B2Content}
      </button>
    </InputButtons>
  )
}

const InputButtons = styled.div`
  display: flex;
  button {
    width: 100%;
    border-radius: var(--inputRadius);
    border: 1px solid var(--lineColor);
    padding: calc(var(--basePadding) / 2) 0;
  }
  button.selected {
    border: 1px solid var(--primaryColor);
  }
`
