import { useEffect } from 'react'
declare var window: any

export const useYotpoReviews = () => {
  useEffect(() => {
    const reviewsScript = document.getElementById('yotpo-script-handler')
    if (!reviewsScript) {
      const script = document.createElement('script')
      script.id = 'yotpo-script-handler'
      script.setAttribute(
        'src',
        `//staticw2.yotpo.com/${process.env.GATSBY_YOTPO_API_KEY}/widget.js`
      )
      document['body'].appendChild(script)
    } else {
      const api = new window.Yotpo.API(window.yotpo)
      api.refreshWidgets()
    }
  }, [])
}
