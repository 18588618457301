import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Grid, Cols, below } from 'styles'
import { Section } from './Section'
import { SectionTheme, SectionPaddingSize, SectionSize } from 'types/misc'

// ? Used for sections that have a split layout (1/2 or 2/3)
// * Layout becomes 1 column on mobile
// * Use isSplitLayout for a 2/3 (Cols) View
// * isSplitReverse flips the Cols view aside to the left

type Props = {
  theme?: SectionTheme | ''
  leftNode?: ReactNode
  rightNode?: ReactNode
  isSplitReverse?: boolean
  isMobileReverse?: boolean
  isSplitLayout?: boolean
  size?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
  id?: string
}

export function SplitSection({
  theme = '',
  leftNode = null,
  rightNode = null,
  isSplitReverse = false,
  isMobileReverse = false,
  isSplitLayout = false,
  size = '',
  paddingTop = '',
  paddingBottom = '',
  id = '',
}: Props) {
  const Content = (
    <>
      <SectionLeft
        className={`Section--left ${
          isMobileReverse ? 'Section--left__mobile-reverse' : ''
        }`}
      >
        {leftNode}
      </SectionLeft>
      <SectionRight className="Section--right">{rightNode}</SectionRight>
    </>
  )

  return (
    <Section
      theme={theme}
      id={id}
      size={size}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      {isSplitLayout ? (
        <Cols isReverse={isSplitReverse}>{Content}</Cols>
      ) : (
        <Grid cols={rightNode ? [1, 1, 2] : 1}>{Content}</Grid>
      )}
    </Section>
  )
}

const SectionLeft = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  ${below.medium`
    &.Section--left__mobile-reverse {
      grid-row: 2;
    }
  `};
`

const SectionRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`
