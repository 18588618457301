export const fetchRachioEmailForTracking = async (id) => {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      id
    }),
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    }
  }

  const endpoint = `${process.env.GATSBY_RETAIL_API_URL}/tracking/get-email-for-tracking`;

  const res = await fetch(endpoint, config);

  if (!res.ok) return; 

  const data = await res.json();

  return data.emailAddress;
}