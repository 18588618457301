import ThumbnailNavigationSection, { ThumbnailNavigationItem } from "components/sections/ThumbnailNavigationSection";
import React from "react";
import styled from "styled-components";
import { below } from "styles";
import { ArticleTopic } from "types/misc";

type Props = {
  header?: string
  description?: string
  topics: ArticleTopic[],
  activeTopicIndex?: number
}
export const SanityArticleTopicNavigation = ({ topics, activeTopicIndex, header, description }: Props) => {
  const thumbnailNavigationItems: ThumbnailNavigationItem[] = topics.map(item => ({
    url: item.slug !== '/blog/' ? `/blog/topics/${item.slug}` : item.slug,
    image: item.thumbnailImage,
    alt: `article topic ${item.thumbnailAlt}`,
    id: item.id,
    title: item.title
  }))

  return (
    <Container>
      <ThumbnailNavigationSection
        id="most-popular-topics"
        header={header}
        description={description}
        thumbnailNavigationItems={thumbnailNavigationItems}
        activeThumbnailIndex={activeTopicIndex}
      />
    </Container>
  )
}

const Container = styled.div`
  .header {
    display: flex;
    align-items: center;
    p {
      line-height: 18px;
    }
    h2 {
      position: relative;
      padding-right: 15px; 
      margin-right: 10px !important;
      &:after {
        position: absolute;
        content: " ";
        top: 10%;
        right: 0;
        width: 4px;
        height: 80%;
        background: var(--lightGray);
      }
    }
  }

  ${below.small`
    .thumbnail-navigation-items {
      padding-top: 20px;
    }

    .header {
      p {
        font-size: 14px;
      }
    }
  `}
`