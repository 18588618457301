import {
  PARTNER_PRODUCT_HANDLES,
  PARTNER_PRODUCT_VARIANTS_BY_TITLE,
  PARTNER_CONTROLLER_PRODUCT_SKUS
} from '../../../common/config/products';
import { ShopifyCheckout, ShopifyCheckoutLineItem, ShopifyCollection, ShopifyProduct, ShopifyProductVariant } from './storefront-api-client/types/custom.types';
import { Checkout, CheckoutLineItem, DiscountCodeApplication, Image } from './storefront-api-client/types/storefront.types';

export const filterCollectionProductVariantsByTitle = (collection: ShopifyCollection, titleFilter = PARTNER_PRODUCT_VARIANTS_BY_TITLE.WEATHERFLOW_TEMPEST.BUNDLE) => {
  const formattedCollection = {...collection};
  const formattedProducts: ShopifyProduct[] = [];

  for (const product of formattedCollection.products) {
    if (PARTNER_PRODUCT_HANDLES.WEATHERFLOW_TEMPEST === product.handle) {
      const formatProduct = {...product}


      formatProduct.variants = product.variants.filter(v => v.title !== titleFilter)

      formattedProducts.push(formatProduct)
    } else {
      formattedProducts.push(product);
    }
  }

  formattedCollection.products = formattedProducts;
  return formattedCollection;
}

export const filterVariantsBySku = (variants: Partial<ShopifyProductVariant> & { sku: string }[], filterSku?: string): number => {
  if (filterSku) {
    const index = variants.findIndex((v) => v.sku === filterSku);
    if (index === -1) return 0;
    return index
  }

  return 0;
}

export const filterVariantForWFT = (variants: ShopifyProductVariant[]) => {
  return variants.filter(v => v.sku !== PARTNER_CONTROLLER_PRODUCT_SKUS.WEATHERFLOW_TEMPEST_BUNDLE)
}

export type ProductLike = {
  variants: ShopifyProductVariant[]
}
export const flattenProductVariants = (products: ProductLike[], onlyAvailableVariants?: boolean) => (
  products.reduce((prev, current) => {
    if (onlyAvailableVariants) {
      current.variants = current.variants.filter(v => v.availableForSale);
    }
    if (current === null) return prev;
    return [...prev, ...current.variants]
  }, [] as ShopifyProductVariant[])
)

export const resolveProduct = ({ node }) => {
  return {
    ...node,
    images: mapEdgeNodes<Image>(node.images),
    variants: mapEdgeNodes<ShopifyProductVariant>(node.variants),
    media: mapEdgeNodes(node.media as any) as any[],
  }
}

type ResolveProductsArgs = { 
  edges: { 
    node: ShopifyProduct 
  }[]
}
export const resolveProducts = (productEdges: ResolveProductsArgs) => {
  return productEdges.edges.map(productEdge => resolveProduct(productEdge))
}

export const resolveCheckout = (checkout: Checkout): ShopifyCheckout => {
  return {
    ...checkout,
    lineItems: mapEdgeNodes<CheckoutLineItem, ShopifyCheckoutLineItem>(checkout.lineItems) as ShopifyCheckoutLineItem[],
    discountApplications: mapEdgeNodes(checkout.discountApplications as any) as DiscountCodeApplication[],
  }
}


function mapEdgeNodes<T, K = void>(edgeConnection: { edges: { node: T }[]}): K|T[] {
  return edgeConnection.edges.map(({ node }) => node)
}