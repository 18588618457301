import { MoneyV2 } from "lib/shopify/storefront-api-client/types/storefront.types";
import React from "react";
import styled from "styled-components";

const appendScript = (onLoaded: () => void) => {
  window._affirm_config = {
    public_api_key: process.env.GATSBY_AFFIRM_API_KEY,
    script: "https://cdn1-sandbox.affirm.com/js/v2/affirm.js"
  };

  const script = document.createElement('script')
  script.id = 'affirm-script'
  script.setAttribute(
    'src',
    `https://cdn1-sandbox.affirm.com/js/v2/affirm.js`
  )
  script.innerHTML = `
    (function(m,g,n,d,a,e,h,c){var b=m[n]||{},k=document.createElement(e),p=document.getElementsByTagName(e)[0],l=function(a,b,c){return function(){a[b]._.push([c,arguments])}};b[d]=l(b,d,"set");var f=b[d];b[a]={};b[a]._=[];f._=[];b._=[];b[a][h]=l(b,a,h);b[c]=function(){b._.push([h,arguments])};a=0;for(c="set add save post open empty reset on off trigger ready setProduct".split(" ");a<c.length;a++)f[c[a]]=l(b,d,c[a]);a=0;for(c=["get","token","url","items"];a<c.length;a++)f[c[a]]=function(){};k.async=!0;k.src=g[e];p.parentNode.insertBefore(k,p);delete g[e];f(g);m[n]=b})(window,affirm_config,"affirm","checkout","ui","script","ready","jsReady");
  `

  script.onload = function() {
    const timeout = setTimeout(function waitUntilJSReady() {
      if (affirm?.jsReady()) {
        clearTimeout(timeout);
        onLoaded();
      } else {
        setTimeout(waitUntilJSReady, 500);
      }
    }, 500);
  };

  document['body'].appendChild(script)
}

enum STATUS {
  READY = "READY",
  NOT_READY = "NOT_READY"
}

type VariantLike = {
  price: MoneyV2
  sku: string
}

type Props = {
  variant: VariantLike
}
const Affirm = ({ variant }: Props) => {
  const [affirmComponent, setAffirmComponent] = React.useState(null);
  const [status, setStatus] = React.useState<STATUS>(STATUS.NOT_READY);

  React.useLayoutEffect(() => {
    const affirmScript = document.getElementById('affirm-script');

    if (!affirmScript) {
      appendScript(() => {
        setStatus(STATUS.READY);
      });
    } else {
      setStatus(STATUS.READY);
    }
  }, []);

  const getOptions = ({ price, sku }: VariantLike) => ({
    amount: Number(price.amount) * 100,
    affirmLogoType: 'logo',
    pageType: 'product',
    sku,
    learnMoreShow: true,
  })

  React.useEffect(() => {
    if (status === STATUS.READY) {
      const options = getOptions(variant);
      const promoComponent = window?.affirm?.ui?.components?.create('promo', options);
      setAffirmComponent(promoComponent)
      promoComponent.render(".affirm-promo-msg")
    }
  }, [status]);

  React.useEffect(() => {
    if (affirmComponent) {
      affirmComponent?.update(
        getOptions(variant)
      )
    }
  }, [variant, affirmComponent])

  return (
    <Wrapper>
      <div className="affirm-promo-msg"></div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: var(--largeMargin);
  display: flex;
    justify-content: center;

    .affirm-ala-price {
      font-weight: bold;
      color: var(--primaryBlue);
      margin-left: 3px;
    }

    .affirm-modal-trigger {
      display: none;
    }
`

export default Affirm;