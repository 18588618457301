import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Title, CollectionCardContainer } from './CollectionCard'
import { ImageSimple } from 'types/misc'
import { Price } from 'components/product'
import { createAddonSelectionMap, deriveDiscountAmount, fetchDeal, PICKYSTORY_DEAL_TYPE, PickyStoryClient, PickyStoryDealTypes, PickyStoryProduct } from '../../lib/pickystory';
import { PARTNER_CONTROLLER_PRODUCT_SKUS  } from "../../../common/config/products";

type HeaderProps = {
  seoPathname: string,
  title: string
  image: ImageSimple,
  imageAlt: string
}

const Header = ({ seoPathname, title, image, imageAlt }: HeaderProps) => {
  return (
    <>
      <div className="product-image-wrapper">
        <Link to={seoPathname}>
          <GatsbyImage
            image={image.asset.gatsbyImageData}
            alt={imageAlt || ""}
            objectFit="contain"
          />
        </Link>
      </div>

      <Title>
        <Link to={seoPathname}>
          <div className="product-title-wrapper">
            <h2 className="product-title no-margins">
              {title}
            </h2>
          </div>
        </Link>
      </Title>
    </>
  )
}

type Props = {
  image: ImageSimple
  imageAlt: string
  title: string
  shortDescription: string
  id: string
  seoPathname: string
  dealId: string
  client: PickyStoryClient
  bundleType: PickyStoryDealTypes
}
export function BundleCollectionCard({
  image,
  imageAlt,
  title,
  shortDescription,
  id,
  seoPathname,
  dealId,
  bundleType,
  client
}: Props) {
  const deal = fetchDeal(client, bundleType, dealId, false);

  const subTotal = (() => {
    if (!deal) return 0;
    const filterOutWFT = (p: PickyStoryProduct) => ({
      ...p,
      variants: p.variants.filter(variant =>
        variant.sku !== PARTNER_CONTROLLER_PRODUCT_SKUS.WEATHERFLOW_TEMPEST_SINGLE_PURCHASE
      ).sort((v1, v2) => v1.position > v2.position ? 1 : -1)
    })
    const productsSortedVariants = deal.products
      .sort((v1, v2) => v1.position > v2.position ? 1 : -1)
      .map(filterOutWFT)
      .map(p => p.variants[0]);

    const total = productsSortedVariants.reduce((prev, cur) => prev + cur.price, 0);

    if (deal.addonProducts.length) {
      const addonProductsSortedVariants = deal.addonProducts
        .map(filterOutWFT)
        .sort((v1, v2) => v1.position > v2.position ? 1 : -1)
        .map(p => p.variants[0])

      return addonProductsSortedVariants
        .reduce((prev, cur) => prev + cur.price, total);
    }

    return total;
  })()

  const discountAmount = (() => {
    if (!deal) return 0;

    const addonSelectionMap = deal.addonProducts.length
      ? createAddonSelectionMap(deal.addonProducts)
      : null;

    return deriveDiscountAmount(deal, subTotal, addonSelectionMap);
  })()

  return (
    <CollectionCardContainer data-testid="product-preview" className="product-preview" id={id}>
      <div className="product-wrapper">
        <div>
          <Header
            seoPathname={seoPathname}
            title={title}
            image={image}
            imageAlt={imageAlt}
          />

          <p className="product-description">{shortDescription}</p>
        </div>
        {deal && bundleType !== PICKYSTORY_DEAL_TYPE.BUILDERS && (
          <div className='pricing'>
            <span>From</span>
            <Price
              forceShow={true}
              basePrice={subTotal}
              discountAmount={discountAmount}
              type='tiny'
            />
          </div>
        )}
      </div>
    </CollectionCardContainer>
  )
}