import { resolveProduct } from "lib/shopify/transformations";
import { storefrontAPIClient } from "../client";
import { PRODUCT_RECOMMENDATIONS_QUERY } from "../graphql";
import { Product, ProductRecommendationIntent } from "../types/storefront.types";

export const getRecommendedProducts = async (
  productId: string, 
  intent: ProductRecommendationIntent.Related
): Promise<Product[] | null> => {
  const { data, errors } = await storefrontAPIClient.request<{ productRecommendations: Product[] }>(
    PRODUCT_RECOMMENDATIONS_QUERY, 
    { 
      variables: {
        productId,
        intent 
      }
    }
  );

  if (errors) throw new Error(errors.message);

  if (!data?.productRecommendations) return null;

  return data.productRecommendations.map(product => resolveProduct({ node: product }))
}