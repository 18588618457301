import React from 'react'
import styled from 'styled-components'
import { below } from 'styles'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

type Props = {
  image1: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  image2: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

export const SplitImages = ({ image1, image2 }: Props) => {
  return (
    <SplitImagesCSS>
      <div className="img-container first-image">
        <GatsbyImage
          image={image1.gatsbyImageData}
          alt="Irrigations zones as shown in the Rachio app"
          objectFit="contain"
        />
      </div>
      <div className="img-container second-image">
        <GatsbyImage
          image={image2.gatsbyImageData}
          alt="Zone detail screen in Rachio app"
          objectFit="contain"
        />
      </div>
    </SplitImagesCSS>
  )
}

const SplitImagesCSS = styled.div`
  display: flex;
  .img-container {
    width: 100%;
    margin: 0 10px;
  }
  ${below.medium`
    height: 1300px;
    position: relative;
    .img-container {
        width: 65%;
        position:absolute;
    }
    .first-image{
        right: 0;
    }
    .second-image{
        z-index:-1;
        top:10%;
    }
 `}
  ${below.small`
    height: 800px;
    
 `}
 ${below.xSmall`
    height: 550px;
 `}

 @media only screen and (max-width: 300px) {
    height: 350px;
  }
`
