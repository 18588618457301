import { useCheckout, useNotification } from "global"
import { ShopifyProductVariant } from "lib/shopify/storefront-api-client/types/custom.types"
import { useEffect, useState } from "react"

enum ADD_TO_CART_STATES {
  IDLE = "IDLE",
  ADDED = "ADDED",
}

enum ADD_TO_CART_BUTTON_TEXT {
  ADD_TO_CART = "Add to Cart",
  ADDED_TO_CART = "Added",
  GO_TO_CART = "Go to Cart"
}

type Props = {
  selectedVariant?: ShopifyProductVariant,
  product: {
    title: string
  }
  customButtonText?: string,
  customButtonTheme?: string,
  noRedirectToCart?: boolean,
  goToCheckout?: boolean
}

export const useAddToCart = ({ 
  customButtonText, 
  customButtonTheme, 
  noRedirectToCart, 
  selectedVariant,
  product, 
  goToCheckout,
}: Props) => {
  const { addVariantsToCart, openCart, addVariantsToCheckout } = useCheckout();
  const { setNotification } = useNotification();
  const [addToCartState, setAddToCartState] = useState(ADD_TO_CART_STATES.IDLE);
  const [buttonTextState, setButtonTextState] = useState(customButtonText || ADD_TO_CART_BUTTON_TEXT.ADD_TO_CART);
  const [buttonThemeState, setButtonThemeState] = useState(customButtonTheme || 'dark');
  const [currentQuantity, setCurrentQuantity] = useState(1); 

  useEffect(() => {
    if (addToCartState === ADD_TO_CART_STATES.ADDED && noRedirectToCart) {
      setButtonTextState(ADD_TO_CART_BUTTON_TEXT.ADDED_TO_CART)
      setButtonThemeState('green')
      const timeout = setTimeout(() => {
        setButtonTextState(ADD_TO_CART_BUTTON_TEXT.GO_TO_CART)
        setButtonThemeState('blue')
      }, 2000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [addToCartState])

  const addToCart = async () => {
    if (!selectedVariant) return;

    if (addToCartState === ADD_TO_CART_STATES.ADDED && !goToCheckout) {
      openCart()
      return;
    }

    const lineItemsToAdd = [
      {
        variantId: String(selectedVariant.id),
        quantity: currentQuantity,
        name: selectedVariant.title,
        price: selectedVariant.price.amount,
        sku: selectedVariant.sku,
      }
    ];

    if (goToCheckout) {
      return addVariantsToCheckout(lineItemsToAdd)
    }

    await addVariantsToCart(lineItemsToAdd, noRedirectToCart)

    if (Boolean(noRedirectToCart)) {
      setNotification({
        message: `${product.title} (${selectedVariant.title}) added to cart.`,
        type: 'cart',
        link: '/cart/',
        linkText: 'Go to Cart.'
      })
    }

    setAddToCartState(ADD_TO_CART_STATES.ADDED);
  }

  return {
    addToCart,
    addToCartState,
    buttonText: buttonTextState,
    buttonTheme: buttonThemeState,
    currentQuantity,
    setCurrentQuantity
  }
}