import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { Button } from '../elements'
import { isValidEmail } from 'utilities'
import { API_ERROR, EMAIL_ERROR } from 'data'
import { below } from 'styles'

type Props = {
  onSubmit: (email: string) => Promise<void>
  hasSuccess?: boolean
  hasTracking?: boolean
  id?: string
  confirmationText ?: string
}

export function EmailInput({
  onSubmit,
  hasSuccess = true,
  id = '',
  confirmationText = '',
}: Props) {
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [isInputShowing, setIsInputShowing] = useState(true)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!isValidEmail(email)) {
      return setError(EMAIL_ERROR)
    }

    try {
      await onSubmit(email)

      if (hasSuccess) {
        setIsInputShowing(false)
      }
      setError('')
    } catch (e) {
      setError(API_ERROR)
    }
  }

  return (
    <AnimatePresence>
      {isInputShowing ? (
        <Form onSubmit={handleSubmit} method="post">
          <label>
            <span className="visually-hidden">Email Address</span>
            <input
              name="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              id="email"
              required
              data-testid="email-input"
            />
            {error && (
              <p className="error-text center-text">
                {error}
              </p>
            )}
          </label>
          <Button type="submit" id={id} data-testid="email-submit" className='btn-email-submit'>
            Subscribe
          </Button>
        </Form>
      ) : (
        <motion.h4
          initial={{ x: 50 }}
          animate={{ x: 0 }}
          exit={{ x: 50 }}
          transition={{ duration: 0.3 }}
          className="center-text"
          style={{ marginTop: 'var(--margin' }}
        >
          {confirmationText || 'Thanks for subscribing!'}
        </motion.h4>
      )}
    </AnimatePresence>
  )
}

const Form = styled.form`
  display: flex;
  padding-left: 2px; // Fixes firefox outline bug
  overflow: hidden;
  label {
    width: 100%;
    display: inline-block;
    margin: 0;
  }
  input {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 1.3rem 2rem;
  }
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 60px;
    height: 56px;
    margin: 1.5rem 0 0;
  }

  ${below.small`
    display: block;
    button, input {
      border-radius: 50px;
    }

    button {
      width: 100%;
    }
  `}
`
