import React from "react";
import styled from "styled-components";
import { below, Grid } from "styles";

type ListItem = {
  title: string,
  description: string
}

type Props = {
  listItems: ListItem[],
  layout?: 'stacked' | 'grid'
}

const OrderedListSection = ({ listItems, layout = 'grid' }: Props) => {
  return (
    <ListContainer>
      {layout === 'grid'
        ? (
          <Grid as="ol" cols={[1, 1, 3]}>
            {listItems.map((item, index) => (
              <li key={`list-item-${item.title}`}>
                <span className="no-top-margin">{index + 1}</span>
                <h4>{item.title}</h4>
                <p>{item.description}</p>
              </li>
            ))}
          </Grid>
        )
        : (
          <ol className="list-stacked">
            {listItems.map((item, index) => (
              <li key={`list-item-${item.title}`}>
                <span>{index + 1}</span>
                <div>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              </li>
            ))}
          </ol>
        ) }
    </ListContainer>
  )
}

const ListContainer = styled.div`
  h4 {
    margin-top: var(--margin);
  }
  ol {
    list-style: none;
    text-align: center;
    span {
      background: var(--primaryColor);
      border-radius: 50%;
      padding: 2rem;
      font-size: var(--largeFontSize);
      color: var(--white);
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: var(--largeMargin) auto 0;
    }

    p {
      max-width: 480px;
      margin: 0 auto;
    }
  }

  ol.list-stacked {
    li {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin: 40px auto;
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    div {
      text-align: left;
    }

    p, span, h4 {
      margin: 0;
    }

    p {
      width: auto;
      line-height: 20px;
    }

    span {
      margin-right: 20px;
    }
  }

  ${below.medium`
    ol {
      span:first-child {
        margin-top: 0;
      }
    }
  `}
`

export default OrderedListSection;