import { CollectionCardContainer } from "components/collection"
import styled from "styled-components"
import { below } from "styles"

export const BundleCollectionCard = styled(CollectionCardContainer)<{ hidePrice: boolean }>`
  .variant-select-input {
    margin-bottom: 0;
  }

  .product-single-variant {
    border: none;
  }

  .variant-text {
    font-size: 16px;
  }

  .pricing > div {
    width: 100%;
  }

  .product-selection-wrapper.quantity-selection-enabled {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .variant-selector-container,
    .quanity-selector-container {
      width: 100%;
    }
    
    select {
      min-height: 40px;
      font-size: 1.4rem;
      padding: 5px 30px 5px 14px;
    }

    .quantity-input-wrapper {
      margin-bottom: 0;

      .quantity-title {
        display: none;
      }

      button {
        margin-top: 0;
        padding: inherit;
      }

      .quantity {
        margin-right: 10px;
        padding: 3px 10px;
        font-size: 1.5rem;
      }
    }

    button {
      padding: 5px 14px 5px 14px;
    }
  }

  ${below.small`
    .product-wrapper .product-description, .product-title-wrapper {
      min-height: auto;
    }

    .product-single-variant {
      display: none;
    }

    .product-selection-wrapper.quantity-selection-enabled {
      display: block;
      h2.product-title {
        font-size: 1.4rem;
      }

      select {
        font-size: 1.2rem;
        min-height: initial;
      }
    }
  `}

  ${({ hidePrice }) => {
    if (!hidePrice) {
      return `
        .product-single-variant {
          position: relative;
          top: 15px;
          padding-bottom: 20px;
        }
        .variant-text {
          display: none;
        }
      `
    } else {
      return `
        .product-single-variant {
          position: relative;
          top: 10px;
          padding: 1.5rem 2rem;
        }
      `
    }
  }}
`