import React, { useState } from 'react'
import styled from 'styled-components'
import { Wrapper, below } from 'styles'
import { SanityArticleCard } from 'components/elements'
import { Button } from 'components/elements'
import { Article, ArticleTopic } from 'types/misc'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { BLOG_ARTICLE_LIST_LENGTH } from 'utilities'

type Props = {
  articles: Article[]
  allSanityArticle: {
    nodes: Article[]
  }
  logoRachio: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  currentTopic?: ArticleTopic

  setArticles: (articles: Article[]) => void
}

export const SanityBlogCards = ({
  articles,
  setArticles,
  allSanityArticle,
  logoRachio,
  currentTopic
}: Props) => {
  const [disabled, setDisabled] = useState(false)
  const loadMoreArticles = () => {
    const newArticles = allSanityArticle.nodes.slice(0, articles.length + BLOG_ARTICLE_LIST_LENGTH)
    setArticles(newArticles)

    if (newArticles.length == allSanityArticle.nodes.length) {
      setDisabled(true)
    }
  }
  return (
    <BlogCardsWrapper>
      <h2 className="no-top-margin articles-headline">
        {currentTopic && (
          <>
            {currentTopic.title}
            {" "}
          </>
        )}
        Articles
      </h2>
      <BlogCardsContainer>
        {articles.map((article) => {
          return (
            <SanityArticleCard
              key={article.id}
              article={article}
              logoRachio={logoRachio}
            />
          )
        })}
      </BlogCardsContainer>
      
      {allSanityArticle.nodes.length > BLOG_ARTICLE_LIST_LENGTH && (
        <ButtonContainer>
          <Button
            style={{ display: disabled ? 'none' : 'inline-block' }}
            onClick={loadMoreArticles}
          >
            <span className="view-more">View More</span>
          </Button>
        </ButtonContainer>
      )}
    </BlogCardsWrapper>
  )
}
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: var(--margin) 0;
`

const BlogCardsWrapper = styled(Wrapper)`
  padding-top: calc(var(--basePadding) * 2);
  padding-bottom: calc(var(--basePadding) * 2);

  .articles-headline {
    margin-bottom: 1.5em;
    ${below.small`
      margin-bottom: 1em;
    `}
  }
`
const BlogCardsContainer = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  margin: var(--margin) 0;
  ${below.large`
    grid-template-columns: repeat(3, 1fr);
  `};
  ${below.mediumLarge`
    grid-gap: 10px;
  `};
  ${below.medium`
    grid-template-columns: repeat(2, 1fr);
  `};
  ${below.small`
    gap: var(--gridGap);
    grid-template-columns: repeat(1, 1fr);
  `};
`
