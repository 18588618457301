import React, { FC, ReactNode } from 'react'
import { PickyStoryProvider } from '../lib/pickystory';
import { ProviderComposer } from './state';

export const ProductContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ProviderComposer
      contexts={[
        <PickyStoryProvider />
      ]}
    >
      {children}
    </ProviderComposer>
  )
}
