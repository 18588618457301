import { useState, useEffect } from 'react'
import { isBrowser } from 'utilities'

type PropsValidation = {
  value: any
  expires?: number
}
const validateLocalStorageValue = ({
  value,
  expires,
}: PropsValidation): string => {
  return expires && expires < new Date().getTime() ? '' : value
}

type Props = {
  key: string
  expires?: number // date in milliseconds
}

export function useStorage({
  key,
  expires = 0,
}: Props): [
  any, 
  (value: any, expiration?: string | number) => void, 
] {
  const initialLocalStorage = isBrowser ? localStorage.getItem(key) : ''
  const initial = initialLocalStorage
    ? validateLocalStorageValue(JSON.parse(initialLocalStorage))
    : ''
  const initialExpiration = initialLocalStorage && initial 
    ? JSON.parse(initialLocalStorage).expires 
    : expires

  const [value, setValue] = useState<any>(initial)
  const [expiration, setExpiration] = useState(initialExpiration);

  const setState = (value: any, expiration?: string | number) => {
    if (expiration) {
      setExpiration(expiration)
    }

    setValue(value);
  }

  useEffect(() => {
    if (value) {
      const localObject: string = JSON.stringify({
        value,
        expires: expiration || null,
      })

      localStorage.setItem(key, localObject)
    }
  }, [value, key, expiration])

  return [value, setState]
}
