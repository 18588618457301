import { Link } from "gatsby";
import React from "react";
type Props = {
  link: string,
  buttonText?: string,
  text: string
}
export const UpsellLink = ({ link, buttonText, text }: Props) => {
  return (
    <div className='bottom-margin center-text'>
      <span>
        {text}{" "}
        <Link
          to={link}
          style={{ color: 'var(--accentColor)' }}
          className="underline"
        >
          {buttonText || 'Learn More'}
        </Link>
      </span>
    </div>
  )
}