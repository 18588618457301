import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { ArticleTopic } from "types/misc";

type Props = {
  topic: ArticleTopic,
  className?: string
  style?: React.CSSProperties
}
export const SanityArticleTopic = ({ topic, style, className }: Props) => {
  return (
    <TopicTag style={style} className={className}>
      <Link to={`/blog/topics/${topic.slug}/`}>{topic.title}</Link>
    </TopicTag>
  )
}

const TopicTag = styled.span`
  height: 24px;
  display: flex;
  align-items: center;
  transition: var(--transition);
  background: var(--primaryColor);
  padding: 2px 10px 0px;
  border-radius: 14px;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
    background: var(--accentColor);
    a {
      color: white;
    }
  }

  a {
    line-height: 0;
    color: white;
    font-size: 10px;
    letter-spacing: .5px;
  }
`