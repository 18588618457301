import styled from 'styled-components'

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 2px solid var(--darkGray);
  border-radius: 50px;
  padding: 0.5rem;
  display: inline-block;
  position: relative;
  height: 2.4rem;
  width: 2.4rem;
  min-height: 2.4rem;
  min-width: 2.4rem;
  margin-top: 0.5rem;
  background: transparent;
  &:hover {
    cursor: pointer;
  }
  &:checked {
    background: var(--primaryColor);
    border: 2px solid var(--primaryColor);

    &:after {
      content: '\\2713';
      font-size: 1.6rem;
      position: absolute;
      top: -3px;
      left: 3px;
      color: var(--white);
    }
  }
`
