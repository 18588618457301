import { Modal, Rebate } from "components/elements";
import { IpGeolocationContext } from "global";
import React from "react";
import { useEcoRebates } from "../../../lib/ecorebates";
import { ShopifyProduct } from "lib/shopify/storefront-api-client/types/custom.types";

type Props = {
  product: ShopifyProduct
}

const TotalRebatesText = ({ totalRebates, onClick }: { totalRebates: number, onClick: () => void }) => {
  return <span>{' '}There are {totalRebates} rebates available in your area, <a href="#" onClick={(e) => { e.preventDefault(); onClick(); }}>click here</a> to learn more.</span>
}

const DefaultRebatesText = ({ onClick }: { onClick: () => void }) => (
  <span>{' '}Rebates may also be available in your area, <a href="#" onClick={(e) => { e.preventDefault(); onClick(); }}>click here</a> to learn more.</span>
)

export const RebatesText = ({ product }: Props) => {
  const [modalActive, setModalActive] = React.useState(false);
  const displayModal = () => setModalActive(true)
  const variantSkus = product.variants.map((variant) => variant.sku);
  const { geolocationData } = React.useContext(IpGeolocationContext);
  const { totalRebates } = useEcoRebates(Number(geolocationData.postal_code), variantSkus);

  return (
    <>
      <p>
        The Rachio 3 comes with a free two-year limited warranty and customer
        service that can help answer any of your questions.
        {
          totalRebates > 0
            ? <TotalRebatesText totalRebates={totalRebates} onClick={displayModal} />
            : <DefaultRebatesText onClick={displayModal} />
        }
      </p>
     <Modal
        maxWidth="60%"
        isActive={modalActive}
        closeAction={() => setModalActive(false)}
      >
        {<Rebate />}
      </Modal>
    </>
  )
}