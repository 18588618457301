import { EcoRebatesResponse } from "./types";

const BASE_URL = 'https://rachio.ecorebates.com/api';

export const fetchRebates = async (zipCode: number, skus: string[]): Promise<EcoRebatesResponse> => {
  const endpoint = `${BASE_URL}/search/rachio/productRebateDetails.json?skus=${skus.join(',')}&zip=${zipCode}`;

  const res = await fetch(endpoint);

  if (!res.ok) {
    throw new Error("Could not fetch EcoRebates rebates.");
  }

  return await res.json();
}