import { FragmentHelpers } from "../../types";

export const DISCOUNT_APPLICATION_FRAGMENT: FragmentHelpers = {
  fragment: `
    fragment DiscountApplicationFragment on DiscountApplication {
      targetSelection
      allocationMethod
      targetType
      value {
        ... on MoneyV2 {
          amount
          currencyCode
        }
        ... on PricingPercentageValue {
          percentage
        }
      }
      ... on ManualDiscountApplication {
        title
        description
      }
      ... on DiscountCodeApplication {
        code
        applicable
      }
      ... on ScriptDiscountApplication {
        title
      }
      ... on AutomaticDiscountApplication {
        title
      }
    }
  `,
  ref: '...DiscountApplicationFragment'
}