import React from "react";
import styled from "styled-components";
import { Modal } from "./Modal";

const HeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .button-modal {
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
  }
`

type Props = {
  title: string
  buttonText?: string
  modalContent: () => JSX.Element,
  modalMaxWidth?: string
}

export default function HeadlineModal({ modalContent, title, buttonText = 'Learn More', modalMaxWidth }: Props) {
  const [isModalActive, setIsModalActive] = React.useState(false)

  return (
    <>
      <HeadlineContainer className="headline-modal">
        <span className="no-margins modal-text">{title}</span>
        <a className="button-modal no-margins" onClick={(e) => {
          e.preventDefault();
          setIsModalActive(true)
        }}>
          {buttonText}
        </a>
      </HeadlineContainer>
      <Modal
        maxWidth={modalMaxWidth || "60%"}
        isActive={isModalActive}
        closeAction={() => setIsModalActive(false)}
      >
        {modalContent()}
      </Modal>
    </>
  )
}