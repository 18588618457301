import { CustomerStory } from "types/misc";

export const getStoryType = (customerStory: CustomerStory) => {
  if (customerStory.video) {
    return 'video'
  } else if (customerStory.image) {
    return 'image'
  }

  return 'text';
}