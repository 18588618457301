import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'

export function Rebate() {
  useLayoutEffect(() => {
    if (!window._ecr) {
      const script = document.createElement('script')
      script.setAttribute('src', '/rebate.js')
      document['body'].appendChild(script)
    }
  }, [])
  return <RebateDiv id="ecorebates" />
}

const RebateDiv = styled.div`
  display: none;
  &#ecorebates .container-fluid {
    padding: 0;
    background: white;
  }
  &#ecorebates .eco-m .h1,
  .eco-m h1 {
    display: none;
  }
  &#ecorebates .eco-m .eco-c .eco-product-list .eco-product > .panel-heading,
  &#ecorebates .eco-m .eco-c .eco-product-list .panel-group .panel-heading,
  &#ecorebates .eco-m .eco-c .eco-rebate-filter,
  &#ecorebates .eco-m .eco-c .eco-product-list .eco-rebate-list,
  &#ecorebates .eco-m .eco-c .eco-heading:last-child {
    border-color: var(--lightestGray);
  }
  &#ecorebates .eco-m .eco-c .eco-rebate-filter {
    border-left: 1px solid var(--lightestGray);
    border-right: 1px solid var(--lightestGray);
    margin: var(--smallMargin) 0 0;
  }
  &#ecorebates .eco-m .eco-c .eco-product-list .eco-product + .eco-product {
    border-top: 0px;
  }
  .ecr-subheading {
    display: none;
  }
  .eco-terms span {
    color: lightgrey;
    font-size: 1rem;
    a {
      font-size: 1rem;
    }
  }
  .media-body {
    text-align: center;
  }
  .ecr-rc-total-products {
    color: grey;
  }
  .ecr-location-display {
    margin: 0;
  }
  .eco-m .col-xs-12 {
    text-align: left;
  }
`
