import React, { useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { VariantSelector } from '../VariantSelector'
import { ProductCopy } from 'types/misc'
import { useBundleVariants } from './useBundleVariant'
import { QuantitySelector } from '../QuantitySelector'
import { BundleCollectionCard } from './styles'
import styled from 'styled-components'
import { GatsbyShopifyStorefrontProduct, ShopifyProductVariant } from 'lib/shopify/storefront-api-client/types/custom.types'
import { getDefaultShopifyProductImage } from 'utilities'

type HeaderProps = {
  product: GatsbyShopifyStorefrontProduct,
}

const Header = ({ product }: HeaderProps) => {
  const mediaItem = getDefaultShopifyProductImage(product);
  
  return (
    <>
      <div className="product-image-wrapper">
        <GatsbyImage
          image={mediaItem.image.gatsbyImageData}
          alt={`${product.title}`}
          objectFit="contain"
          loading="eager"
        />
      </div>

      <div>
        <div className="product-title-wrapper">
          <h2 className="product-title no-margins">
            {product.title}
          </h2>
        </div>
      </div>
    </>
  )
}

type DealProductCardProps = {
  product: GatsbyShopifyStorefrontProduct
  copy: ProductCopy
  hidePrice: boolean,
  quantitySelectionEnabled?: boolean
  productOptional?: boolean
  onVariantSelect: (productId: string, variant: ShopifyProductVariant & { quantity: number }) => void
}
export function BundleProductCard({
  product,
  onVariantSelect,
  copy,
  hidePrice,
  quantitySelectionEnabled,
  productOptional
}: DealProductCardProps) {
  if (!copy || !product) return null

  const {
    availableVariants,
    setSelectedVariant,
    selectedVariant
  } = useBundleVariants(product, copy);

  const [currentQuantity, setCurrentQuantity] = useState<number>(productOptional ? 0 : 1);

  useEffect(() => {
    if (quantitySelectionEnabled) {
      onVariantSelect(
        String(product.id),
        {
          ...selectedVariant,
          quantity: currentQuantity
        }
      )
    }
  }, [currentQuantity])

  return (
    <BundleProductCardContainer data-testid="product-preview" className="product-preview" hidePrice={hidePrice}>
      <div className="product-wrapper">
        <div>
          <Header product={product} />
          <p className="product-description">{copy.shortDescription}</p>
        </div>
        <div className="pricing">
          <div className={`
            product-selection-wrapper
            ${quantitySelectionEnabled ? 'quantity-selection-enabled' : ''}
          `}>
            <VariantSelector
              variants={availableVariants}
              setSelectedVariant={(variant) => {
                setSelectedVariant(variant);
                onVariantSelect(
                  String(product.id),
                  variant
                )
              }}
              hidePrice={hidePrice}
              selectedVariant={selectedVariant}
              size="small"
              type="input"
            />
            {quantitySelectionEnabled && (
              <QuantitySelector 
                selectedVariant={selectedVariant}
                currentQuantity={currentQuantity}
                setCurrentQuantity={setCurrentQuantity}
                hidePrice={true}
                minAmount={productOptional ? 0 : 1}
              />
            )}
          </div>
        </div>
      </div>
    </BundleProductCardContainer>
  )
}

const BundleProductCardContainer = styled(BundleCollectionCard)`
  .product-wrapper {
    height: 100%;
    .product-description {
      min-height: auto;
    }

    .product-single-variant {
      display: none;
    }
  }

  @media (max-width: 991px) {
    .variant-select-input {
      font-size: 1.4rem;
      padding: .5rem 3rem .5rem 2rem;
    }
  }

  @media (max-width: 768px) {
    .product-wrapper {
      .product-title-wrapper .product-title {
        font-size: 1.6rem;
      }
      .product-description {
        display: block;
        padding-bottom: 0;
      }
    }
  }
`