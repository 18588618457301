import styled from 'styled-components'
import { media } from 'styles'

export const SectionOuter = styled.section`
  /* Theme intentions */
  --sectionBgColor: none;
  --sectionHeadlineColor: var(--headlineColor);
  --sectionTextColor: var(--textColor);

  &.Section--theme__secondary,
  &.Section--theme__primary,
  &.Section--theme__green {
    --sectionHeadlineColor: var(--white);
    --sectionTextColor: var(--white);
  }

  &.Section--theme__secondary {
    --sectionBgColor: var(--secondaryColor);
  }

  &.Section--theme__primary {
    --sectionBgColor: var(--primaryColor);
  }

  &.Section--theme__gray {
    --sectionBgColor: var(--lightesterGray);
  }

  &.Section--theme__white {
    --sectionBgColor: var(--white);
  }

  &.Section--theme__green {
    --sectionBgColor: var(--darkGreen);
  }

  /* Size intentions */
  .Section--padding {
    --sectionPaddingNormalMax: 120;
    --sectionPaddingSmallMax: 70;
    --sectionPaddingMin: 40;
    --sectionPaddingMax: var(--sectionPaddingNormalMax);

    --sectionPaddingTop: calc(
      var(--sectionPaddingMin) * 1px +
        (var(--sectionPaddingTopMax) - var(--sectionPaddingMin)) *
        (var(--windowWidth) - var(--layoutWidthMin)) / var(--maxTextLayoutWidth)
    );
    --sectionPaddingBottom: calc(
      var(--sectionPaddingMin) * 1px +
        (var(--sectionPaddingBottomMax) - var(--sectionPaddingMin)) *
        (var(--windowWidth) - var(--layoutWidthMin)) / var(--maxTextLayoutWidth)
    );

    position: relative;
    z-index: 1;
    padding: var(--sectionPaddingTop) 0 var(--sectionPaddingBottom);

    & > *:first-child {
      margin-top: 0;
    }
    & > *:last-child {
      margin-bottom: 0;
    }

    &.Section--padding__small {
      --sectionPaddingMax: var(--sectionPaddingSmallMax);
    }

    --sectionPaddingTopMax: var(--sectionPaddingMax);
    --sectionPaddingBottomMax: var(--sectionPaddingMax);

    &.Section--padding-top__small {
      --sectionPaddingTopMax: var(--sectionPaddingSmallMax);
    }

    &.Section--padding-bottom__small {
      --sectionPaddingBottomMax: var(--sectionPaddingSmallMax);
    }

    &.Section--padding-top__none {
      --sectionPaddingTop: 0;
    }

    &.Section--padding-bottom__none {
      --sectionPaddingBottom: 0;
    }
  }

  background-color: var(--sectionBgColor);
  color: var(--sectionTextColor);

  .title-split {
    text-align: center;
    margin-bottom: var(--bottomSectionPadding);
  }

  ${media.large`
    .Section--padding {
      --sectionPaddingTop: calc(var(--sectionPaddingTopMax) * 1px);
      --sectionPaddingBottom: calc(var(--sectionPaddingBottomMax) * 1px);
    }
  `};
`
