import React from 'react'
import styled from 'styled-components'
import { below } from 'styles'
import { Icon } from './Icon'
import { shareFacebook, shareTwitter } from 'utilities'

type Props = {
  isPc: boolean
}

export const SocialShareButtons = ({ isPc }: Props) => {
  const Links = (
    <>
      <a
        rel="noopener noreferer"
        href="https://www.facebook.com/rachioco"
        onClick={(e) => {
          e.preventDefault();
          shareFacebook(location.href)
        }}
        target="_blank"
        title="Rachio Facebook page (opens in a new tab)"
      >
        <Icon color="#00a7e1" name="facebook" />
      </a>
      <a
        rel="noopener noreferer"
        href="https://www.instagram.com/rachioco/"
        target="_blank"
        title="Rachio Instagram page (opens in a new tab)"
      >
        <Icon color="#00a7e1" name="instagram" />
      </a>
      <a
        rel="noopener noreferer"
        href="https://twitter.com/rachioco/"
        onClick={(e) => {
          e.preventDefault();
          shareTwitter(location.href)
        }}
        target="_blank"
        title="Rachio Twitter page (opens in a new tab)"
      >
        <Icon color="#00a7e1" name="twitter" />
      </a>
    </>
  )
  return (
    <>
      {isPc ? (
        <IconsPc>
          {Links}
        </IconsPc>
      ) : (
        <IconsMobile>
          {Links}
        </IconsMobile>
      )}
    </>
  )
}

const IconsMobile = styled.div`
  display: none;
  margin: var(--margin) 0;
  a {
    color: var(--primaryBlue);
    background: #fff;
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${below.medium`
    display:flex;
    justify-content: center;
    a {
      margin: 0 5px;
    }
  `}
`

const IconsPc = styled.div`
  position: absolute;
  right: var(--margin);
  top: var(--margin);

  display: flex;
  flex-direction: column;
  a {
    color: var(--primaryBlue);
    background: #fff;
    width: 40px;
    height: 40px;
    margin: calc(var(--margin) * 0.5) 0;
    font-size: 30px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${below.medium`
    display:none;
  `}
`
