import React, {
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react'
import { navigate } from 'gatsby'

import {
  getUrlParam,
  hasUrlParam,
  asyncForEach,
  skusExtend,
  fetchIdFromGlobalLineItemId,
  fetchIdFromGlobalId,
  getGlobalVariantId,
  MAX_LINE_ITEM_QUANTITY,
  daysToMs,
  getGlobalProductId,
  isValidCompareAtPrice
} from 'utilities'

import { 
  addLineItems as addShopifyLineItems, 
  createCheckout as createShopifyCheckout, 
  addDiscount as addShopifyDiscount,
  removeDiscount as removeShopifyDiscount,
  fetchCheckout as fetchShopifyCheckout,
  updateLineItems as updateShopifyLineItems,
  removeLineItems as removeShopifyLineItems,
  attributesUpdate as updateShopifyCheckoutAttributes
} from "lib/shopify";
import {
  trackProductAdded,
  trackProductRemoved,
  TrackingProductUnformatted,
  checkoutCustomAttributes,
  trackProductsAdded,
} from 'analytics'
import { useStorage } from 'hooks'
import { useLoad } from './loading'
import { ExtendVariant } from '../lib/extend';
import { CONTROLLER_PRODUCT_SKUS, CONTROLLER_PRODUCT_SKUS_MAP, PARTNER_PRODUCT_VARIANTS_BY_TITLE } from '../../common/config/products';
import { getBundleNameFromLineItem, isBundleLineItem } from '../lib/pickystory';
import { AttributeInput, CheckoutLineItemInput, DiscountCodeApplication, MoneyV2} from 'lib/shopify/storefront-api-client/types/storefront.types';
import { ShopifyCheckout, ShopifyCheckoutLineItem } from 'lib/shopify/storefront-api-client/types/custom.types';

declare const window: any

interface LineItem {
  variantId: string
  name: string
  price: string
  quantity: number
  sku: string
}

interface RemoveLineItem {
  id: string
  name: string
  price: string
  sku: string
  quantity: number
  variantId?: string | number
}

export interface LineItemInput extends LineItem {
  noRedirectToCart?: boolean
  customAttributes?: AttributeInput[]
  warranty?: {
    plan: any
    strippedProductId: string
    title: string
  }
}

export interface UpdateLineItem {
  id: string
  name: string
  price: string
  sku: string
  quantity: number
  isIncreasingQuantity: boolean
  quantityChanged: number
  variantId?: string | number
}

export enum CHECKOUT_STATES {
  PENDING = 'PENDING',
  READY = 'READY',
  PRODUCT_ADDED = 'PRODUCT_ADDED',
  PRODUCT_REMOVED = 'PRODUCT_REMOVED',
  QUANTITY_UPDATED = 'QUANTITY_UPDATED',
  DISCOUNT_ADDED = 'DISCOUNT_ADDED',
  DISCOUNT_REMOVED = 'DISCOUNT_REMOVED'
}


export const initialCheckoutValues = {
  cartQuantity: 0,
  legacyAddVariantToCart: (_: LineItemInput) => {},
  // @ts-ignore
  addVariantsToCart: (lineItems: LineItemInput[], noRedirectToCart?: boolean, discountCode?: string) => Promise.resolve(),
  // @ts-ignore
  addVariantsToCheckout: (lineItems: LineItemInput[], discountCode?: string) => Promise.resolve(),
  addWarrantyToCart: (
    plan: any,
    strippedProductId: string,
    title: string
  ) => {
    plan + strippedProductId + title
  },
  openCart: () => {},
  checkout: {
    id: '',
    discountApplications: [] as DiscountCodeApplication[],
    lineItems: [] as ShopifyCheckoutLineItem[],
    webUrl: '',
    subtotalPrice: {
      amount: '',
      currencyCode: 'USD'
    } as MoneyV2,
  } as ShopifyCheckout,
  updateQuantityInCart: async (_: UpdateLineItem) => {},
  removeLineItemInCart: (_: RemoveLineItem) => {},
  removeLineItemsInCart: (
    _: RemoveLineItem[]
  ) => {},
  updateCustomAttributes: () => {},
  shopifyError: '',
  applyDiscount: async (_code: string) => {},
  removeDiscount: async () => {},
  getCheckout: async () => {},
  checkoutState: CHECKOUT_STATES.PENDING,
}


export const CheckoutContext = createContext(initialCheckoutValues);

export const CheckoutProvider = ({ children }) => {
  const [checkoutState, setCheckoutState] = useState<CHECKOUT_STATES>(CHECKOUT_STATES.PENDING);
  const [checkout, setCheckout] = useState<ShopifyCheckout>(null!)
  const [shopifyError, setShopifyError] = useState('')
  const [cartId, setCartId] = useStorage({ key: 'rachioCartId', expires: daysToMs(5) })
  const { setIsLoading } = useLoad()

  const createCheckout = async () => {
    setCheckoutState(CHECKOUT_STATES.PENDING);
    setIsLoading(true)
    try {
      const checkoutCart = await createShopifyCheckout() as ShopifyCheckout
      setCartId(checkoutCart.id)
      setCheckout(checkoutCart)
      setIsLoading(false)
      setCheckoutState(CHECKOUT_STATES.READY);
    } catch (e) {
      console.log(e)
      setShopifyError('Our checkout is temporarily experiencing difficulties.')
      setIsLoading(false)
    }
  }

  const getCheckout = async () => {
    let checkoutId = cartId
    // If checkout id param is passed from abandonment email use it as the cart id
    if (hasUrlParam('checkout_id')) {
      checkoutId = decodeURIComponent(getUrlParam('checkout_id') || '');
      setCartId(checkoutId)
    }

    if (checkoutId) {
      try {
        let checkoutCart = await fetchShopifyCheckout(checkoutId) as ShopifyCheckout

        /**
         * If cached checkout id returns null OR the checkout returned is completed,
         * THEN reset cart cookies and make new checkout.
         */
        if (!checkoutCart || checkoutCart?.completedAt) {
          localStorage.removeItem('rachioCartId')
          return createCheckout()
        }

        setCheckout(checkoutCart)
        return setCheckoutState(CHECKOUT_STATES.READY);
      } catch (e) {
        createCheckout()
        console.log(e)
      }
    }
    return createCheckout()
  }

  useEffect(() => {
    getCheckout()
  }, [])

  const openCart = () => {
    navigate('/cart/');
  }

  const addVariantsToCheckout = async (lineItems: CheckoutLineItemInput[], discountCode?: string) => {
    try {
      const oneTimeCheckout = await createShopifyCheckout();
      
      if (!oneTimeCheckout) throw new Error("Could not create checkout!");

      const filterMaxQtyProducts = (lineItem: CheckoutLineItemInput) => {
        const currentLineItemQuantity = checkout.lineItems?.find(
          (item) => item.variant?.id === lineItem.variantId
        )?.quantity || 0

        return currentLineItemQuantity <= MAX_LINE_ITEM_QUANTITY
      }
        // Limits orders to MAX_LINE_ITEM_QUANTITY per item

      const lineItemsToAdd = lineItems.filter(filterMaxQtyProducts).map(lineItem => {
        const { variantId , customAttributes, quantity} = lineItem
        return { variantId , customAttributes, quantity }
      })

      let updatedCheckout = await addShopifyLineItems(oneTimeCheckout.id, lineItemsToAdd)

      if (!updatedCheckout) throw new Error('Could not add line items to checkout!')

      if (updatedCheckout && discountCode) {
        updatedCheckout = await addShopifyDiscount(checkout.id, discountCode);
      }
      
      const variantsInCart = updatedCheckout?.lineItems?.filter(({ variant }) => (
        lineItemsToAdd.map(lineItemToAdd => lineItemToAdd.variantId).includes(variant?.id as string)
      ))

      if (variantsInCart?.length && updatedCheckout) {
        const formattedTrackingProducts = variantsInCart.map(lineItem => {
          if (!lineItem.variant) return;

          return {
            variantId: lineItem.variant.id,
            productId: lineItem.variant.product.id,
            quantity: lineItem.quantity,
            title: lineItem.variant.title,
            price: lineItem.variant.compareAtPrice && isValidCompareAtPrice(lineItem.variant)
              ? lineItem.variant.compareAtPrice.amount 
              : lineItem.variant.price.amount,
            sku: lineItem.variant.sku
          }
        }).filter(Boolean) as TrackingProductUnformatted[]

        trackProductsAdded(formattedTrackingProducts, updatedCheckout)
      }

      location.href = oneTimeCheckout.webUrl
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const addVariantsToCart = async (
    lineItems: CheckoutLineItemInput[], 
    noRedirectToCart?: boolean, 
    discountCode?: string,
  ) => {
    try {
      if (!checkout) throw new Error('Checkout is not defined, could not add item to cart');
      setIsLoading(true);
      setCheckoutState(CHECKOUT_STATES.READY);

      const filterMaxQtyProducts = (lineItem: CheckoutLineItemInput) => {
        const currentLineItemQuantity = checkout.lineItems?.find(
          (item) => item.variant?.id === lineItem.variantId
        )?.quantity || 0

        return currentLineItemQuantity <= MAX_LINE_ITEM_QUANTITY
      }
        // Limits orders to MAX_LINE_ITEM_QUANTITY per item

      const lineItemsToAdd = lineItems.filter(filterMaxQtyProducts).map(lineItem => {
        const { variantId , customAttributes, quantity} = lineItem
        return { variantId , customAttributes, quantity }
      })

      let updatedCheckout = await addShopifyLineItems(checkout.id, lineItemsToAdd)

      if (discountCode) {
        updatedCheckout = await addShopifyDiscount(checkout.id, discountCode);
      }
      
      const variantsInCart = updatedCheckout.lineItems.filter(({ variant }) => (
        lineItemsToAdd.map(lineItemToAdd => lineItemToAdd.variantId).includes(variant.id)
      ))

      if (variantsInCart.length) {
        const formattedTrackingProducts = variantsInCart.map(lineItem => ({
            variantId: lineItem.variant.id,
            productId: lineItem.variant.product.id,
            quantity: lineItem.quantity,
            title: lineItem.variant.title,
            price: lineItem.variant.compareAtPrice 
              ? lineItem.variant.compareAtPrice.amount 
              : lineItem.variant.price.amount,
            sku: lineItem.variant.sku
          }
        ))

        trackProductsAdded(formattedTrackingProducts, updatedCheckout)
      }

      setCheckout(updatedCheckout);
      setIsLoading(false)
      setCheckoutState(CHECKOUT_STATES.READY);
      if (!noRedirectToCart) {
        openCart()
      }
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  /**
   * @todo REFACTOR TO ONLY WORK WITH WARRANTIES
   */
  const legacyAddVariantToCart = async ({
    variantId,
    quantity,
    name,
    price,
    sku,
    noRedirectToCart = false,
    customAttributes = [],
    warranty,
  }: LineItemInput) => {
    setIsLoading(true)
    setCheckoutState(CHECKOUT_STATES.READY);

    try {
      // Limits orders to MAX_LINE_ITEM_QUANTITY per item
      const currentLineItemQuantity =
        checkout?.lineItems?.find((item) => item.variant?.id === variantId)
          ?.quantity || 0

      let result = checkout
      //prevent more than MAX_LINE_ITEM_QUANTITY units per product

      if (currentLineItemQuantity < MAX_LINE_ITEM_QUANTITY) {
        let actualQuantity = quantity

        //match the Extend Quantity with the product quantity
        if (skusExtend.includes(sku)) {
          // retrivew product reference
          const refId = customAttributes.filter((attr) => attr.key === 'Ref')[0]
            .value
          let checkoutCart = await fetchShopifyCheckout(cartId)

          const RefQuantity =
            checkoutCart?.lineItems?.find((item) => {
              const variantId = String(item.variant.id)
                .split('/')
                .slice(-1)[0]
                .split('?')[0]

              return variantId === refId
            })?.quantity || 0
          actualQuantity = RefQuantity
        }

        result = await addShopifyLineItems(checkout.id, [
          { variantId, quantity: actualQuantity, customAttributes },
        ])

        setCheckout(result)

        const variantInCart = result.lineItems.find(({ variant }) => variant?.id === variantId);

        if (variantInCart) {
          // productAdded tracking event
          const addedProduct: TrackingProductUnformatted = {
            variantId: variantId,
            productId: String(variantInCart?.variant?.product.id),
            quantity,
            title: name,
            price,
            sku
          }

          trackProductAdded(addedProduct, result)
        }
      }
      if (warranty?.plan) {
        window.ExtendShopify.getPlanVariant(
          {
            referenceId: warranty.strippedProductId,
            termLength: warranty.plan.term
          },
          async function (error: any, planVariant: { variantId: string }) {
            if (error) return console.log(error)
            var variantId = planVariant?.variantId
            const globalVariantId = getGlobalVariantId(variantId)

            //remove old warranty if exists
            const items = checkout?.lineItems
            const oldWarranty = items?.find((item: ShopifyCheckoutLineItem) =>
              item.customAttributes.some(
                (attr) => attr.value === warranty.strippedProductId
              )
            )
            let quantity = 1
            let previousQuantity = oldWarranty?.quantity || 0
            if (
              oldWarranty?.variant?.id &&
              oldWarranty?.variant.id !== globalVariantId &&
              oldWarranty?.variant.sku
            ) {
              await removeLineItemInCart({
                id: getGlobalVariantId(String(oldWarranty.id)),
                name: oldWarranty.title,
                price: oldWarranty.variant.price.amount,
                sku: oldWarranty.variant.sku,
                quantity: oldWarranty.quantity,
              })
              const RefQuantity =
                result.lineItems?.find((item) => {
                  const variantId = String(item.variant.id)
                    .split('/')
                    .slice(-1)[0]
                    .split('?')[0]

                  return variantId === warranty.strippedProductId
                })?.quantity || 0
              quantity = RefQuantity
              previousQuantity = 0
            }
            if (!oldWarranty?.variant.id) {
              const RefQuantity =
                result.lineItems?.find((item) => {
                  const variantId = fetchIdFromGlobalLineItemId(item.variant.id);
                  return variantId === warranty.strippedProductId
                })?.quantity || 0
              quantity = RefQuantity
            }

            if (previousQuantity < MAX_LINE_ITEM_QUANTITY) {
              const result2 = await addShopifyLineItems(checkout.id, [
                {
                  variantId: globalVariantId,
                  quantity,
                  customAttributes: [
                    {
                      key: 'Ref',
                      value: warranty.strippedProductId,
                    },
                    {
                      key: 'Extend.IsExtendWarranty',
                      value: 'true'
                    },
                    { key: 'Product', value: warranty.title },
                    { key: 'Term', value: String(warranty.plan.term) },
                    { key: 'Price', value: String(warranty.plan.price) },
                    { key: 'Vendor', value: 'Extend' },
                  ],
                },
              ])

              const product: TrackingProductUnformatted = {
                productId: getGlobalProductId(warranty.strippedProductId),
                variantId: globalVariantId,
                quantity: quantity,
                title: warranty.plan.title,
                price: String(warranty.plan.price / 100),
                sku: warranty.plan.planId,
              }

              trackProductAdded(product, result2);
              setCheckout(result2);
              setCheckoutState(CHECKOUT_STATES.PRODUCT_ADDED);
            }
          }
        )
      } else {
        //checks if warranty is already in cart
        const warrantyInCart = result?.lineItems.find((item: ShopifyCheckoutLineItem) =>
          item.customAttributes.some(
            (attr) => attr.value === warranty?.strippedProductId
          )
        )
        const currentQuantity = warrantyInCart?.quantity || 0
        const RefQuantity =
          result.lineItems?.find((item) => {
            const variantId = fetchIdFromGlobalLineItemId(item.variant.id);
            return variantId === warranty?.strippedProductId
          })?.quantity || 0

        if (warrantyInCart && warrantyInCart.quantity < MAX_LINE_ITEM_QUANTITY) {
          await updateQuantityInCart({
            id: String(warrantyInCart.id),
            name: warrantyInCart.title,
            price: warrantyInCart.variant.price.amount,
            quantity: RefQuantity,
            sku: warrantyInCart.variant.sku,
            isIncreasingQuantity: currentQuantity < RefQuantity,
            quantityChanged: Math.abs(currentQuantity - RefQuantity),
          })
        }
      }

      setIsLoading(false)
      setCheckoutState(CHECKOUT_STATES.READY);
      if (!noRedirectToCart) {
        openCart()
      }
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const applyDiscount = async (code: string) => {
    try {
      setCheckoutState(CHECKOUT_STATES.PENDING);
      const updatedCheckout = await addShopifyDiscount(checkout.id, code);  
      setCheckout(updatedCheckout);
      setCheckoutState(CHECKOUT_STATES.DISCOUNT_ADDED);
    } catch (error) {
      console.error(error)
    }
  }

  const removeDiscount = async () => {
    try {
      setCheckoutState(CHECKOUT_STATES.PENDING);
      const updatedCheckout = await removeShopifyDiscount(checkout.id);
      setCheckout(updatedCheckout);
      setCheckoutState(CHECKOUT_STATES.DISCOUNT_REMOVED);
    } catch (error) {
      console.error(error)
    }
  }

  const addWarrantyToCart = async (
    plan: any,
    strippedProductId: string,
    title: string,

    result: any = null
  ) => {
    //add the recent warranty
    window.ExtendShopify.getPlanVariant(
      {
        referenceId: strippedProductId,
        termLength: plan.term
      },
      async function (error: any, planVariant: ExtendVariant) {
        if (error) return console.log(error)
        var variantId = planVariant?.variantId

        const globalVariantId = getGlobalVariantId(variantId);

        //remove old warranty if exists
        const items = result ? result : checkout?.lineItems
        const oldWarranty = items?.find((item: ShopifyCheckoutLineItem) =>
          item.customAttributes.some(
            (attr) => attr.value === strippedProductId
          )
        )
        if (
          oldWarranty?.variant.id &&
          oldWarranty?.variant.id !== globalVariantId
        ) {
          await removeLineItemInCart({
            id: String(oldWarranty.id),
            name: oldWarranty.title,
            price: oldWarranty.variant.price.amount,
            sku: oldWarranty.variant.sku,
            quantity: oldWarranty.quantity,
          })
        }

        await legacyAddVariantToCart({
          variantId: globalVariantId,
          quantity: 1,
          name: plan.title,
          price: plan.price,
          sku: plan.planId,
          customAttributes: [
            {
              key: 'Ref',
              value: strippedProductId,
            },
            { key: 'Product', value: title },
            { key: 'Term', value: String(plan.term) },
            { key: 'Price', value: String(plan.price) },
            { key: 'Vendor', value: 'Extend' },
          ],
          noRedirectToCart: true,
        })
      }
    )
  }

  const updateQuantityInCart = async ({
    id,
    name,
    price,
    quantity,
    sku,
    isIncreasingQuantity,
    quantityChanged,
    variantId = '',
  }: UpdateLineItem) => {
    if (quantity <= 0) {
      return removeLineItemInCart({ id, name, price, sku, quantity })
    }
    setCheckoutState(CHECKOUT_STATES.PENDING);
    setIsLoading(true)

    try {
      const lineItem = checkout.lineItems.find(l => l.id === id) as ShopifyCheckoutLineItem;
      const result = await updateShopifyLineItems(checkout.id, [
        { id, quantity },
      ])
      setCheckout(result)
      setCheckoutState(CHECKOUT_STATES.QUANTITY_UPDATED)

      if (lineItem) {
        const product: TrackingProductUnformatted = {
          productId: lineItem.variant.product.id,
          variantId: lineItem.variant.id,
          quantity: quantityChanged,
          title: name,
          price,
          sku
        }
  
        if (lineItem && isBundleLineItem(lineItem)) {
          product.bundle = getBundleNameFromLineItem(lineItem) || ""
        }
  
        if (isIncreasingQuantity) {
          trackProductAdded(product, result)
        } else {
          trackProductRemoved(product, result)
        }
      }

      //----- block to match quantity
      const weatherflowTempestIndex = result.lineItems.findIndex(
        (item) => item.variant.title == PARTNER_PRODUCT_VARIANTS_BY_TITLE.WEATHERFLOW_TEMPEST.BUNDLE
      )

      if ((CONTROLLER_PRODUCT_SKUS_MAP[sku]) && weatherflowTempestIndex !== -1) {
        const r38zIdx = result.lineItems.findIndex(
          (item) => item.variant.sku == CONTROLLER_PRODUCT_SKUS.EIGHT_ZONE
        )
        const r316zIdx = result.lineItems.findIndex(
          (item) => item.variant.sku == CONTROLLER_PRODUCT_SKUS.SIXTEEN_ZONE
        )
        const r34zIdx = result.lineItems.findIndex(
          (item) => item.variant.sku == CONTROLLER_PRODUCT_SKUS.FOUR_ZONE
        )
        const quantityr38z = result.lineItems[r38zIdx]?.quantity || 0
        const quantityr316z = result.lineItems[r316zIdx]?.quantity || 0
        const quantityr34z = result.lineItems[r34zIdx]?.quantity || 0
        const totalControllerQty = quantityr38z + quantityr316z + quantityr34z;

        // updateQuantityInCart({
        //   id: String(result.lineItems[weatherflowTempestIndex].id),
        //   name: result.lineItems[weatherflowTempestIndex].title,
        //   price: result.lineItems[weatherflowTempestIndex].variant.price,
        //   quantity: 1,
        //   sku: String(result.lineItems[weatherflowTempestIndex].variant.sku),
        //   isIncreasingQuantity,
        //   quantityChanged,
        // })
      }
      //----- /block to match quantity // this block was used for RCD-1089

      //----- block to match quantity with the Extend Warranty
      if (CONTROLLER_PRODUCT_SKUS_MAP[sku]) {
        const r3VariantId = fetchIdFromGlobalId(variantId);
        const item = result.lineItems.find((item) => {
          return (
            item.customAttributes.filter((attr) => attr.key === 'Ref')[0]
              ?.value === r3VariantId
          )
        })
        if (item) {
          updateQuantityInCart({
            id: String(item.id),
            name: item.title,
            price: item.variant.price.amount,
            quantity: quantity,
            sku: item.variant.sku,
            isIncreasingQuantity,
            quantityChanged,
          })
        }
      }
      //----- /block to match quantity with the Extend Warranty

      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const removeLineItemsInCart = (variants: RemoveLineItem[]) => {
    asyncForEach(variants, removeLineItemInCart)
  }

  const removeLineItemInCart = async ({
    id,
    name,
    price,
    sku,
    quantity,
    variantId = '',
  }: RemoveLineItem) => {
    setCheckoutState(CHECKOUT_STATES.PENDING);
    setIsLoading(true)
    try {
      const lineItemToRemove = checkout.lineItems.find(l => l.id === id);
      const result = await removeShopifyLineItems(checkout.id, [id]);

      if (lineItemToRemove) {
        const product: TrackingProductUnformatted = {
          productId: lineItemToRemove.variant.product.id,
          variantId: lineItemToRemove.variant.id,
          title: name,
          price,
          sku,
          quantity,
        }
  
        if (isBundleLineItem(lineItemToRemove)) {
          product.bundle = getBundleNameFromLineItem(lineItemToRemove) || ""
        }
  
        trackProductRemoved(product, result)
      }

      setCheckoutState(CHECKOUT_STATES.DISCOUNT_REMOVED);
      setCheckout(result)
      
      setIsLoading(false)

      //----- block to match quantity with the Extend Warranty & Weatherflow Tempest Bundle
      if (CONTROLLER_PRODUCT_SKUS_MAP[sku]) {
        const r3VariantId = fetchIdFromGlobalId(variantId);
        const extendLineItem = result.lineItems.find((item) => {
          return (
            item.customAttributes.filter((attr) => attr.key === 'Ref')[0]
              ?.value === r3VariantId
          )
        })
        const wftItem = result.lineItems.find((item) => {
          return (
            item.variant.title == PARTNER_PRODUCT_VARIANTS_BY_TITLE.WEATHERFLOW_TEMPEST.BUNDLE
          )
        })
        if (extendLineItem) {
          await removeLineItemInCart({
            id: String(extendLineItem.id),
            name: extendLineItem.title,
            price: extendLineItem.variant.price.amount,
            sku: extendLineItem.variant.sku,
            quantity: extendLineItem.quantity,
          })
        }

        if (wftItem) {
          await removeLineItemInCart({
            id: String(wftItem.id),
            name: wftItem.title,
            price: wftItem.variant.price.amount,
            sku: wftItem.variant.sku,
            quantity: wftItem.quantity,
          })
        }

      }
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  // These attributes are added to the checkout object and sent to shopify
  const updateCustomAttributes = async (
    newAttributes: AttributeInput[] = []
  ) => {
    setIsLoading(true)
    try {
      const customAttributes: AttributeInput[] = checkoutCustomAttributes({
        checkoutId: cartId,
      }, newAttributes)

      const result = await updateShopifyCheckoutAttributes(checkout.id, {
        customAttributes,
      })

      setCheckout(result)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const cartQuantity = checkout?.lineItems.length
    ? checkout?.lineItems
        .map((lineItem) => lineItem.quantity)
        .reduce((accumulator, quantity) => accumulator + quantity, 0)
    : 0

  return (
    <CheckoutContext.Provider
      value={{
        cartQuantity,
        legacyAddVariantToCart,
        addWarrantyToCart,
        addVariantsToCart,
        addVariantsToCheckout,
        openCart,
        checkout,
        updateQuantityInCart,
        removeLineItemInCart,
        removeLineItemsInCart,
        updateCustomAttributes,
        shopifyError,
        applyDiscount,
        removeDiscount,
        getCheckout,
        checkoutState,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export const useCheckout = () => useContext(CheckoutContext)
