import React from 'react'
import { SanitySection } from 'components/sections'
import { FormattedHtml } from 'styles'
import { PortableText } from 'components/elements'
import {
  SectionPaddingSize,
  SpecialContent,
  ListItem,
  TableSpecial,
  ParagraphSpecialSection,
} from 'types/misc'

type Props = {
  title: string
  isPageHeader: boolean
  content?: SpecialContent
  paddingBottom?: SectionPaddingSize | ''
}

const SanitySpecial = ({
  title,
  isPageHeader,
  content,
  paddingBottom = 'none',
}: Props) => {
  return (
    <SanitySection
      paddingTop={isPageHeader ? '' : 'none'}
      paddingBottom={paddingBottom}
      width="tight"
    >
      {isPageHeader && (
        <>
          <h1
            className="center-text"
            data-testid="privacy-policy-h1"
          >
            {title}
          </h1>
        </>
      )}
      <FormattedHtml>
        {!isPageHeader && <h3>{title}</h3>}
        {content && (
          <>
            {content.map((element) => {
              switch (element._type) {
                case 'paragraphForSpecialSection':
                  let pElement = element as ParagraphSpecialSection
                  return (
                    <div key={element.id}>
                      <PortableText _rawContent={pElement._rawParagraph} />
                    </div>
                  )

                case 'listForSpecialSection':
                  let lElement = element as ListItem
                  return lElement.isOrderedList ? (
                    <ol key={element.id}>
                      {lElement.listItems.map(({ _rawParagraph, id }) => {
                        return (
                          <li key={id}>
                            <PortableText _rawContent={_rawParagraph} />
                          </li>
                        )
                      })}
                    </ol>
                  ) : (
                    <ul key={element.id}>
                      {lElement.listItems.map(({ _rawParagraph, id }) => {
                        return (
                          <li key={id}>
                            <PortableText _rawContent={_rawParagraph} />
                          </li>
                        )
                      })}
                    </ul>
                  )

                case 'tableForSpecialSection':
                  let tElement = element as TableSpecial
                  return (
                    <table key={element.id}>
                      {tElement.rows.map((row) => {
                        return row.isTableHeader ? (
                          <thead key={row.id}>
                            <tr>
                              <th style={{ textAlign: 'right' }}>
                                <PortableText
                                  _rawContent={row._rawFirstColumnContent}
                                />
                              </th>
                              <th>
                                <PortableText
                                  _rawContent={row._rawSecondColumnContent}
                                />
                              </th>
                            </tr>
                          </thead>
                        ) : (
                          <tbody key={row.id}>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <strong>
                                  <PortableText
                                    _rawContent={row._rawFirstColumnContent}
                                  />
                                </strong>
                              </td>
                              <td style={{ textAlign: 'left' }}>
                                <PortableText
                                  _rawContent={row._rawSecondColumnContent}
                                />
                              </td>
                            </tr>
                          </tbody>
                        )
                      })}
                    </table>
                  )
              }
            })}
          </>
        )}
      </FormattedHtml>
    </SanitySection>
  )
}

export default SanitySpecial
