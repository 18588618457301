import React, {
  FC,
  useState,
  createContext,
  useContext,
  useEffect,
} from 'react'

export const initialNotificationValues: {
  setNotification: (notification: INotificationMessage | null) => any,
  notification: INotificationMessage | null
} = {
  setNotification(_) {},
  notification: null
}

export const NotificationContext = createContext(initialNotificationValues)

export type INotificationMessage = {
  type: 'cart' | 'success' | 'error' | 'warning'
  message: string
  link?: string
  linkText?: string
  duration?: number
}

export const NotificationProvider: FC = ({ children }) => {
  const [notification, setNotification] = useState<INotificationMessage | null>(null);

  useEffect(() => {
    if (notification) {
      const displayNotificationTimeout = setTimeout(() => {
        setNotification(null)
      }, notification.duration || 5000)

      return () => {
        clearTimeout(displayNotificationTimeout)
      }
    }
  }, [notification])

  return (
    <NotificationContext.Provider
      value={{
        setNotification,
        notification
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

//? Used for a local loader such as a spinning animation
export const useNotification = () => {
  return useContext(NotificationContext)
}
