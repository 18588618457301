import React from 'react'
import { useExtend } from 'hooks'

type Props = {
  strippedProductId?: string
  type?: 'buttons' | 'simple'
  loadJustSdk?: boolean
  extendId?: string
  title: string
}

export const Extend = ({
  strippedProductId = '',
  type = 'buttons',
  loadJustSdk = false,
  extendId = 'extend-offer',
  title,
}: Props) => {
  useExtend(strippedProductId, type, loadJustSdk, extendId, title)
  return (
    <div data-testid="extend-offer">
      {!loadJustSdk && (
        <div
          id={extendId}
          style={{ minHeight: type == 'buttons' ? 78 : 27 }}
        ></div>
      )}
    </div>
  )
}
