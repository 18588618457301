import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { VariantSelector } from '../../VariantSelector'
import { ProductCopy } from 'types/misc'
import { useBundleVariants } from '../useBundleVariant'
import { QuantitySelector } from '../../QuantitySelector'
import { BundleCollectionCard } from '../styles'
import { Button } from '@rachio-npm/rachio-retail-common'
import { PickyStorySelectionMap } from 'src/lib/pickystory'
import { fetchIdFromGlobalId, getDefaultShopifyProductImage } from 'utilities'
import styled from 'styled-components'
import { ShopifyProductVariant, GatsbyShopifyStorefrontProduct } from 'lib/shopify/storefront-api-client/types/custom.types'

type HeaderProps = {
  product: GatsbyShopifyStorefrontProduct,
  copy: ProductCopy
}

const Header = ({ product, copy }: HeaderProps) => {
  const mediaItem = getDefaultShopifyProductImage(product);
  
  return (
    <>
      <div className="product-image-wrapper">
        <a href={copy.seoPathname} target='_blank'>
          <GatsbyImage
            image={mediaItem.image.gatsbyImageData}
            alt={`${product.title}`}
            objectFit="contain"
            loading="eager"
          />
        </a>
      </div>

      <div>
        <div className="product-title-wrapper">
          <h2 className="product-title no-margins">
            <a href={copy.seoPathname} target='_blank'>
              {product.title}
            </a>
          </h2>
        </div>
      </div>
    </>
  )
}

type DealProductCardProps = {
  product: GatsbyShopifyStorefrontProduct
  copy: ProductCopy
  hidePrice: boolean,
  quantitySelectionEnabled?: boolean
  onVariantSelect: (productId: string, variant: ShopifyProductVariant, quantity: number) => void
  selectionMap: PickyStorySelectionMap | null
}
export function BundleBuilderProductCard({
  product,
  onVariantSelect,
  copy,
  hidePrice,
  quantitySelectionEnabled,
  selectionMap
}: DealProductCardProps) {
  if (!copy || !product) return null

  const {
    availableVariants,
    setSelectedVariant,
    selectedVariant
  } = useBundleVariants(product, copy);

  const [currentQuantity, setCurrentQuantity] = useState<number>(1);

  const isSelected = selectionMap && selectionMap[fetchIdFromGlobalId(product.id)];

  return (
    <BundleBuilderCollectionCard 
      data-testid="product-card" 
      className="product-preview" 
      hidePrice={hidePrice}
    >
      <div className="product-wrapper">
        <div>
          <Header product={product} copy={copy} />
          <p className="product-description">{copy.shortDescription}</p>
        </div>
        <div className="pricing">
          <div className={`
            product-selection-wrapper
            ${quantitySelectionEnabled ? 'quantity-selection-enabled' : ''}
          `}>
            <VariantSelector
              variants={availableVariants}
              setSelectedVariant={(variant) => {
                setSelectedVariant(variant);
              }}
              hidePrice={hidePrice}
              selectedVariant={selectedVariant}
              size="small"
              type="input"
            />
            {quantitySelectionEnabled && (
              <QuantitySelector 
                selectedVariant={selectedVariant}
                currentQuantity={currentQuantity}
                setCurrentQuantity={setCurrentQuantity}
                hidePrice={true}
              />
            )}
            <Button 
              data-testid="add-selection"
              className="full-width"
              onClick={() => {
                onVariantSelect(
                  String(product.id),
                  selectedVariant,
                  currentQuantity
                )
              }} 
              disabled={(selectedVariant.availableForSale) && isSelected}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </BundleBuilderCollectionCard>
  )
}

const BundleBuilderCollectionCard = styled(BundleCollectionCard)`
  .product-wrapper {
    height: 100%;
  }

  .product-title-wrapper .product-title {
    font-size: 1.6rem;
    color: var(--primaryColor);
  }

  .product-wrapper .product-description {
    min-height: auto;
    padding-bottom: .5rem;
  }

  .product-single-variant {
    padding: 0 2rem;
    .variant-text {
      font-size: 1.5rem;
    }
  }

  button {
    margin-top: 1.2em;
  }

  @media(max-width: 768px) {
    .product-title-wrapper .product-title {
      font-size: 1.4rem;
    }

    .product-description {
      min-height: auto;
      display: block;
      line-height: 1.8rem;
      font-size: 1.2rem; 
    }

    .variant-select-input, button {
      font-size: 1.2rem;
    }

    .variant-select-input {
      padding: .5rem 3rem .5rem 2rem;
    }

    button {
      padding: .5rem 2rem .5rem 2rem;
    }

    .variant-select-input {
      margin-top: 0;
    }

    .product-single-variant {
      display: none;
    }
  }
`