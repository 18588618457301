import { FragmentHelpers } from "../../types"

export const APPLIED_GIFT_CARD_FRAGMENT: FragmentHelpers = {
  fragment: `
    fragment AppliedGiftCardFragment on AppliedGiftCard {
      amountUsed {
        amount
        currencyCode
      }
      amountUsedV2: amountUsed {
        amount
        currencyCode
      }
      balance {
        amount
        currencyCode
      }
      balanceV2: balance {
        amount
        currencyCode
      }
      presentmentAmountUsed {
        amount
        currencyCode
      }
      id
      lastCharacters
    }
  `,
  ref: '...AppliedGiftCardFragment'
}