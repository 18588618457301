import { PRODUCT_FRAGMENT } from "./fragments/product-fragment";

export const QUERY_PRODUCT_BY_ID = `
  query ProductById($id: ID!) {
    node(id: $id) {
      ${PRODUCT_FRAGMENT.ref}
    }
  }

  ${PRODUCT_FRAGMENT.fragment}
`