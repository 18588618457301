import { trackEvent, TrackFunction } from "analytics"
import { ShoppingEventInputs, SHOPPING_EVENT_NAMES } from "."
import { ProductSharedProps } from "./types"

const track: TrackFunction<SHOPPING_EVENT_NAMES.PRODUCT_SHARED, ShoppingEventInputs[SHOPPING_EVENT_NAMES.PRODUCT_SHARED]> = async (
  eventName,
  properties
) => {
  await trackEvent(eventName, properties)
}

export const trackProductShared = async (properties: ProductSharedProps) => {
  await track(
    SHOPPING_EVENT_NAMES.PRODUCT_SHARED, 
    properties
  )
}