import React from "react";
import { ShopifyCopyStorefrontProduct } from "utilities";
import styled from "styled-components";
import { below } from "styles";
import { PickyStorySelectionMap } from "../../../../lib/pickystory";
import { BundleBuilderProductCard } from "./BundleBuilderProductCard";
import { ShopifyProductVariant } from "lib/shopify/storefront-api-client/types/custom.types";

type BundleProductListOverviewProps = {
  products: (ShopifyCopyStorefrontProduct | null)[],
  onVariantSelect: (globalProductId: string, variant: ShopifyProductVariant, quanity: number) => void,
  selectionMap: PickyStorySelectionMap | null
  enableQuantitySelection?: boolean
}

export const BundleBuilderProductListOverview = (
  {
    products,
    onVariantSelect,
    selectionMap,
    enableQuantitySelection
  }: BundleProductListOverviewProps
) => {
  return (
    <BundleProductListOverviewContainer className='Section--product-bundle-builder'>
      <div data-testid="bundle-product-overview">
        <BundleCardList 
          productLength={products.length} 
          data-testid="bundle-builder-product-card-list"
        >
          {products.map((product) => (product &&
            <BundleBuilderProductCard
              product={product}
              copy={product.copy}
              onVariantSelect={onVariantSelect}
              key={product.id}
              hidePrice={true}
              selectionMap={selectionMap}
              quantitySelectionEnabled={Boolean(enableQuantitySelection)}
            />
          ))}
        </BundleCardList>
      </div>
    </BundleProductListOverviewContainer>
  )
}

const BundleProductListOverviewContainer = styled.div`
  margin-bottom: 4em; 

  .deal-price-text {
    font-size: var(--largestFontSize);
    .compare-at-price {
      text-decoration: line-through;
      display: inline-block;
      margin-right: .5em;
      margin-bottom: 1em;
    }
  }

  .variant-select-input, 
  .product-single-variant {
    margin-bottom: 1rem;
  }

  .variant-select-input, {
    padding: 5px 30px 5px 20px;
    font-size: 1.4rem;
  }

  .add-to-cart {
    max-width: 480px;
    margin: 0 auto;
    p:first-child {
      margin-top: 0;
    }
  }

  ${below.small`
    h1 {
      font-size: var(--h2);
    }
  `}
`

const BundleCardList = styled.div<{ productLength: number }>`
  width: 100%;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 991px) {
    gap: 1rem;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 380px) {
    grid-template-columns: 1fr;
  }
`