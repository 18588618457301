import React from 'react'
import { SanitySection } from 'components/sections'
import { Grid, Card } from 'styles'
import { Button, PortableText } from 'components/elements'
import { Link } from 'gatsby'
import { CTA, ICard } from 'types/misc'

/**
 * @prop title main title of all cards
 * @prop description an optional description
 * @prop cta for all cards
 * @prop cards an array with the cards
 **/

type Props = {
  title: string
  description: any[]
  cards: ICard[]
  cta: CTA
  borderedCards?: boolean
}

const SanityCards = ({
  title,
  borderedCards,
  description,
  cards,
  cta,
}: Props) => {
  return (
    <SanitySection theme="secondary">
      <h2 className="center-text">{title}</h2>
      <div className="center-text">
        {description && <PortableText _rawContent={description} />}
      </div>
      <div className="margins">
        <Grid
          className={
            borderedCards
              ? ''
              : 'Section--padding-bottom__none'
          }
          cols={[1, 1, 3]}
        >
          {cards.map(({ title, paragraph }) =>
            borderedCards ? (
              <Card key={title}>
                <h3 className="accent-text">{title}</h3>
                <p>{paragraph}</p>
              </Card>
            ) : (
              <div key={title}>
                <h4 className="no-margins">{title}</h4>
                <p>{paragraph}</p>
              </div>
            )
          )}
        </Grid>
      </div>
      {cta && (
        <div className="center-text margins">
          <Button as={Link} to={cta.buttonLink}>
            {cta.buttonText}
          </Button>
        </div>
      )}
    </SanitySection>
  )
}

export default SanityCards
