import { useEffect, useState } from 'react'
import { useCheckout } from 'global'
import { fetchIdFromGlobalId } from 'utilities'

export const skusExtend = [
  '10001-misc-elec-base-replace-1y',
  '10001-misc-elec-base-replace-2y',
  '10001-misc-elec-base-replace-3y',
  'B0-HGGEN-1y',
  'B0-HGGEN-2y',
  'B0-HGGEN-3y',
]

export const extendIsAlreadyInCart = (variantId: string): boolean => {
  const strippedId = fetchIdFromGlobalId(variantId);
  const { checkout } = useCheckout()
  const [isInCart, setIsInCart] = useState(false)

  useEffect(() => {
    if (checkout) {
      const found = checkout.lineItems.some((lineItem) =>
        lineItem.customAttributes.some((attr) => attr.value === strippedId)
      )
      setIsInCart(found)
    }
  }, [checkout])

  return isInCart
}
