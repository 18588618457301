import { DiscountResponse, fetchDiscountByCode } from "lib/retail-api";
import { useEffect, useState } from "react";

export const useShopifyDiscount = (discountCode?: string) => {
  const [shopifyDiscount, setShopifyDiscount] = useState<DiscountResponse|null>(null)

  useEffect(() => {
    if (!discountCode) return;

    const fetchData = async () => {
      try {
        const discount = await fetchDiscountByCode(discountCode);
        
        if (discount) {
          setShopifyDiscount(discount)
        } 
      } catch (error) {
        console.error(error)
        return;
      }
    }
    fetchData()
  }, [discountCode])

  return shopifyDiscount as DiscountResponse | null;
}