import { ReactNode } from 'react'
import ReactDOM from 'react-dom'

const createUniversalPortal = (children: ReactNode, id = 'modal-root') => {
  if (!(typeof window !== `undefined` && window?.document.createElement)) {
    return null
  }
  let rootElement = document.getElementById(id)
  if (!rootElement) {
    rootElement = document.createElement(`div`)
    rootElement.setAttribute(`id`, id)
    document.body.appendChild(rootElement)
  }
  return ReactDOM.createPortal(children, rootElement)
}

export const Portal = ({ children, id }: { children: ReactNode, id?: string }) =>
  createUniversalPortal(children, id)
