import { Modal } from "components/elements";
import { useFetchShopifyProductByHandle, useWindowWidth } from "hooks";
import React from "react";
import { ProductCopy, VariantSelectorType } from "types/misc";
import { transformGatsbyShopifyVariants, transformShopifyStorefrontImagesToGatsbyImage } from "utilities";
import { ProductOverview } from "../ProductOverview";
import { Link, navigate } from "gatsby";
import styled from "styled-components";

type Props = {
  productPage: ProductCopy
  bannerText?: string
  isModalActive: boolean
  onOutOfStock?: () => any
}
export const ModalUpsell = ({ 
  productPage, 
  bannerText, 
  isModalActive,
  onOutOfStock
}: Props) => {
  const shopifyProduct = useFetchShopifyProductByHandle(productPage.handle);
  const windowWidth = useWindowWidth();

  if (!shopifyProduct) {
    return null;
  }

  if (!shopifyProduct.availableForSale) {
    if (typeof onOutOfStock === "function") onOutOfStock()
    return null;
  }

  const shopifyProductTransformed = transformGatsbyShopifyVariants(
    transformShopifyStorefrontImagesToGatsbyImage(shopifyProduct)
  )

  if (!shopifyProductTransformed) return null;

  const variantSelector = productPage.variantSelector === 'buttons' 
    ? 'input' 
    : productPage.variantSelector as VariantSelectorType;

  const productPageTransformed = {
    ...productPage,
    extendEnabled: false,
    affirmEnabled: false,
    topBannerText: undefined,
    variantSelector,
    upsellModalEnabled: false
  }

  return (
    <Modal
      maxWidth={windowWidth && windowWidth > 1199 ? "60%" : "95%"}
      isActive={isModalActive}
      closeAction={() => navigate('/cart/')}
      modalContentStyles={{
        padding: 0,
        overflow: 'hidden',
        border: '1px solid black'
      }}
      innerModalContentStyles={{
        padding: 0
      }}
      closeButtonColor="white"
    >
      <>
        <Banner>
          <h3>{bannerText || 'You may also like...'}</h3>
        </Banner>
        <ProductOverviewWrapper>
          <ProductOverview
            product={shopifyProductTransformed}
            productMedia={shopifyProductTransformed.media}
            productPage={productPageTransformed}
            addToCartTestId="modal-upsell-buy-button"
          />
        </ProductOverviewWrapper>
        <BottomLinks className="center-text">
          <Link to={productPage.seoPathname} className="display-block bottom-padding">Learn more</Link>
          <Link to="/cart/" className="display-block bottom-padding">No thanks</Link>
        </BottomLinks>
      </>
    </Modal>
  )
}

const Banner = styled.div`
  text-align: center;
  padding: 1.5rem;
  background-color: var(--darkNavy);
  color: white;
  margin-bottom: 2rem;
  h3 {
    margin: 0;
  }

  @media (max-width: 900px) {
    padding: 2rem 1.5rem;
    h3 {
      font-size: 2rem;
    }
  }

  @media (max-width: 600px) {
    h3 {
      font-size: 1.6rem;
      margin-left: 2rem;
    }
  }
`

const ProductOverviewWrapper = styled.div`
  .product-overview {
    h1 {
      font-size: 3rem;
      margin: 0;
    }
    .variant-select-input {
      margin-bottom: 1rem;
    }

    p {
      margin: calc(var(--bodyTextMargins) * .5);
    }
  }

  @media (max-width: 900px) {
    > div {
      margin-bottom: 0;
    }
    margin: 2rem 2rem 0 2rem;
    .carousel-wrapper {
      margin-top: 0;
    }
  }
`

const BottomLinks = styled.div` 
  display flex;
  justify-content: center;
  a {
    text-decoration: underline;
  }
  a:nth-child(2) {
    margin-left: 10px;
  }
`