import { CHECKOUT_FRAGMENT } from "./fragments/checkout-fragment";
import { CHECKOUT_USER_ERROR_FRAGMENT } from "./fragments/checkout-user-error-fragment";
import { USER_ERROR_FRAGMENT } from "./fragments/user-error-fragment";


export const CHECKOUT_CREATE_MUTATION = `
  ${USER_ERROR_FRAGMENT.fragment}
  ${CHECKOUT_USER_ERROR_FRAGMENT.fragment}
  ${CHECKOUT_FRAGMENT.fragment}

  mutation ($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      userErrors {
        ${USER_ERROR_FRAGMENT.ref}
      }
      checkoutUserErrors {
        ${CHECKOUT_USER_ERROR_FRAGMENT.ref}
      }
      checkout {
        ${CHECKOUT_FRAGMENT.ref}
      }
    }
  }
`