import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { below } from 'styles'
import { Link } from 'gatsby'
import { Article, ArticleTopic } from 'types/misc'
import { SanityArticleTopic } from './SanityArticleTopic'
import { useCustomDate, useWindowWidth } from 'hooks'
import { sortTopicsAlpha } from 'utilities'

type Props = {
  article: Article
  logoRachio: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

type TopicsProps = {
  topics: ArticleTopic[],
  title: string
}
const Topics = ({ topics, title }: TopicsProps) => {
  const windowWidth = useWindowWidth();
  const topicLength = windowWidth && windowWidth < 400 ? 3 : 4;
  return (
    <div className="topics">
      {sortTopicsAlpha(topics).slice(0, topicLength).map(topic => (
        <SanityArticleTopic 
          key={`${title} - ${topic.id}`}
          topic={topic}
          style={{ marginRight: '3px' }} 
        />
      ))}
    </div>
  )
}

export const SanityArticleCard = ({ article, logoRachio }: Props) => {
  const { 
    seoDescription, 
    author, 
    mainImage, 
    mainImageAltText, 
    title, 
    slug,
    topics,
    publishedAt
  } = article

  const publishedAtParsed = useCustomDate(publishedAt);

  const image = mainImage?.asset.gatsbyImageData
    ? mainImage.asset.gatsbyImageData
    : logoRachio.childImageSharp.gatsbyImageData
  return (
    <CardContainer data-testid="article-card">
      <Link to={`/blog/${slug}/`}>
        <div className="image-container">
          <GatsbyImage
            image={image}
            alt={mainImageAltText ? mainImageAltText : `${title} image`}
            objectFit="cover"
          />
        </div>
      </Link>
      <div className="card-body">
        <div>
          <Link to={`/blog/${slug}/`} className='article-title'>
            <h3>{title}</h3>
          </Link>
          <div className="about-article">
            <div className="author">
              <div className='h6'>
                <span>
                  <span className='sr-only'>Author name</span>
                  <span>{author?.name || 'Team Rachio'}</span>
                </span>
                {publishedAtParsed && (
                  <>
                    {" - "}
                    <span>
                      <span className='sr-only'>Published date</span>
                      <span>{publishedAtParsed}</span>
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {topics && topics.length > 0 && (
          <Topics 
            topics={topics} 
            title={title}
          />
        )}

        <div className="preview-content">
          <div>
            {seoDescription && seoDescription}
          </div>
        </div>
      </div>
    </CardContainer>
  )
}

const CardContainer = styled.div`
  background: white;
  isolation: isolate;
  border-radius: var(--cardRadius);
  overflow: hidden;
  .card-body {
    padding: calc(var(--basePadding) * .6);
  }
  .article-title {
    color: var(--darkNavy);
  }
  .image-container {
    height: 225px;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  h3 {
    margin: 0 0 calc(var(--margin) / 2);
    font-size: 19px;
    transition: all 0.3s;
  }
  .about-article {
    display: flex;
    align-items: center;
  }
  .about-article .author {
    line-height: normal;
    margin-right: 10px;
  }
  .about-article .h6 {
    margin: 0;
    display: inline;
    font-size: 12px;
    span {
      color: var(--darkGray);
    }
  }
  .divider {
    border-bottom: solid 2px var(--darkGray);
    margin-bottom: var(--margin);
  }
  .preview-content {
    font-size: 14px;
    margin: 1rem 0;
  }
  :hover {
    .card-body h3 {
      color: var(--primaryBlue);
    }
  }
  .topics {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    span {
      margin-bottom: 3px;
    }
  }
  ${below.medium`
    h3, .about-article{
      min-height: auto;
    }
  `};
`
