import { Button } from "@rachio-npm/rachio-retail-common"
import { ProductFormMini } from "components/product"
import { Link } from "gatsby"
import { useAddToCart, useFetchShopifyProductByHandle } from "hooks"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ProductCopy } from "types/misc"

type Props = {
  productPage: ProductCopy
  customButtonText: string,
  customButtonTheme: string,
  noRedirectToCart?: boolean,
  goToCheckout?: boolean
  quantitySelectionEnabled?: boolean
}
export const Product = ({ 
  productPage, 
  customButtonText, 
  customButtonTheme, 
  noRedirectToCart,
  goToCheckout,
  quantitySelectionEnabled
}: Props) => {
  const shopifyProduct = useFetchShopifyProductByHandle(productPage.handle);
  const [selectedVariant, setSelectedVariant] = useState(shopifyProduct?.variants[0]);
  const { addToCart, buttonText, buttonTheme, currentQuantity, setCurrentQuantity } = useAddToCart({ 
    selectedVariant, 
    customButtonText, 
    customButtonTheme,
    noRedirectToCart,
    goToCheckout,
    product: {
      ...shopifyProduct,
      title: shopifyProduct?.title || ''
    }
  });

  useEffect(() => {
    if (!shopifyProduct) return;
    setSelectedVariant(shopifyProduct.variants[0])
  }, [shopifyProduct])

  if (!selectedVariant || !shopifyProduct) return null;

  if (!shopifyProduct.availableForSale) {
    return (
      <Button as={Link} to={productPage.seoPathname}>
        Learn More
      </Button>
    )
  }

  return (
    <Container singleVariant={shopifyProduct.variants.length === 1}>
      <ProductFormMini 
        shopifyProductLike={shopifyProduct}
        setSelectedVariant={setSelectedVariant}
        selectedVariant={selectedVariant}
        addToCart={addToCart}
        buttonText={buttonText}
        buttonTheme={buttonTheme || 'dark'}
        hidePrice={false}
        testId="split-section-add-to-cart-cta"
        singleVariantLayout={true}
        quantitySelectionEnabled={quantitySelectionEnabled}
        currentQuantity={currentQuantity}
        setCurrentQuantity={setCurrentQuantity}
      />
    </Container>
  )
}

const Container = styled.div<{ singleVariant: boolean }>`
  select {
    margin-bottom: 0;
  }
  
  button {
    margin: 1.5rem 0 3rem .5rem;
    &:hover {
      color: white;
    }
  }
  
  .add-to-cart {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin: 0 auto;
    .single-variant {
      font-weight: bold;
      padding-top: .5rem;
      background: var(--white);
      border: 1px solid var(--lineColor);
      border-radius: 2.5rem;
      margin-top: 1rem;
    }
  }
`