import React from 'react'
import styled, { css } from 'styled-components'
import { below, media, sizes } from 'styles'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { SectionPaddingSize, SectionSize, SectionTheme, TextAlign } from 'types/misc'
import { SectionOuter } from './sectionStyle'
import { SectionLeft, SectionRight } from './SanitySplit'
import SimpleList from './SanitySplit/SimpleList'
import { useWindowWidth } from 'hooks'

type ImageContainerProps = {
  image: {
    gatsbyImageData: IGatsbyImageData
  }
  mobileImage?: {
    gatsbyImageData: IGatsbyImageData
  }
  alt: string
}
export const ImageContainer = ({
  image,
  mobileImage,
  alt,
}: ImageContainerProps) => {
  const width = useWindowWidth();
  const primaryImage = mobileImage && Number(width) <= sizes.medium
    ? mobileImage
    : image;

  return (
    <div
      className="media-container"
    >
      <GatsbyImage
        style={{ height: '100%' }}
        image={primaryImage.gatsbyImageData}
        alt={alt}
        objectFit="cover"
      />
    </div>
  )
}

type Props = {
  isMobileReverse?: boolean
  image: {
    gatsbyImageData: IGatsbyImageData
  }
  mobileImage?: {
    gatsbyImageData: IGatsbyImageData
  }
  contentBackgroundImage?: {
    gatsbyImageData: IGatsbyImageData
  }
  size?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
  isImageFirst?: boolean
  alt: string
  id?: string
  title: string
  subtitle?: string
  _rawParagraph?: any[]
  textAlign?: TextAlign
  buttonLink?: string
  buttonText?: string
  buttonTheme?: string
  secondaryButtonLink?: string
  secondaryButtonText?: string
  secondaryButtonTheme?: string
  list: string[]
  listBulletsType?: string
  listHasMargin?: boolean
  listHasAccentText?: boolean
  theme?: SectionTheme
  isTitleH1?: boolean
}

const SanityVanitySection = ({
  title = '',
  subtitle = '',
  isMobileReverse = false,
  isImageFirst = true,
  image,
  mobileImage,
  contentBackgroundImage,
  _rawParagraph,
  textAlign,
  alt,
  buttonLink = '',
  buttonText = '',
  buttonTheme = '',
  secondaryButtonLink = '',
  secondaryButtonText = '',
  secondaryButtonTheme = '',
  list,
  listBulletsType,
  listHasMargin = false,
  listHasAccentText = false,
  size = '',
  theme = 'gray',
  isTitleH1 = false
}: Props) => {
  const nodeImg = (
    <ImageContainer
      image={image}
      mobileImage={mobileImage}
      alt={alt}
    />
  )

  let nodeInfo = (
    <TextNode className={`
      Section--padding-${size || 'default'}
      ${contentBackgroundImage && 'has-background-image'}
    `} style={{ textAlign }}>
      {contentBackgroundImage && (
        <ImageContainer
          image={contentBackgroundImage}
          alt="section background"
        />
      )}
      <div className='inner-wrap'>
        <div>
          <SimpleList
            subtitle={subtitle}
            title={title}
            _rawParagraph={_rawParagraph}
            list={list}
            listBulletsType={listBulletsType}
            listHasAccentText={listHasAccentText}
            listHasMargin={listHasMargin}
            buttonText={buttonText}
            buttonLink={buttonLink}
            buttonTheme={buttonTheme}
            secondaryButtonLink={secondaryButtonLink}
            secondaryButtonText={secondaryButtonText}
            secondaryButtonTheme={secondaryButtonTheme}
            textAlign={textAlign}
            isTitleH1={isTitleH1}
          />
        </div>
      </div>
    </TextNode>
  )

  const Content = (
    <>
      <SectionLeft
        className={`
          Section--left
          ${isImageFirst ? 'Section--media' : 'Section--text'}
        `}
      >
        {isImageFirst ? nodeImg : nodeInfo}
      </SectionLeft>
      <SectionRight className={`
        Section--right
        ${isImageFirst ? 'Section--text' : 'Section--media'}
      `}>
        {isImageFirst ? nodeInfo : nodeImg}
      </SectionRight>
    </>
  )
  return (
    <VanitySplitSection
      className={`
        Section Section--theme__${theme} Section--vanity-split
        ${isMobileReverse ? 'Section__mobile-reverse' : ''}
      `}
      isMediaFirst={isImageFirst}
    >
      {Content}
    </VanitySplitSection>
  )
}

export const TextNode = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    line-height: 25px;
    font-size: 15px;
  }
  h1, 
  h2 {
    font-size: var(--h2);
  }
  h5 {
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: var(--h6);
  }

  p {
    margin: 15px 0;
  }

  &.has-background-image {
    .media-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .inner-wrap {
      z-index: 2;
      color: white;
      ${below.small`
        text-align: center;
      `}
    }

    ${below.small`
      height: 35vh;
    `}
  }

  &.Section--padding-default {
    padding: calc(var(--baseSectionPadding) * .8);
  }

  &.Section--padding-small {
    padding: 20px;
  }

  @media (min-width: 1600px) {
    p {
      font-size: 17px;
    }
  }

  ${media.xLarge`
    &.Section--padding-small {
      padding: calc(var(--baseSectionPadding) * .4);
    }
  `}

  ${below.xLarge`
    p {
      line-height: 20px;
    }
    &.Section--padding-default {
      padding: calc(var(--baseSectionPadding) * .4);
    }
  `}

  ${below.large`
    &.Section--padding-small {
      padding: 15px;
      p {
        margin: 10px 0;
      }
    }
  `}


  ${below.mediumLarge`
    &.Section--padding-default,
    &.Section--padding-small {
      padding: calc(var(--baseSectionPadding) * .2);
    }

    h1, 
    h2 {
      font-size: var(--h3);
    }

    li {
      margin: 15px 0;
    }
  `}

  ${below.medium`
    &.Section--padding-default,
    &.Section--padding-small {
      padding: calc(var(--baseSectionPadding) * .6);
    }
  `}

  @media (min-width: 1024px) and (max-width: 1100px) {
    h5 {
      margin-bottom: 0;
    }
  }
`

export const VanitySplitSection = styled(SectionOuter)`
  display: flex;
  .Section--left, .Section--right {
    height: 80vh;
    overflow: hidden;
    width: 50vw;
    position: relative;
  }

  ${({ isMediaFirst }: { isMediaFirst: boolean }) => css`
    .Section--media {
      order: ${isMediaFirst ? 1 : 2};
    }

    .Section--text {
      order: ${isMediaFirst ? 2 : 1};
    }
  `}

  .Section--left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .media-container {
    width: 100%;
    height: 100%;
    @media screen and (max-width: ${sizes.medium}px) {
      display: flex;
      justify-content: center;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
  }
  @media (min-width: 1440px) {
    .Section--left, .Section--right {
      height: 100vh;
    }
  }
  @media (min-width: 1920px) {
    .Section--left, .Section--right {
      height: 85vh;
    }
  }
  ${below.large`
    .Section--left, .Section--right {
      height: 60vh;
    }
  `}

  ${below.medium`
    h1, 
    h2 {
      font-size: var(--h2);
    }
    flex-wrap: wrap;
    .Section--left, .Section--right {
      width: 100vw;
    }
    .Section--media {
      order: 1;
      height: auto;
    }
    .Section--text {
      order: 2;
      height: 50vh;
    }
    &.Section__mobile-reverse {
      .Section--text {
        order: 1;
      }
    }
  `}

  ${below.small`
    .Section--media, .Section--text {
      height: auto;
      .has-background-image {
        padding: calc(var(--baseSectionPadding) * .3);
      }
    }
    
    .Section--text {
      padding: 60px 0;
    }
  `}
`

export default SanityVanitySection;