import React from 'react'
import {PortableText as ReactPortableText } from '@portabletext/react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { TextBubble, below } from 'styles'
import { SanityVideo } from './SanityVideo'
import { GatsbyShopifyStorefrontProductLoader, ProductRating } from 'components/product'
import { ProductUpsell } from 'components/product'
import { Image } from 'types/misc'
import { Rating } from 'react-simple-star-rating'

type Props = {
  _rawContent: any[]
}

const LinkMark = ({ link, linkText }: { link: string, linkText: string }) => {
  if (!link) return <>{linkText}</>
      
  if (link.startsWith('http'))
    return (
      <a
        style={{ textDecoration: 'underline' }}
        target="_blank"
        rel="noreferrer noopener"
        href={link}
      >
        {linkText}
      </a>
    )

  return (
    <Link style={{ textDecoration: 'underline' }} to={link}>
      {linkText}
    </Link>
  )
}

type ImageTypeProps = {
  gatsbyImageData: IGatsbyImageData,
  sanityImageAsset: Image
  objectFit?: GatsbyImageProps["objectFit"]
  className?: string
  customAltText?: string
}
const ImageType = ({ gatsbyImageData, sanityImageAsset, customAltText, objectFit }: ImageTypeProps) => {
  const { height, width, altText, title, description, originalFilename } = sanityImageAsset.asset;
  const isPortraitAspectRatio = (height / width) > 2;

  return (
    <div className={`article-image-container ${isPortraitAspectRatio ? 'has-background' : ''}`}>
      <GatsbyImage 
        image={gatsbyImageData} 
        alt={customAltText || altText || title || originalFilename} 
        objectFit={objectFit} 
      />
      {(title || description) && (
        <div className="center-text caption">
          <div><em className='caption-title'>{title}</em></div>
          <div><em>{description}</em></div>
        </div>
      )}
    </div>
  )
}

const sanityConfig = {
  projectId: String(process.env.GATSBY_SANITY_PROJECT),
  dataset: String(process.env.GATSBY_SANITY_DATASET),
}

const portableReactComponents = {
  types: {
    image: ({ value }) => {
      const imageData = getGatsbyImageData(value.asset.id, {}, sanityConfig)
      
      if (imageData) {
        return (
          <ImageType 
            gatsbyImageData={imageData} 
            customAltText={value.altText || value.title}
            sanityImageAsset={value}
          />
        )
      }
      
      return <></>
    },
    customBlogImage: ({ value }) => {
      const imageData = getGatsbyImageData(
        value.image.asset.id,
        {},
        sanityConfig
      )
      if (!imageData) return <></>
      if (value.floatingDirection == 'right') {
        return (
          <FloatRight>
            <ImageType 
              gatsbyImageData={imageData}
              customAltText={value.titleImage}
              sanityImageAsset={value.image}
              objectFit='contain'
            />
          </FloatRight>
        )
      }
      return (
        <FloatLeft>
          <ImageType 
            gatsbyImageData={imageData} 
            customAltText={value.titleImage}
            sanityImageAsset={value.image}
            objectFit='contain'
          />
        </FloatLeft>
      )
    },
    customImage: ({ value }) => {
      const imageData = getGatsbyImageData(value.asset.id, {}, sanityConfig)
      if (imageData)
        return (
          <ImageType 
            gatsbyImageData={imageData} 
            sanityImageAsset={value}
            customAltText={value.title}
          />
        )
      return <></>
    },
    blogVideo: ({ value }) => {      
      return (
        <VideoWrapper>
          <SanityVideo 
            video={value.reference} 
          />
        </VideoWrapper>
      )
      
    },
    productRating: ({ value }) => {
      return (
        <ProductRating
          rating={value.reference.productRating}
          ratingText={value.reference.productRatingText}
        />
      )
    },
    blogProductUpsell: ({ value }) => {
      return (
        <GatsbyShopifyStorefrontProductLoader 
          productPage={value.reference.product}
          render={(product) => (
            <ProductUpsell 
              shopifyStorefrontCopyProduct={product} 
              noRedirectToCart={true} 
              bordered={true}
            />
          )}
        />
      )
    },
    textDisclaimer: ({ value }) => {
      return (
        <TextBubble>
          <p className='no-margins'>
            {value.text}
            {
              value.link 
                ? (
                  <>
                    {" "}
                    <LinkMark 
                      link={value.link} 
                      linkText={value.linkText} 
                    />
                  </>
                ) 
              : null
            }
          </p>
        </TextBubble>
      )
    }
  },
  block: {
    h4: ({ children }) => {
      return <h4 className="top-margin-large">{children}</h4>
    },
    h3: ({ children }) => {
      return <h3 className="top-margin-large">{children}</h3>
    },
  },
  marks: {
    strong: ({ children }) => {
      return <strong>{children}</strong>
    },
    link: ({ value: { href }, children }) => (
      <LinkMark link={href} linkText={children} />
    ),
  },
  list: {
    bullet: ({ children }: any) => {
      return <ul className="disc">{children}</ul>
    },
    number: ({ children }: any)  => {
      return <ol className="disc">{children}</ol>
    }
  },
}

export const PortableText = ({ _rawContent }: Props) => {
  return (
    <CustomCSS>
      <ReactPortableText value={_rawContent} components={portableReactComponents} />
    </CustomCSS>
  )
}

const FloatLeft = styled.div`
  width: 300px;
  float: left;
  margin-right: var(--margin);
  ${below.medium`
    width: 100%;
    margin-right: 0;
  `};
`
const FloatRight = styled.div`
  width: 300px;
  float: right;
  margin-left: var(--margin);
  ${below.medium`
    width: 100%;
    margin-left: 0;
  `};
`
const CustomCSS = styled.div`
  img {
    border-radius: var(--cardRadius);
  }
  h4 {
    margin: var(--margin) 0;
  }
  h1, 
  h2 {
    margin: var(--largeMargin) 0 var(--margin);
  }

  .article-image-container {    
    text-align: center;
    &.has-background {
      background: #ddd;
      padding: 2rem 0;
      margin: 1rem auto;
    }
    .caption {
      max-width: 60rem; 
      margin: 1.5rem auto 0;
      line-height: 2.2rem;
      .caption-title {
        font-size: 1.5rem;
      }
    }
  }

  .gatsby-image-wrapper img {
    max-height: 600px;
  }
`

const VideoWrapper = styled.div`
  .video-iframe, 
  video {
    width: 100%;
  }

  .video-iframe {
    height: 400px;
  }

  video {
    object-fit: fill;
  }
  
  ${below.large`
    .video-iframe {
      max-width: 600px;
      margin: 0 auto; 
      height: 340px;
    }
  `}

  ${below.small`
    .video-iframe {
      height: 290px;
    }
  `}

  @media (max-width: 480px) {
    .video-iframe {
      height: 230px;
    }
  }
`