import React from 'react'
import { Cols, Wrapper, Grid, media } from 'styles'
import styled from 'styled-components'
import { ICard, SectionTheme } from 'types/misc'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
import { PortableText } from 'components/elements'
const CustomGatsbyImage = loadable(() => import('components/elements'), {
  resolveComponent: (components) => components.CustomGatsbyImage,
})
type Props = {
  title: string
  subtitle: string
  _rawParagraph: any[]
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  cards: ICard[]
  theme?: SectionTheme
}

const SanitySplitCards = ({
  cards,
  title,
  subtitle,
  _rawParagraph,
  image,
  theme,
}: Props) => {
  return (
    <div className={theme === 'gray' ? 'gray-bg' : ''}>
      <Wrapper>
        <Cols className="container">
          <div>
            <h2>{title}</h2>
            <h3 className="accent-text">{subtitle}</h3>
            <PortableText _rawContent={_rawParagraph} />
          </div>
          <CustomGatsbyImage image={image.asset.gatsbyImageData} alt={title} />
        </Cols>

        <ElementsGrid cols={[1, 2, 4]}>
          {cards.map(({ alt, image, paragraph, title }) => {
            return (
              <div key={title}>
                <CustomGatsbyImage
                  image={image.asset.gatsbyImageData}
                  alt={alt}
                />
                <h5 className="accent-text">{title}</h5>
                <p>{paragraph}</p>
              </div>
            )
          })}
        </ElementsGrid>
      </Wrapper>
    </div>
  )
}

export default SanitySplitCards

const ElementsGrid = styled(Grid)`
  padding: 0 0 40px 0;
  ${media.medium`
    padding: 0 0 125px 0;
  `}
`
