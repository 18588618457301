import React, { Fragment } from 'react'
import { SanitySection } from 'components/sections'
import { PortableText } from 'components/elements'
import { SectionPaddingSize, SectionSize } from 'types/misc'

type Item = {
  title: string
  _rawParagraph: any[]
}

type Props = {
  title: string
  paragraph: string
  items: Item[]
  size?: SectionSize | ''
  paddingTop?: SectionPaddingSize | ''
  paddingBottom?: SectionPaddingSize | ''
}

const SanityList = ({
  title,
  paragraph,
  items,
  size = '',
  paddingTop = '',
  paddingBottom = '',
}: Props) => {
  return (
    <SanitySection
      width="tight"
      size={size}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <h2 className="center-text top-padding">{title}</h2>
      <div className="container">
        <p>{paragraph}</p>
        <div>
          {items.map(({ title, _rawParagraph }) => (
            <Fragment key={title}>
              <h3 className="no-margins">{title}</h3>
              <>
                <PortableText _rawContent={_rawParagraph} />
              </>
            </Fragment>
          ))}
        </div>
      </div>
    </SanitySection>
  )
}

export default SanityList
