import React from "react";
import { YotpoWidget } from "../../lib/yotpo";

type Props = {
  widgetInstanceId: number
}
const SanityYotpoWidgetSection = ({ widgetInstanceId }: Props) => {
  return (
    <div className="sanity-yotpo-widget-section">
      <YotpoWidget widgetInstanceId={widgetInstanceId} />
    </div>  
  )
}

export default SanityYotpoWidgetSection;