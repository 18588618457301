import React, { ReactNode, createRef, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { Portal } from './Portal'
import { useScrollFreeze, useKeyDown } from 'hooks'
import { Icon } from './Icon'

interface ModalAnimationProps {
  isActive: boolean
  maxWidth?: string
  children: ReactNode
  closeAction: () => void
  modalContentStyles?: any
  innerModalContentStyles?: any
  closeButtonColor?: string
}

export function Modal({
  isActive,
  children,
  maxWidth = `var(--modalWidth)`,
  closeAction = () => {},
  modalContentStyles,
  innerModalContentStyles,
  closeButtonColor
}: ModalAnimationProps) {
  return (
    <Portal>
      <AnimatePresence>
        {isActive && (
          <InnerModal
            children={children}
            maxWidth={maxWidth}
            closeAction={closeAction}
            isActive={isActive}
            modalContentStyles={modalContentStyles}
            innerModalContentStyles={innerModalContentStyles}
            closeButtonColor={closeButtonColor}
          />
        )}
      </AnimatePresence>
    </Portal>
  )
}

const InnerModal = ({
  isActive,
  children,
  maxWidth,
  closeAction,
  modalContentStyles,
  innerModalContentStyles,
  closeButtonColor
}: ModalAnimationProps) => {
  const ref = createRef<HTMLDivElement>()
  const closeButtonRef = createRef<HTMLButtonElement>()
  useKeyDown((e) => {
    if (e.key === 'Escape') closeAction()
  })

  useScrollFreeze()

  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  })

  const styles = {
    ...modalContentStyles,
    pointerEvents: isActive ? 'all': 'none',
  }

  return (
    <div
      onClick={(event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        closeAction()
      }}
    >
      <ModalWrapper className='modal-wrapper' role="dialog" aria-modal="true">
        <Transport
          maxWidth={maxWidth}
          exit={{ opacity: 0, y: -40 }}
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          className='inner-modal'
        >
          <ModalContent 
            style={styles}
            data-testid="modal" 
            ref={ref} 
            className='modal-content'
          >
            {closeAction && (
              <CloseButton 
                onClick={closeAction} 
                className="close-modal-trigger"
                data-testid="close-modal-trigger"
                ref={closeButtonRef}
              >
                <Icon
                  name="remove"
                  color={closeButtonColor || "var(--lightGray)"}
                  style={{
                    width: '30px',
                    height: '30px',
                    marginBottom: 'var(--smallMargin)',
                  }}
                />
              </CloseButton>
            )}
            <div className='modal-inner-content' style={innerModalContentStyles}>{children}</div>
          </ModalContent>
        </Transport>
      </ModalWrapper>
      <Background
        as={motion.div}
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        onClick={closeAction}
      />
    </div>
  )
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: var(--basePadding);
  pointer-events: bounding-box;
  z-index: var(--modalLevel);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const ModalContent = styled.div`
  position: relative;
  --modalPadding: calc(var(--basePadding) / 2);
  padding: var(--modalPadding);
  border-radius: var(--cardRadius);
  background: white;
  box-shadow: var(--elevation-5);
  > div {
    padding: 0 var(--modalPadding) var(--modalPadding) var(--modalPadding);
    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
`

const CloseButton = styled.button`
  pointer-events: all;
  z-index: 1;
  position: absolute;
  top: calc(var(--basePadding) / 2);
  left: calc(var(--basePadding) / 2);
`

const Background = styled.div`
  background: rgba(0, 40, 58, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: all;
  width: 100%;
  height: 100%;
  z-index: calc(var(--modalLevel) - 1);
  cursor: pointer;
`

const Transport = styled(motion.div)`
  position: relative;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  min-width: 320px;
  max-width: ${({ maxWidth }: { maxWidth: string }) => maxWidth};
  @media(max-width: 480px) {
    max-width: 100%;
  }
`
