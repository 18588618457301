import React from 'react'
import styled from 'styled-components'

type RadioProps = {
  label?: string
  name?: string
  checked: boolean
  onClick?: () => void
  dataTestId?: string
  required?: boolean
  id?: string
}

const ButtonWrapper = styled.span`
  margin: 0;
  * {
    cursor: pointer;
  }
`

const RadioLabel = styled.label`
  width: 24px;
  height: 24px;
  margin: 0;
  border-radius: 50%;
  background: transparent;
  border: 2px solid var(--darkGray);
`

const RadioBtn = styled.input.attrs({
  type: 'radio',
})`
  opacity: 0;
  display: none;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  & + ${RadioLabel} {
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 4px;
      background: transparent;
    }
  }
  &:checked + ${RadioLabel} {
    border: 2px solid var(--primaryColor);
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 4px;
      background: var(--primaryColor);
    }
  }
`

export const RadioButton = ({
  checked,
  onClick = () => {},
  dataTestId = "radio",
  name = "",
  required = false,
  id,
}: RadioProps) => {
  return (
    <ButtonWrapper
      onClick={onClick}
      className="radio-button"
      data-testid="radio-wrapper"
    >
      <RadioBtn
        id={id}
        type="radio"
        checked={checked}
        data-testid={dataTestId}
        name={name}
        required={required}
      />
      <RadioLabel />
    </ButtonWrapper>
  )
}
