const SMART_HOSE_TIMER_SKUS = {
  VALVE: 'SHV01001',
  KIT: 'SHVK001'
}

const SMART_HOSE_TIMER_SHOPIFY_PRODUCT_HANDLES = {
  VALVE: 'rachio-smart-hose-timer-valve',
  KIT: 'rachio-smart-hose-timer',
}

const PRO_CONTROLLER_HANDLE = 'rachio-pro';

const PRO_CONTROLLER_SKU = '6ZULWC-PS';

const SMART_HOSE_TIMER_SKUS_MAP = {
  [SMART_HOSE_TIMER_SKUS.VALVE]: true,
  [SMART_HOSE_TIMER_SKUS.KIT]: true
}

const CONTROLLER_PRODUCT_SKUS = {
  FOUR_ZONE: '4ZULW-C',
  SIXTEEN_ZONE: '16ZULW-C',
  EIGHT_ZONE: '8ZULW-C'
}

const PRODUCT_ACCESSORY_SKUS = {
  WEATHERPROOF_ENCLOSURE: '16RACHBX'
}

const CONTROLLER_PRODUCT_SKUS_MAP = {
  [CONTROLLER_PRODUCT_SKUS.FOUR_ZONE]: true,
  [CONTROLLER_PRODUCT_SKUS.EIGHT_ZONE]: true,
  [CONTROLLER_PRODUCT_SKUS.SIXTEEN_ZONE]: true,
}

/**
 * Make sure not to include /products/rachio-3/ as we want all variants to be displayed
 */
const PRODUCT_PATHNAME_SKUS_FILTER = {
  '/products/rachio-3-4-zone/' : CONTROLLER_PRODUCT_SKUS.FOUR_ZONE,
  '/products/rachio-3-16-zone/' : CONTROLLER_PRODUCT_SKUS.SIXTEEN_ZONE
}

/**
 * Make sure not to include /products/rachio-3/ as we want all variants to be displayed
 */
 const PRODUCT_PATHNAME_SKUS_MAP = {
  [CONTROLLER_PRODUCT_SKUS.SIXTEEN_ZONE] : 'rachio-3-16-zone',
  [CONTROLLER_PRODUCT_SKUS.EIGHT_ZONE]: 'rachio-3'
}

const PARTNER_CONTROLLER_PRODUCT_SKUS = {
  WEATHERFLOW_TEMPEST_SINGLE_PURCHASE: 'WF-TWS01-R1',
  WEATHERFLOW_TEMPEST_BUNDLE: 'WF-TWS01-R2',
}

const PARTNER_PRODUCT_PATHNAMES = {
  WEATHERFLOW_TEMPEST: '/products/weatherflow-tempest/',
  ROOT_QUENCHER_WATERING_SYSTEM: '/products/root-quencher-watering-system/'
}

const PARTNER_PRODUCT_VARIANTS_BY_TITLE = {
  WEATHERFLOW_TEMPEST: {
    BUNDLE: 'Rachio Tempest Bundle',
    SINGLE_PURCHASE: 'Tempest Weather System'
  }
}

const PARTNER_PRODUCT_HANDLES = {
  WEATHERFLOW_TEMPEST: 'tempest-rachio-wx-system-1',
  ROOT_QUENCHER_WATERING_SYSTEM: 'root-quencher-1',
  KRAIN_NOZZLLES: 'krain-rotary-nozzle-adjustable',
  HUNTER_NOZZLLES: 'hunter-mp-rotator-nozzles'
}

const ACCESSORY_PRODUCT_HANDLES = {
  WATERPROOF_ENCLOSURE: 'waterproof-enclosure'
}

const CONTROLLER_PRODUCT_HANDLES = {
  RACHIO_THREE: 'rachio-3'
}

const FERTILIZER_PRODUCT_TAG = 'fertilizer';

const FERTILIZER_PRODUCT_VENDORS = ['Sunday', 'Andersons'];

module.exports = {
  CONTROLLER_PRODUCT_SKUS,
  CONTROLLER_PRODUCT_SKUS_MAP,
  PRODUCT_PATHNAME_SKUS_FILTER,
  PRODUCT_PATHNAME_SKUS_MAP,
  PARTNER_CONTROLLER_PRODUCT_SKUS,
  PARTNER_PRODUCT_PATHNAMES,
  PARTNER_PRODUCT_VARIANTS_BY_TITLE,
  PARTNER_PRODUCT_HANDLES,
  ACCESSORY_PRODUCT_HANDLES,
  CONTROLLER_PRODUCT_HANDLES,
  PRODUCT_ACCESSORY_SKUS,
  SMART_HOSE_TIMER_SKUS,
  SMART_HOSE_TIMER_SKUS_MAP,
  SMART_HOSE_TIMER_SHOPIFY_PRODUCT_HANDLES,
  PRO_CONTROLLER_HANDLE,
  PRO_CONTROLLER_SKU,
  FERTILIZER_PRODUCT_TAG,
  FERTILIZER_PRODUCT_VENDORS
}