import React from 'react'
import {
  ShopifyCopyStorefrontProduct
} from 'utilities'

import {
  Price,
  ProductCarousel,
  ProductOverviewWrapper,
  VariantSelector
} from 'components/product'
import { Button } from '@rachio-npm/rachio-retail-common'
import { useBundleVariants } from './useBundleVariant'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { below, Wrapper } from 'styles'
import styled from 'styled-components'
import { PortableText } from 'components/elements'
import { QuanitifiedShopifyProductVariant } from './BundleDisplay'
import { MediaContentType } from 'lib/shopify/storefront-api-client/types/storefront.types'

type VariantSelectionProps = {
  product: ShopifyCopyStorefrontProduct,
  onVariantSelect: (globalProductId: string, variant: QuanitifiedShopifyProductVariant) => void,
}
const VariantSelectionCard = (
  {
    product,
    onVariantSelect,
  }: VariantSelectionProps
) => {
  const {
    availableVariants,
    setSelectedVariant,
    selectedVariant
  } = useBundleVariants(product, product.copy);

  return availableVariants.length > 1 ? (
    <div>
      <h5 className='no-top-margin bottom-margin-smaller'>{availableVariants[0].selectedOptions[0].name}:</h5>
      <VariantSelector
        variants={availableVariants}
        setSelectedVariant={(variant) => {
          setSelectedVariant(variant);
          onVariantSelect(
            String(product.id),
            variant
          )
        }}
        hidePrice={true}
        selectedVariant={selectedVariant}
        size="small"
        type="input"
      />
    </div>
  ) : null;
}

type Props = {
  products: (ShopifyCopyStorefrontProduct | null)[],
  addonProducts: (ShopifyCopyStorefrontProduct | null)[],
  copy: {
    title: string,
    subtitle: string,
    filterSku?: string,
    _rawDescription: any[],
    carouselImages: {
      asset: {
        gatsbyImageData: IGatsbyImageData
      },
      id: string
      title: string
    }[],
  },
  onVariantSelect: (globalProductId: string, variant: QuanitifiedShopifyProductVariant) => void,
  onAddonVariantSelect: (globalProductId: string, variant: QuanitifiedShopifyProductVariant) => void,
  onAddBundleToCart: () => void,
  subTotal: number | null,
  discountAmount: number,
  addToCartButtonText: string,
}
export function BundleProductOverview({
  products,
  addonProducts,
  copy,
  onVariantSelect,
  onAddonVariantSelect,
  onAddBundleToCart,
  addToCartButtonText,
  subTotal,
  discountAmount
}: Props) {
  return (
    <Wrapper>
      <ProductOverviewWrapper>
        <BundleProductOverviewWrapper>
          <div className="product-overview" data-testid="bundle-product-overview">
            <h6 className='product-overview-subtitle'>{copy.subtitle}</h6>
            <h1 data-testid="product-overview-title" className="no-top-margin">
              {copy.title}
            </h1>

            <div>
              <PortableText _rawContent={copy._rawDescription} />
            </div>

            {products.map(p => {
              if (p) {
                return (
                  <VariantSelectionCard
                    key={p.id}
                    product={p}
                    onVariantSelect={onVariantSelect}
                  />
                )
              }
            })}

            {addonProducts.map(p => {
              if (p) {
                return (
                  <VariantSelectionCard
                    key={p.id}
                    product={p}
                    onVariantSelect={onAddonVariantSelect}
                  />
                )
              }
            })}

            <div className='buy-buttons'>
              {subTotal && (
                <Price
                  basePrice={subTotal}
                  discountAmount={discountAmount}
                  forceShow={true}
                />
              )}
              <Button
                className="buy-button"
                data-testid="bundle-overview-buy-button"
                style={{ width: '100%' }}
                onClick={onAddBundleToCart}
              >
                {addToCartButtonText}
              </Button>
            </div>
          </div>

          <div className="carousel-wrapper">
            <ProductCarousel
              mediaItems={copy.carouselImages.map((img, index) => ({
                image: {
                  gatsbyImageData: img.asset.gatsbyImageData,
                  id: `${img.title}-${index}`,
                  altText: img.title,
                },
                mediaContentType: 'IMAGE'
              }))}
            />
          </div>
        </BundleProductOverviewWrapper>
      </ProductOverviewWrapper>
    </Wrapper>
  )
}

const BundleProductOverviewWrapper = styled.div`
  display: flex;
  .buy-buttons {
    .price-text {
      display: flex;
      justify-content: center;
      font-size: var(--largestFontSize);
      margin-bottom: 20px;
    }
    .price {
      margin-right: 5px;
    }
  }

  .variant-select-input {
    margin-bottom: 15px;
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }

  ${below.small`
    h1 {
      font-size: var(--h2);
    }
  `}
`