import React, { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { below } from 'styles'

type Item = {
  name: string
  child: JSX.Element
}

export function TabsMenu({ items }: { items: Item[] }) {
  const [[pageIndex, direction], setPageIndex] = useState<number[]>([0, 0])
  const names = items.map((item) => item.name)
  const children = items.map((item) => item.child)

  const variants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 500 : -500,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        x: direction > 0 ? -500 : 500,
        opacity: 0,
      }
    },
  }

  return (
    <>
      <TabsContainer>
        {names.map((name, i) => {
          return (
            <TabButton
              key={`tab-btn-${name + i}`}
              isActive={pageIndex === i}
              onClick={() => {
                setPageIndex((prev) => {
                  const indexDirection = prev[0] > i ? prev[0] + i : prev[0] - i
                  return [i, indexDirection]
                })
                // Scroll to top of tab content
                const element = document.getElementById('tab-content')
                setTimeout(() => {
                  window.scrollTo({
                    behavior: 'smooth',
                    top: element ? element.offsetTop - 100 : 0,
                  })
                }, 100)
              }}
            >
              {name}
            </TabButton>
          )
        })}
      </TabsContainer>
      <AnimatePresence custom={direction}>
        <motion.div
          key={pageIndex}
          custom={direction}
          variants={variants}
          transition={{ duration: 0.3 }}
          initial="enter"
          animate="center"
          exit="exit"
          id="tab-content"
        >
          {children.find((_, i) => i === pageIndex)}
        </motion.div>
      </AnimatePresence>
    </>
  )
}

const TabsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  border-radius: var(--cardRadius);
  overflow: hidden;
  ${below.medium`
    margin: var(--margins);
    flex-direction: column;
  `}
`
const TabButton = styled.button`
  width: 100%;
  padding: var(--buttonPadding);
  background-color: ${({ isActive }: { isActive: boolean }) =>
    isActive ? 'var(--primaryColor)' : 'var(--white)'};
  color: ${({ isActive }: { isActive: boolean }) =>
    isActive ? 'var(--white)' : 'var(--darkerGray)'};
  display: inline-block;
  text-align: center;
  font-weight: bold;
  font-size: var(--baseFontSize);
  font-family: var(--headlineFont);
  transition: var(--transition);
  &:hover {
    background: var(--primaryColor);
    border-color: var(--primaryColor);
    color: var(--white);
  }
  &:focus {
    outline: none;
  }
  & + & {
    margin: 0 0 0 2px;
    ${below.medium`
      margin: 3px 0 0 0;
    `}
  }
`
