import { resolveProduct } from "lib/shopify/transformations"
import { storefrontAPIClient } from "../client"
import { InputMaybe, ProductCollectionSortKeys } from "../types/storefront.types"
import { COLLECTION_BY_FILTERABLE_FILTERABLE_QUERY } from "../graphql"

export type ProductCollectionArgs = {
  first: number
  last: number
  before: string
  after: string
  reverse?: boolean
  sortKey?: InputMaybe<ProductCollectionSortKeys>
  productFilters: {
    available?: boolean,
    price?: {
      min: number
      max: number
    }
    tag?: string
    variantOption?: {
      name: string
      value: string
    }
  }[]
}

export const getCollectionByHandle = async (handle: string, productArgs?: ProductCollectionArgs) => {
  const args = productArgs || { first: 50 };

  const { data, errors } = await storefrontAPIClient.request(
    COLLECTION_BY_FILTERABLE_FILTERABLE_QUERY, 
    { 
      variables: {
        handle, 
        ...args 
      }
    }
  );

  if (errors) {
    throw new Error(errors.message);
  }

  if (data?.collection) {
    return {
      ...data.collection,
      products: data.collection.products.edges.map(resolveProduct)
    }
  }

  return data;
}
