import { PRODUCT_FRAGMENT } from "./fragments/product-fragment";

export const PRODUCT_RECOMMENDATIONS_QUERY = `
  ${PRODUCT_FRAGMENT.fragment}

  query ($productId: ID!, $intent: ProductRecommendationIntent) {
    productRecommendations(productId: $productId, intent: $intent) {
      ${PRODUCT_FRAGMENT.ref}
    }
  }
`