import { getProductByHandle, getProductById } from "lib/shopify";
import { GatsbyShopifyStorefrontProduct, ShopifyProduct } from "lib/shopify/storefront-api-client/types/custom.types";
import { useEffect, useState } from "react";
import { sortVariantsByPrice } from "utilities";

export const useFetchShopifyProduct = (initialShopifyProduct: { handle: string, id: string }) => {
  const [shopifyProduct, setShopifyProduct] = useState<any>(initialShopifyProduct)

  useEffect(() => {
    const fetchData = async () => {
      const productPromise = await getProductById(shopifyProduct.id);
        
      if (productPromise) {
        setShopifyProduct({
          ...productPromise,
          variants: sortVariantsByPrice(productPromise.variants)
        })
      } 
    }
    fetchData()
  }, [])

  return shopifyProduct as GatsbyShopifyStorefrontProduct;
}

export const useFetchShopifyProductByHandle = (handle: string) => {
  const [shopifyProduct, setShopifyProduct] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const productPromise = await getProductByHandle(handle);
        
      if (productPromise) {
        setShopifyProduct({
          ...productPromise,
          variants: sortVariantsByPrice(productPromise.variants)
        })
      } 
    }
    fetchData()
  }, [])

  return shopifyProduct as ShopifyProduct | null; 
}