import React, { ReactElement } from "react";
import { motion } from "framer-motion";

type Props = {
  className?: string,
  delay?: number
  children: ReactElement | ReactElement[]
}
export const AnimatedSection: React.FC<Props> = ({ children, className, delay }) => {
  const container = {
    hidden: { 
      opacity: 0,
      y: 25
    },
    visible: {
      opacity: 1,
      y: 0
    }
  };

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="visible"
      transition={{ delay:  delay || .3 }}
    >
      <div className={className}>
        {children}
      </div>
    </motion.div>
  )
}